import config from "services/config";


const getSummarizationReport = (report) => {
    return config.modelsApi.post("/summarization_report", {
        report: report
    }).then(response => {
        return response.data
    }).catch(() => {
        return {
            error: 'Backend is offline or received critical error',
        }
    })

}

const getRadGraphAnnotations = (report) => {
    return config.modelsApi.post("/radgraph", {
        report: report
    }).then(response => {
        return response.data
    }).catch(() => {
        return {
            error: 'Backend is offline or received critical error',
        }
    })

}


const getProcessedRadGraphAnnotations = (report) => {
    return config.modelsApi.post("/processed_radgraph", {
        report: report
    }).then(response => {
        return response.data
    }).catch(() => {
        return {
            error: 'Backend is offline or received critical error',
        }
    })

}


const getGPT3Completion = (prompt) => {
    return config.modelsApi.post("/openai/gpt3", {
        prompt: prompt
    }).then(response => {
        return response.data
    }).catch(() => {
        return {
            error: 'Backend is offline or received critical error',
        }
    })

}

const getChatGPTCompletion = (prompt, model) => {
    return config.modelsApi.post("/openai/chatGPT", {
        prompt: prompt,
        model: model
    }).then(response => {
        return response.data
    }).catch(() => {
        return {
            error: 'Backend is offline or received critical error',
        }
    })

}


const exportedObject = {
    getRadGraphAnnotations,
    getProcessedRadGraphAnnotations,
    getGPT3Completion,
    getSummarizationReport,
    getChatGPTCompletion,
}

export default exportedObject