import React from 'react'
import {Space, Typography} from 'antd';
import "./style/style.css"

const {Title, Paragraph, Text} = Typography;
const Privacy = () => {

    return (
        <div style={{width: 900}}>
            <Space direction={"vertical"}>
                <Space direction="vertical" size="large" className="privacy-terms-container">
                    <Title level={2}>Privacy Policy</Title>
                    <Title level={4}>Effective date: 08/31/2023</Title>
                    <Paragraph>
                        <Text strong>Introduction</Text><br/>
                        Welcome to ViLMedic ("we", "our", or "us"). We respect your privacy and are committed
                        to protecting your personal data. This privacy policy will inform you about how we handle your
                        personal data when you authenticate using Google OAuth and your privacy rights.
                    </Paragraph>
                    <Paragraph>
                        <Text strong>Information We Collect</Text><br/>
                        We use Google OAuth for authentication purposes. When you authenticate using Google OAuth, we do
                        not collect, store, or process any of your personal data. The authentication process solely
                        verifies your permission to access resources on our website.
                    </Paragraph>
                    <Paragraph>
                        <Text strong>How We Use Your Information</Text><br/>
                        As we do not collect, store, or process any personal data during the Google OAuth authentication
                        process, there is no personal information of yours that we use.
                    </Paragraph>
                    <Paragraph>
                        <Text strong>Data Security</Text><br/>
                        Even though we do not collect personal data, we prioritize the security of our users. We use
                        appropriate security measures to prevent any unauthorized access, alteration, disclosure, or
                        destruction of data.
                    </Paragraph>
                    <Paragraph>
                        <Text strong>Changes to This Privacy Policy</Text><br/>
                        We may update our Privacy Policy from time to time. We will notify you of any changes by posting
                        the new Privacy Policy on this page. Changes to this Privacy Policy are effective when they are
                        posted on this page.
                    </Paragraph>
                    <Paragraph>
                        <Text strong>Contact Us</Text><br/>
                        For any questions about this Privacy Policy, please contact us:<br/>
                        - By email: jbdel@stanford.edu<br/>

                    </Paragraph>

                    <Title level={2}>Terms of Use</Title>
                    <Title level={4}>Effective date: 08/31/2023</Title>
                    <Paragraph>
                        <Text strong>Agreement to Terms</Text><br/>
                        By accessing or using our website, you agree to be bound by these Terms of Use.
                    </Paragraph>
                    <Paragraph>
                        <Text strong>Authentication</Text><br/>
                        To access certain resources, users must authenticate using Google OAuth. This process is solely
                        to verify the user's permission to access said resources.
                    </Paragraph>
                    <Paragraph>
                        <Text strong>Prohibited Uses</Text><br/>
                        You may not use our website in any way that causes, or may cause, damage to the website or
                        impair the availability or accessibility of the website.
                    </Paragraph>
                    <Paragraph>
                        <Text strong>Limitation of Liability</Text><br/>
                        To the fullest extent permitted by applicable law, ViLMedic will not be liable for
                        any indirect, consequential, exemplary, incidental, or punitive damages, including lost profits,
                        even if ViLMedic has been advised of the possibility of such damages.
                    </Paragraph>
                    <Paragraph>
                        <Text strong>Governing Law & Jurisdiction</Text><br/>
                        These Terms of Use are governed by and construed in accordance with the laws of USA.
                    </Paragraph>
                    <Paragraph>
                        <Text strong>Changes to Terms of Use</Text><br/>
                        We may modify the Terms of Use at any time. Changes will be posted on this page and are
                        effective immediately upon posting.
                    </Paragraph>
                    <Paragraph>
                        <Text strong>Contact Us</Text><br/>
                        For any questions about these Terms of Use, please contact us:<br/>
                        - By email: jbdel@stanford.edu<br/>
                    </Paragraph>
                </Space>
            </Space>
        </div>

    )
}

export default Privacy
