import React, {useEffect} from 'react'
import SMMILEOverview from "./SMMILEOverview"
import {Affix, Button, Layout, Select, Space, Spin, Typography} from 'antd';

const {Content} = Layout;
const SMMILEOverviewContainer = () => {

    useEffect(() => {
    }, [])


    const centeredContainerStyle = {
        display: 'flex',
        justifyContent: 'center',
        height: '100vh',
        overflowY: 'auto', // Add this line to enable vertical scrolling
    };

    return (

        <Layout>
            <Content>
                <div style={centeredContainerStyle}>
                    <SMMILEOverview/>
                </div>
            </Content>
        </Layout>

    )
}

export default SMMILEOverviewContainer