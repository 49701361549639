import React, {useEffect, useState} from 'react'
import Emnlp2022 from "./emnlp2022";

const Emnlp2022Container = () => {

    return (
        <Emnlp2022/>
    )
}

export default Emnlp2022Container