import React, {useContext} from 'react'
import {Alert, Anchor, List, Space, Typography} from 'antd';
import "./style/style.css"
import SyntaxHighlighter from 'react-syntax-highlighter';
import GlobalContext from "contexts/GlobalContext";

const {Link} = Anchor;


const {Paragraph, Title, Text} = Typography;

const code_config_1 = 'name: my_experiment\n' +
    'ckpt_dir: ckpt\n' +
    'dataset:\n' +
    '  proto: ImSeq\n' +
    '  image:\n' +
    '    root: mimic-cxr/impression/\n' +
    '    file: image.tok\n' +
    '    image_path: data/images/\n' +
    '    resize: 256\n' +
    '    crop: 224\n' +
    '    ext: .jpg\n' +
    '  seq:\n' +
    '    root: mimic-cxr/impression/\n' +
    '    file: impression.tok\n' +
    '    tokenizer: allenai/biomed_roberta_base\n' +
    '    tokenizer_max_len: 120\n' +
    '    processing: r2gen_clean_report\n' +
    '    source: tgt\n' +
    '\n' +
    'model:\n' +
    '  proto: RRG\n' +
    '  decoder:\n' +
    '    proto: allenai/biomed_roberta_base\n' +
    '  cnn:\n' +
    '    proto: VisualEncoder\n' +
    '    backbone: densenet169\n' +
    '    output_layer: features\n' +
    '    dropout_out: 0.0\n' +
    '    permute: batch_first\n' +
    '    visual_projection:\n' +
    '      in_features: 1664\n' +
    '      out_features: 768\n' +
    '    freeze: False\n' +
    '\n' +
    'trainor:\n' +
    '  batch_size: 128\n' +
    '  optimizer: RAdam\n' +
    '  optim_params:\n' +
    '    lr: 5e-5\n' +
    '    weight_decay: 0.\n' +
    '  lr_decay: ReduceLROnPlateau\n' +
    '  lr_decay_params:\n' +
    '    factor: 0.8\n' +
    '    patience: 1\n' +
    '    min_lr: 0.000001\n' +
    '    threshold_mode: abs\n' +
    '  epochs: 99\n' +
    '  early_stop: 10\n' +
    '  eval_start: 0\n' +
    '  early_stop_metric: chexbert\n' +
    '\n' +
    'validator:\n' +
    '  batch_size: 4\n' +
    '  beam_width: 8\n' +
    '  metrics:\n' +
    '    - ROUGEL\n' +
    '    - chexbert\n' +
    '  splits: [validate]\n' +
    '\n' +
    'ensemblor:\n' +
    '  batch_size: 4\n' +
    '  beam_width: 8\n' +
    '  metrics:\n' +
    '    - ROUGEL\n' +
    '    - BLEU\n' +
    '    - METEOR\n' +
    '    - CIDERD\n' +
    '    - chexbert\n' +
    '    - radentitymatchexact\n' +
    '    - radentitynli\n' +
    '    - radgraph\n' +
    '  splits: [validate, test]\n' +
    '  mode: best-1\n'


const code_block_2 = 'validator:\n' +
    '  batch_size: 4\n' +
    '  beam_width: 8\n' +
    '  metrics:\n' +
    '    - BLEU\n' +
    '    - chexbert\n' +
    '    - radentitymatchexact\n' +
    '    - radentitynli\n' +
    '    - radgraph\n' +
    '  splits: [validate]'
const code_block_1 = 'from vilmedic.blocks.vision import VisualEncoder\n' +
    'class MyModel(nn.Module):\n' +
    '\n' +
    '    def __init__(self, visual_config, dl=None, logger=None, from_training=None, **kwargs):\n' +
    '        super().__init__()\n' +
    '        # VisualEncoder\n' +
    '        self.enc = VisualEncoder(**visual_config)'

const code_model = 'class MyModel(nn.Module):\n' +
    '\n' +
    '    def __init__(self, dl=None, logger=None, from_training=None, **kwargs):\n' +
    '        super().__init__()\n' +
    '        pass\n' +
    '\n' +
    '    def forward(self, epoch=None, iteration=None, **kwargs):\n' +
    '        pass\n' +
    '\n' +
    '    def __repr__(self):\n' +
    '        s = "MyModel\\n"\n' +
    '        return s'
const Philosophy = () => {
    const {codeStyle, VilmedicTag} = useContext(GlobalContext);
    return (
        <div style={{display: 'flex'}}>
            <div style={{width: 900}} id={"docPageContent"}>
                <Space direction={"vertical"}>
                    <Paragraph>
                        <VilmedicTag/> revolves around the following key elements: models, blocks and configs.
                    </Paragraph>
                    <Title level={2} id="anchor-model">Models</Title>

                    <Paragraph>
                        A model is defined in a single class and should be self-sufficient. All classes are declared
                        in <Text code>vilmedic/models/__init__.py</Text>.
                    </Paragraph>

                    The model skeleton is the following:
                    <SyntaxHighlighter customStyle={{textAlign: "left"}} language="python" style={codeStyle}>
                        {code_model}
                    </SyntaxHighlighter>
                    <Paragraph>
                        This model class is unrestricted and designed for great flexibility. You can create your models
                        with
                        implemented <VilmedicTag/> blocks or just copy paste another implementation to bring it into the
                        ViLMedic ecosystem.
                    </Paragraph>
                    <Paragraph>
                        The model receives all the information to perform a forward pass. The <Text
                        code>init()</Text> receives the model configuration as specified in the config file in <Text
                        code>kwargs</Text> and the <Text
                        code>forward()</Text> receives the batch in <Text
                        code>kwargs</Text>.
                        Other important information are provided to such as the dataloader (dl), the current
                        iteration or epoch, or if the model is called during training or test-time.
                    </Paragraph>
                    <Paragraph>
                        The model can perform its weights update in the <Text
                        code>forward()</Text> with its own optimizer defined in the <Text
                        code>init()</Text> or
                        return a loss and let <VilmedicTag/> do the training. More details are provided in the dedicated
                        section.
                    </Paragraph>

                    <Title level={2} id="anchor-blocks">Blocks</Title>

                    <Paragraph>
                        A block is a useful piece of deep-learning code. It can be a subpart of a neural network or an
                        evaluation metrics. Blocks are defined in <Text code>vilmedic/blocks</Text> and can be imported
                        in a
                        model class.
                    </Paragraph>

                    <SyntaxHighlighter customStyle={{textAlign: "left"}} language="python" style={codeStyle}>
                        {code_block_1}
                    </SyntaxHighlighter>
                    <Paragraph>
                        In this example, the VisualEncoder block of <VilmedicTag/> contains code to encode multiple 2D
                        images
                        (such as multi-xrays studies) or 3D volumes (such as CT scans). One can of course decides to
                        write
                        its own encoder using <Text code>torchvision</Text> code.
                    </Paragraph>
                    <Paragraph>
                        Some blocks can be invoked directly from the config file. In this example, this piece of config
                        the
                        user is listing metrics to be computed on the validation set.
                    </Paragraph>
                    <SyntaxHighlighter customStyle={{textAlign: "left"}} language="yaml" style={codeStyle}>
                        {code_block_2}
                    </SyntaxHighlighter>
                    <Paragraph>
                        The metrics <Text code>chexbert, radentitymatchexact, radentitynli, radgraph</Text> are scorer
                        blocks implemented in <VilmedicTag/> and defined in <Text code>vilmedic/blocks/scorers</Text>.
                    </Paragraph>
                    <Paragraph>
                        More details are provided in the dedicated block section.
                    </Paragraph>
                    <Title level={2} id="anchor-configs">Configs</Title>
                    A configuration is declared in a yaml file and defines the whole pipeline (configuration of models,
                    blocks, trainings, and evaluations).

                    Here is what a typical config file would look like. More details are provided in the dedicated
                    configs
                    section.
                    <SyntaxHighlighter customStyle={{textAlign: "left"}} language="yaml" style={codeStyle}>
                        {code_config_1}
                    </SyntaxHighlighter>

                </Space>
            </div>
            <div style={{marginLeft: '10px'}}>
                <Anchor>
                    <Link href="#anchor-model" title="Models"/>
                    <Link href="#anchor-blocks" title="Blocks"/>
                    <Link href="#anchor-configs" title="Configs"/>
                </Anchor>
            </div>
        </div>
    )
}

export default Philosophy
