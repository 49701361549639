import config from "services/config";


const getReports = (index) => {
    return config.miscApi.post("/struct_report_review/get_reports", {
        index: index,
    }).then(response => {
        return response.data
    }).catch(() => {
        return {
            error: 'Backend is offline or received critical error',
        }
    })
}

const verifyReport = (report, id) => {
    return config.miscApi.post("/struct_report_review/verify_reports", {
        report: report,
        id: id,
    }).then(response => {
        return response.data
    }).catch(() => {
        return {
            error: 'Backend is offline or received critical error',
        }
    })
}


const verifyUtterances= (utterances, id) => {
    return config.miscApi.post("/struct_report_review/verify_utterances", {
        utterances: utterances,
        id: id,
    }).then(response => {
        return response.data
    }).catch(() => {
        return {
            error: 'Backend is offline or received critical error',
        }
    })
}


const exportedObject = {
    getReports,
    verifyReport,
    verifyUtterances,
}

export default exportedObject