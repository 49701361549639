import React, {useEffect, useState} from 'react'
import RadGraph from "./RadGraph"

const RadGraphContainer = () => {


    useEffect(() => {
    }, [])


    return (
        <RadGraph/>
    )
}

export default RadGraphContainer