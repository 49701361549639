import React, {useEffect, useState} from 'react'
import MiscScripts from "./MiscScripts";

const MiscScriptsContainer = () => {

    return (
        <MiscScripts/>
    )
}

export default MiscScriptsContainer