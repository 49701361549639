import React, {useContext, useState} from 'react'
import "./style/style.css"
import {Button, message, Space, Steps} from 'antd';
import {SettingOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import SyntaxHighlighter from "react-syntax-highlighter";
import GlobalContext from "contexts/GlobalContext";

const {Step} = Steps;


const codeZoo = `from vilmedic import AutoModel
model, processor = AutoModel.from_pretrained("rrg/biomed-roberta-baseline-mimic")
print(model)

>>> model: RRG
>>> (enc):Sequential(
>>>   (0): densenet169(dropout_out=0.0, freeze=False, output_layer=features, pretrained=True)
>>>   (1): Linear(in_features=1664, out_features=768, bias=True)
>>> )
>>> (dec):RobertaForCausalLM(RobertaConfig {
>>>   "_name_or_path": "allenai/biomed_roberta_base",
>>> ...
`

const codeInference = `batch = processor.inference(seq='no acute cardiopulmonary process .',
                            image='my_chest_x_ray.jpg')
out = model(**batch)
print(out.keys())
>>> dict_keys(['loss', 'logits', 'past_key_values', 'hidden_states', 'attentions', 'cross_attentions'])
`

const Quickstart = () => {
    const {codeStyle} = useContext(GlobalContext);

    const steps = [
        {
            title: 'Installation',
            content: <>
                <Link to="/documentation/installation/" target="_blank" rel="noopener noreferrer">
                    <SettingOutlined/> Follow the installation guide
                </Link>
            </>,
        },
        {
            title: 'Invoke model',
            content: <>
                <Space direction={"vertical"}>
                    <Link to="/documentation/installation/" target="_blank" rel="noopener noreferrer">
                        <SettingOutlined/> Choose a model from the model-zoo and run the following:
                    </Link>
                    <SyntaxHighlighter customStyle={{textAlign: "left"}} language="python" style={codeStyle}>
                        {codeZoo}
                    </SyntaxHighlighter>
                </Space>
            </>,
        },
        {
            title: "Run inference",
            content: <>
                <Space direction={"vertical"}>
                    You can run inference to get model's representation:
                    <SyntaxHighlighter customStyle={{textAlign: "left"}} language="python" style={codeStyle}>
                        {codeInference}
                    </SyntaxHighlighter>
                    Each model type has advanced inference settings.
                    <Link to="/documentation/installation/" target="_blank" rel="noopener noreferrer">
                        Click here for an advanced inference example for radiology report generation
                    </Link>
                </Space>
            </>,
        },
    ];


    const [current, setCurrent] = useState(0);

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    return (
        <div id={"quickstart"} style={{width: 900}}>
            <Steps current={current}>
                {steps.map(item => (
                    <Step key={item.title} title={item.title}/>
                ))}
            </Steps>
            <div className="steps-content">{steps[current].content}</div>
            <div className="steps-action">
                {current < steps.length - 1 && (
                    <Button type="primary" onClick={() => next()}>
                        Next
                    </Button>
                )}
                {current === steps.length - 1 && (
                    <Button type="primary" onClick={() => message.success('Quickstart complete!')}>
                        Done
                    </Button>
                )}
                {current > 0 && (
                    <Button style={{margin: '0 8px'}} onClick={() => prev()}>
                        Previous
                    </Button>
                )}
            </div>
        </div>
    );
};

export default Quickstart
