import React, {useEffect} from 'react'
import MimicCxr from "./MimicCxr"

const MimicCxrContainer = () => {

    useEffect(() => {
    }, [])

    return (
        <MimicCxr/>
    )
}

export default MimicCxrContainer