import React, {useEffect, useContext, useState} from 'react'
import {Alert, Divider, message, notification, Space, Spin, Table, Typography, Upload} from 'antd';
import bionlp23Api from "services/misc/bionlp23";
import LeaderboardDragger from "./LeaderboardDragger"
import SyntaxHighlighter from "react-syntax-highlighter";
import GlobalContext from "contexts/GlobalContext";
import Retrieve from "./Retrieve";

const {Title, Paragraph, Link} = Typography;

const code_process = 'from nltk.tokenize import wordpunct_tokenize\n' +
    'def process_impression(impression):\n' +
    '    impression = impression.lower()\n' +
    '    return \' \'.join(wordpunct_tokenize(impression))'


const DATASETS = {
    "mimic-iii-test": 6526,
    "mimic-iii-test-hidden": 6531,
    "mimic-cxr-test": 1624,
    "mimic-cxr-test-hidden": 1000,
}

const openNotificationWithIcon = (type, description) => {
    notification[type]({
        message: 'Notification: ' + type,
        description: description,
        duration: 0,
    });
};


const columnsMimicCXR = [{
    title: 'Modality/Anatomy', dataIndex: 'name', key: 'name',
}, {
    title: 'Number of images', dataIndex: 'num_im', key: 'num',
}, {
    title: 'Number of reports', dataIndex: 'num', key: 'num',
}, {
    title: 'Train', dataIndex: 'tr', key: 'tr',
}, {
    title: 'Validation', dataIndex: 'val', key: 'val',
}, {
    title: 'Test', dataIndex: 'te', key: 'te',
},];


const columnsSingle = [
    {
        title: 'BLEU4',
        dataIndex: 'bleu',
        key: 'bleu',
        render: (text) =>
            text === null ? 'N/A' : (text * 100).toFixed(2)
    },
    {
        title: 'ROUGEL',
        dataIndex: 'rougel',
        key: 'rougel',
        render: (text) =>
            text === null ? 'N/A' : (text * 100).toFixed(2)
    },
    {
        title: 'Bertscore',
        dataIndex: 'bertscore',
        key: 'bertscore',
        render: (text) =>
            text === null ? 'Computing...' : (text * 100).toFixed(2)
    },
    {
        title: 'F1-cheXbert',
        dataIndex: 'f1chexbert',
        key: 'f1chexbert',
        render: (text) =>
            text === null ? 'Computing...' : text === undefined ? "N/A" : (text * 100).toFixed(2)
    },
    {
        title: 'F1-RadGraph',
        dataIndex: 'f1radgraph',
        key: 'f1radgraph',
        render: (text) =>
            text === null ? 'Computing...' : (text * 100).toFixed(2)
    },
];

const columnsAll = [
    {
        title: 'Team',
        dataIndex: 'team',
        key: 'team',
    },
    {
        title: 'BLEU4',
        dataIndex: 'bleu',
        key: 'bleu',
        render: (text) =>
            text === null ? 'N/A' : (text * 100).toFixed(2)
    },
    {
        title: 'ROUGEL',
        dataIndex: 'rougel',
        key: 'rougel',
        render: (text) =>
            text === null ? 'N/A' : (text * 100).toFixed(2)
    },
    {
        title: 'Bertscore',
        dataIndex: 'bertscore',
        key: 'bertscore',
        render: (text) =>
            text === null ? 'Computing...' : (text * 100).toFixed(2)
    },
    {
        title: 'F1-cheXbert',
        dataIndex: 'f1chexbert',
        key: 'f1chexbert',
        render: (text) =>
            text === null ? 'Computing...' : text === undefined ? "N/A" : (text * 100).toFixed(2),
        // defaultSortOrder: 'descend',
        // sorter: {
        //     compare: (a, b) => a.f1chexbert - b.f1chexbert,
        //     multiple: 1,
        // },
    },
    {
        title: 'F1-RadGraph',
        dataIndex: 'f1radgraph',
        key: 'f1radgraph',
        render: (text) =>
            text === null ? 'Computing...' : (text * 100).toFixed(2),
        defaultSortOrder: 'descend',
        sorter: {
            compare: (a, b) => a.f1radgraph - b.f1radgraph,
            multiple: 1,
        },
    },
];


const Leaderboard = () => {

    const [waitForTeam, setWaitForTeam] = useState(false);
    const [waitForResult, setWaitForResult] = useState(false);
    const [hyps, setHyps] = useState(null);
    const [team, setTeam] = useState("");
    const [pin, setPin] = useState("");
    const [filename, setFilename] = useState("");
    const {
        codeStyle,
        errorMessage,
        setErrorMessage
    } = useContext(GlobalContext);

    // current_result
    const [data, setData] = useState(null);
    const [leaderboard, setLeaderboard] = useState({});

    const getLeaderboard = async (item) => {
        try {
            const response = await bionlp23Api.getScores(item);
            if ("error" in response)
                setErrorMessage(response["error"])
            return {[item]: response};
        } catch (error) {
            console.error(error);
            return {[item]: null};
        }
    };

    useEffect(() => {
        setErrorMessage("")
        Promise.all(Object.keys(DATASETS).map(getLeaderboard))
            .then(results => {
                // Combine all the results into a single object
                const newLeaderboard = results.reduce((acc, curr) => ({...acc, ...curr}), {});
                // Update the state with the new leaderboard
                setLeaderboard(newLeaderboard);
            });
    }, [])

    const resetState = () => {
        setData(null)
        setHyps(null)
        setTeam("")
        setPin("")
        setWaitForTeam(false)
        setWaitForResult(false)
        setFilename("")
    }
    const handleFileUpload = async ({fileList: newFileList}, dataset) => {
        resetState()
        setWaitForTeam(true)

        if (newFileList.length > 0) {
            let file = newFileList[0]
            let hyps = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);

            })
            hyps = atob(hyps.split(',')[1]).split("\n")

            // remove trailing blank line if present
            if (typeof hyps[hyps.length - 1] === 'string' && hyps[hyps.length - 1] === '') {
                hyps.pop();
            }

            if (hyps.length !== DATASETS[dataset]) {
                message.error(`Num of lines mismatch:  ${hyps.length} (your file) vs ${DATASETS[dataset]} (reference file) !`);
                resetState()
                return false
            }
            setHyps(hyps)
            setFilename(file["name"])
        }
    }
    const resetResults = async () => {
        resetState()
    }
    const submitResults = async (dataset) => {
        setWaitForResult(true)
        setWaitForTeam(false)

        if (!(team) || team === "") {
            message.error("No team provided")
            setWaitForResult(false)
            return
        }

        if (!(pin) || pin === "") {
            message.error("No pin provided")
            setWaitForResult(false)
            return
        }

        if (!Array.isArray(hyps) || hyps.length === 0) {
            message.error("No hyps provided")
            setWaitForResult(false)
            return
        }

        let ret = await bionlp23Api.computeScores(hyps, dataset, pin, team)

        if (!(typeof ret === 'object' && !Array.isArray(ret))) {
            resetState()
            openNotificationWithIcon("error", "Error happened in the scoring, please try again later")
            return
        }

        if ('error' in ret) {
            resetState()
            openNotificationWithIcon("error", ret["error"])
            return
        }
        ret["key"] = 1
        setData([ret])
        setHyps(null)
        setTeam("")
        setPin("")
        setWaitForTeam(false)
        setWaitForResult(false)
        setFilename("")
        openNotificationWithIcon("success", "Result successfully sent. It will soon be updated with the missing values.")

    }
    if (errorMessage) {
        return (
            <Alert
                message="An error has been caught"
                description={`Sorry. We encountered the following error: ${errorMessage}`}
                type="error"
                showIcon
            />
        );
    }

    return (
        <div style={{width: 1000}}>
            <Space direction={"vertical"} size={20}>
                <Title level={3}>1. Introduction</Title>

                The hidden test-sets datasets consists of the following splits:
                <Table dataSource={[
                    {
                        key: 'chexpert',
                        name: 'CheXpert Chest X-rays',
                        num_im: 1000,
                        num: 1000,
                        tr: "",
                        val: "",
                        te: "1000",
                    },
                    {
                        key: '2.1', name: 'MIMIC-III ', num_im: "", num: 6531, tr: "", val: "", te: "6531",
                    }
                ]
                } columns={columnsMimicCXR} pagination={false}/>
                <Paragraph>
                    <Alert
                        message="Please Read"
                        description={<>The provided CheXpert test-set has been de-identified using the "hide in plain
                            sight" method. <br/>
                            <blockquote>Pierre J Chambon, Christopher Wu, Jackson M Steinkamp, Jason Adleberg, Tessa S
                                Cook, Curtis P Langlotz, Automated deidentification of radiology reports combining
                                transformer and “hide in plain sight” rule-based methods, Journal of the American
                                Medical Informatics Association, Volume 30, Issue 2, February 2023, Pages 318–328,
                                https://doi.org/10.1093/jamia/ocac219
                            </blockquote>
                        </>}
                        type="warning"
                        showIcon
                    />
                </Paragraph>
                <Title level={3}>2. How to and rules</Title>
                To submit your models generation, please use Section 3 the following way:
                <Paragraph>
                    <b>How to:</b>
                    <ol>
                        <li>
                            Select the dataset you want to make the submission for.
                        </li>
                        <li>
                            Upload a text file containing one impression per line. This file should be aligned with the
                            provided
                            findings text file.
                        </li>
                        <li>
                            Enter a team name and a pin code. If this is your first submission, your team name will be
                            created. The pin code ensures that only you can submit for your team, please remember it.
                        </li>
                        <Alert
                            message="Please Read"
                            description={<>The team name is the name that will prepend to the title of your paper at
                                BioNLP: MyTeam at RadSum23: my method. You can see example of the previous edition <Link
                                    href={"https://aclanthology.org/volumes/2021.bionlp-1/"}>here</Link> (look for "at
                                MEDIQA 2021").</>}
                            type="warning"
                            style={{margin: 12}}
                            showIcon
                        />
                        <li>
                            Click submit and wait for the success message stating that your submission has been
                            recorded. Because some metrics are computationally expensive, your submission will be scored
                            asynchronously.
                        </li>
                    </ol>
                    <b>Rules:</b>
                    <ol>
                        <li> You can make one submission each 8 hours per hidden test-set and each hour per open
                            test-sets. Submission on open test-sets are present to validate your local results match
                            your results online.
                        </li>
                        <li>
                            You can only use one team name unless you plan on writing multiple papers with substantially
                            different scientific contributions. If you develop one method to be evaluated on both
                            test-sets, this would still count as one paper, and therefore one team name.
                        </li>
                    </ol>
                    Finally, each impression of your submission will be processed as such before scoring to match the
                    ground-truth processing on our side:
                    <SyntaxHighlighter customStyle={{textAlign: "left"}} language="python" style={codeStyle}>
                        {code_process}
                    </SyntaxHighlighter>


                </Paragraph>

                <Title level={3}>3. Submit</Title>

                {/*<LeaderboardDragger*/}
                {/*    handleFileUpload={handleFileUpload}*/}
                {/*    loading={waitForResult || waitForTeam}*/}
                {/*    hyps={hyps}*/}
                {/*    setTeam={setTeam}*/}
                {/*    setPin={setPin}*/}
                {/*    pin={pin}*/}
                {/*    filename={filename}*/}
                {/*    submitResults={submitResults}*/}
                {/*    resetResults={resetResults}*/}
                {/*    datasets={Object.keys(DATASETS)}*/}
                {/*/>*/}

                {/*{waitForResult &&*/}
                {/*    <div style={{padding: 50}}>*/}
                {/*        <Spin tip="Computing scores, this could take a few minutes...">*/}
                {/*            <div className="content"/>*/}
                {/*        </Spin>*/}
                {/*    </div>*/}
                {/*}*/}
                {/*{data &&*/}
                {/*    <div>*/}
                {/*        <Divider orientation="left">Submission</Divider>*/}
                {/*        <Table columns={columnsSingle} dataSource={data}/>*/}
                {/*    </div>*/}
                {/*}*/}

                <Title level={3}>4. Retrieve my submissions</Title>
                <Retrieve/>

                <Title level={3}>5. Leaderboards</Title>


                {[
                    ["mimic-iii-test-hidden", "MIMIC-III hidden test-set"],
                    ["mimic-cxr-test-hidden", "MIMIC-CXR hidden test-set"],
                    ["mimic-iii-test", "MIMIC-III test-set"],
                    ["mimic-cxr-test", "MIMIC-CXR test-set"],
                ].map((dataset) =>
                    <>
                        <Title level={5}>{dataset[1]} ({DATASETS[dataset[0]]} samples)</Title>
                        <div>
                            <Divider orientation="left">Leaderboard</Divider>
                            <Table columns={columnsAll}
                                   dataSource={leaderboard[dataset[0]]}
                                   loading={(Object.keys(leaderboard).length === 0)}
                            />
                        </div>

                    </>
                )}


            </Space>
        </div>
    )

}

export default Leaderboard

