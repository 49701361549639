import React, {useContext, useState} from 'react'
import "./style/style.css"
import GlobalContext from "contexts/GlobalContext";
import {Alert, Button, Space, Steps, Table, Typography} from 'antd';
import DraggableTable from "../DraggableTable";
import Modal from "antd/es/modal/Modal";

const {Step} = Steps;


const {Title, Paragraph} = Typography;


const MVQA = () => {
    const {codeStyle} = useContext(GlobalContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [configSteps, setConfigSteps] = useState([]);
    const [inConfigCurrent, setInConfigCurrent] = useState(0);

    const next = () => {
        setInConfigCurrent(inConfigCurrent + 1);
    };

    const prev = () => {
        setInConfigCurrent(inConfigCurrent - 1);
    };

    const showConfig = (record) => {
        console.log(record)
        let name = record.name
        let train = ""
        let test = ""
        if (name.includes("SYSU-HCP")) {
            window.open('https://github.com/Rodger-Huang/SYSU-HCP-at-ImageCLEF-VQA-Med-2021', '_blank', 'noreferrer');
            return
        }
        if (name.includes("Visual only")) {
            train = 'for i in {1..10}\n' +
                'do\n' +
                '    python bin/train.py config/MVQA/vqa.yml \\\n' +
                '            trainor.batch_size=32 \\\n' +
                '            trainor.optimizer=RAdam \\\n' +
                '            name=visual_only \\\n' +
                '            ckpt_dir=ckpt\n' +
                'done'
            test = "python bin/ensemble.py config/MVQA/vqa.yml \\\n" +
                "            name=visual_only \\\n" +
                "            ensemblor.mode=best-7"
        }


        setConfigSteps(
            [<Paragraph>
                <pre>{train}</pre>
            </Paragraph>, <Paragraph>
                <pre>{test}</pre>
            </Paragraph>],
        )


        setIsModalOpen(true);
    };

    const handleOk = () => {
        setConfigSteps([])
        setInConfigCurrent(0)
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setConfigSteps([])
        setInConfigCurrent(0)
        setIsModalOpen(false);
    };


    const expandedRowRender = (record, index, indent, expanded) => {
        let data = undefined
        if (record.key === "mvqa21") {
            data = [
                {
                    "key": "0",
                    "name": "Visual only",
                    "split": "test (in-domain)",
                    "accuracy": "69.0",
                    "index": 0,
                },
                {
                    "key": "1",
                    "name": "Visual only (ensemble-7)",
                    "split": "test (in-domain)",
                    "accuracy": "72.0",
                    "index": 1,
                },
                {
                    "key": "2",
                    "name": "SYSU-HCP (ensemble-8)",
                    "split": "test (in-domain)",
                    "accuracy": "69.2",
                    "index": 2,
                },
                {
                    "key": "3",
                    "name": "Visual only (ensemble-7)",
                    "split": "test (out-domain)",
                    "accuracy": "37.8",
                    "index": 3,
                },
                {
                    "key": "4",
                    "name": "SYSU-HCP (ensemble-8)",
                    "split": "test (out-domain)",
                    "accuracy": "38.2",
                    "index": 4,
                },
            ]

        }

        const columns = [

            {
                title: 'Split',
                dataIndex: 'split',
                key: 'split',
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Accuracy',
                dataIndex: 'accuracy',
                key: 'accuracy',
                sorter: (a, b) => a.accuracy - b.accuracy,
            },
            {
                title: '',
                key: 'config',
                render: (text, record, index) => <a onClick={() => showConfig(record)}>Config</a>,
            }
        ];
        return <DraggableTable
            data={data}
            columns={columns}
        />;
    };


    const columns = [

        {
            title: 'Dataset',
            dataIndex: 'dataset',
            key: 'dataset',
        },
        {
            title: 'Size',
            dataIndex: 'size',
            key: 'size',
        },
        {
            title: 'Publisher',
            dataIndex: 'publisher',
            key: 'publisher',
        },
        {
            title: 'Link',
            key: 'operation',
            render: (text, record, index) => <a>Link</a>,
        },
    ];
    const data = [
        {
            "key": "mvqa21",
            "dataset": "ImageCLEF-VQA-Med2021",
            "size": "6683 samples",
            "publisher": "CLEF 2021",
        },
    ]

    console.log(inConfigCurrent)
    return (
        <>
            <div id="mvqa_solution" style={{width: 900}}>
                <Space direction={"vertical"}>
                    <Title level={2}>Medical Visual Question Answering</Title>
                    <Paragraph>
                        Given a radiology image accompanied by a relevant
                        question, systems are tasked with answering the question
                        based on the visual image content.
                    </Paragraph>
                    <Alert
                        message="Informational Notes"
                        description="The datasets used in this section are ImageCLEF-VQA-Med2021."
                        type="info"
                        showIcon
                    />

                    <Table
                        columns={columns}
                        expandable={{
                            expandedRowRender,
                            defaultExpandedRowKeys: ['mvqa21'],
                        }}
                        dataSource={data}
                    />

                </Space>
            </div>
            <Modal title="Config" width={700} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>

                <Steps current={inConfigCurrent}>
                    <Step key={"1"} title={"Train & Validation"}/>
                    <Step key={"2"} title={"Test"}/>
                </Steps>
                <div className="steps-content">{configSteps[inConfigCurrent]}</div>
                <div className="steps-action">
                    {inConfigCurrent < configSteps.length - 1 && (
                        <Button type="primary" onClick={() => next()}>
                            Next
                        </Button>
                    )}
                    {inConfigCurrent > 0 && (
                        <Button style={{margin: '0 8px'}} onClick={() => prev()}>
                            Previous
                        </Button>
                    )}
                </div>
            </Modal>
        </>
    );
};

export default MVQA
