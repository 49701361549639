import config from "services/config";


const getAnnotations = (username, study) => {
    return config.miscApi.post("/radGPT/getAnnotations", {
        username: username,
        study: study,
    }).then(response => {
        return response.data
    }).catch(() => {
        return {
            error: 'Backend is offline or received critical error',
        }
    })
}

const saveAnnotations = (username, study, annotations) => {
    return config.miscApi.post("/radGPT/saveAnnotations", JSON.stringify({
        username: username,
        study: study,
        annotations: annotations,
    })).then(response => {
        return response.data
    }).catch(() => {
        return {
            error: 'Backend is offline or received critical error',
        }
    })
}


const exportedObject = {
    getAnnotations,
    saveAnnotations,
}

export default exportedObject