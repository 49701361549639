import React, {useEffect} from 'react'
import PlumbGPT from "./PlumbGPT.jsx"
import {Layout} from 'antd';
import {
    ProviderWrapper as SummarizationProvider
} from "components/Layout/Pages/RadGPT/explainReport/SummarizationContext"

const {Content} = Layout;
const PlumbGPTContainer = () => {

    useEffect(() => {
    }, [])

    return (

        <SummarizationProvider>
            <Layout>
                <Content>
                    <PlumbGPT/>
                </Content>
            </Layout>
        </SummarizationProvider>

    )
}

export default PlumbGPTContainer