import React, {useContext} from 'react'
import "./style/style.css"
import {Space} from 'antd';

import GlobalContext from "contexts/GlobalContext";
import {Card} from 'antd';

const {Meta} = Card;


const Solution = () => {
    // const {codeStyle} = useContext(GlobalContext);

    function cardOnClick(event) {
        console.log(event)
        return undefined;
    }

    return (
        <>
            <div id={"solution-main"}>
                <Space direction={"horizontal"}>

                    <Card
                        hoverable
                        style={{
                            width: 300,
                        }}
                        key={"ok"}
                        cover={<img alt="example" src="/images/rrg.png"/>}
                        onClick={() => cardOnClick("rrg")}
                    >
                        <Meta title="Radiology Report Generation" description="www.instagram.com"/>
                    </Card>
                    <Card
                        hoverable
                        style={{
                            width: 300,
                        }}
                        cover={<img alt="example" src="/images/rrs.png"/>}
                    >
                        <Meta title="Radiology Report Summarization" description="www.instagram.com"/>
                    </Card>
                    <Card
                        hoverable
                        style={{
                            width: 300,
                        }}
                        cover={<img alt="example" src="https://img.icons8.com/nolan/344/ask-question.png"/>}
                    >
                        <Meta title="Medical VQA" description="www.instagram.com"/>
                    </Card>
                    <Card
                        hoverable
                        style={{
                            width: 300,
                        }}
                        cover={<img alt="example" src="https://img.icons8.com/color/344/mind-map.png"/>}
                    >
                        <Meta title="Self-supervised learning" description="www.instagram.com"/>
                    </Card>
                </Space>
            </div>
        </>
    );
};

export default Solution
