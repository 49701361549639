import React, {useContext, useState} from 'react'
import {
    Space,
    Input,
    Table,
    message,
    Modal,
    Spin,
    Steps,
    Button,
    Typography,
    Alert, PageHeader, Row
} from 'antd';
import SyntaxHighlighter from "react-syntax-highlighter";
import GlobalContext from "contexts/GlobalContext";
import LoginPassword from "components/Utils/LoginPassword";
import downloadApi from "services/downloadResources";
import {FilePdfOutlined, SettingOutlined} from "@ant-design/icons";

const {Title, Paragraph, Link} = Typography;
const {Step} = Steps;


const Emnlp2022 = () => {
    const {codeStyle} = useContext(GlobalContext);

    const [isLoginModalOpen, setLoginIsModalOpen] = useState(false);
    const [currentDataset, setCurrentDataset] = useState("");
    const [loginEpilogue, setLoginEpilogue] = useState("");
    // Config
    const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);
    const [configSteps, setConfigSteps] = useState([]);
    const [inConfigCurrent, setInConfigCurrent] = useState(0);

    const Content = ({children, extraContent}) => (
        <Row key={"rowContent"}>
            <div key="children" style={{flex: 1,}}>{children}</div>
            <div key="extraContent" className="image">{extraContent}</div>
        </Row>
    );
    const configNext = () => {
        setInConfigCurrent(inConfigCurrent + 1);
    };

    const configPrev = () => {
        setInConfigCurrent(inConfigCurrent - 1);
    };

    const handleConfigOk = () => {
        setConfigSteps([])
        setInConfigCurrent(0)
        setIsConfigModalOpen(false);
    };

    const handleConfigCancel = () => {
        setConfigSteps([])
        setInConfigCurrent(0)
        setIsConfigModalOpen(false);
    };


    const showConfig = (record) => {
        console.log(record)
        let key = record.key
        let train_nll = ""
        let train_rl = ""
        let test = ""
        if (key.includes("modelfindings")) {
            train_nll = 'python bin/train.py config/RRG/baseline-mimic.yml \\\n' +
                '    dataset.seq.processing=ifcc_clean_report \\\n' +
                '    dataset.image.root=data/RRG/mimic-cxr/findings/ \\\n' +
                '    dataset.seq.root=data/RRG/mimic-cxr/findings/ \\\n' +
                '    dataset.seq.file=findings.tok \\\n' +
                '    dataset.seq.tokenizer_max_len=128 \\\n' +
                '    dataset.image.file=image.tok \\\n' +
                '    dataset.image.image_path=data/images/ \\\n' +
                '    dataset.image.multi_image=3 \\\n' +
                '    model.cnn.backbone=densenet121 \\\n' +
                '    model.cnn.visual_projection.in_features=1024 \\\n' +
                '    model.cnn.visual_projection.out_features=768 \\\n' +
                '    trainor.batch_size=16 \\\n' +
                '    trainor.grad_accu=8 \\\n' +
                '    trainor.optim_params.lr=0.0003 \\\n' +
                '    trainor.optimizer=Adam \\\n' +
                '    trainor.early_stop_metric=bertscore \\\n' +
                '    trainor.early_stop=10 \\\n' +
                '    validator.batch_size=8 \\\n' +
                '    validator.beam_width=2 \\\n' +
                '    validator.metrics=\'[bertscore]\' \\\n' +
                '    validator.splits=\'[validate]\' \\\n' +
                '    ckpt_dir=ckpt \\\n' +
                '    name=nll_findings_bertscore_128'

            train_rl = 'nll_checkpoint=ckpt/nll_findings_bertscore_128/FILENAME.pth\n' +
                'python bin/train.py config/RRG/baseline-mimic.yml \\\n' +
                '            dataset.seq.processing=ifcc_clean_report \\\n' +
                '            dataset.image.root=data/RRG/mimic-cxr/findings/ \\\n' +
                '            dataset.seq.root=data/RRG/mimic-cxr/findings/ \\\n' +
                '            dataset.seq.file=findings.tok \\\n' +
                '            dataset.seq.tokenizer_max_len=128 \\\n' +
                '            dataset.image.file=image.tok \\\n' +
                '            dataset.image.image_path=data/images/ \\\n' +
                '            dataset.image.multi_image=3 \\\n' +
                '            model.proto=RRG_SCST \\\n' +
                '            model.top_k=0 \\\n' +
                '            model.ckpt=${nll_checkpoint} \\\n' +
                '            model.scores_weights=\'[0.01,0.495,0.495]\' \\\n' +
                '            model.scores_args=\'[{},{reward_level: partial}]\' \\\n' +
                '            model.scores=[bertscore,radgraph] \\\n' +
                '            model.use_nll=true \\\n' +
                '            model.cnn.backbone=densenet121 \\\n' +
                '            model.cnn.visual_projection.in_features=1024 \\\n' +
                '            model.cnn.visual_projection.out_features=768 \\\n' +
                '            trainor.batch_size=12 \\\n' +
                '            trainor.optim_params.lr=5e-5 \\\n' +
                '            trainor.optimizer=Adam \\\n' +
                '            trainor.grad_accu=2 \\\n' +
                '            trainor.early_stop_metric=bertscore \\\n' +
                '            trainor.early_stop=3 \\\n' +
                '            trainor.lr_decay_params.patience=0 \\\n' +
                '            trainor.lr_decay_params.factor=0.8 \\\n' +
                '            validator.batch_size=16 \\\n' +
                '            validator.beam_width=2 \\\n' +
                '            validator.metrics=\'[bertscore,radgraph]\' \\\n' +
                '            ckpt_dir=ckpt \\\n' +
                '            name=rl_findings_bertscore_128'
            test = 'python bin/ensemble.py config/RRG/baseline-mimic.yml \\\n' +
                '            dataset.seq.processing=ifcc_clean_report \\\n' +
                '            dataset.image.root=data/RRG/mimic-cxr/findings/ \\\n' +
                '            dataset.seq.root=data/RRG/mimic-cxr/findings/ \\\n' +
                '            dataset.seq.file=findings.tok \\\n' +
                '            dataset.seq.tokenizer_max_len=128 \\\n' +
                '            dataset.image.file=image.tok \\\n' +
                '            dataset.image.image_path=data/images/ \\\n' +
                '            dataset.image.multi_image=3 \\\n' +
                '            model.proto=RRG_SCST \\\n' +
                '            model.top_k=0 \\\n' +
                '            model.scores_weights=\'[0.01,0.495,0.495]\' \\\n' +
                '            model.scores_args=\'[{},{reward_level: partial}]\' \\\n' +
                '            model.scores=[bertscore,radgraph] \\\n' +
                '            model.use_nll=true \\\n' +
                '            model.cnn.backbone=densenet121 \\\n' +
                '            model.cnn.visual_projection.in_features=1024 \\\n' +
                '            model.cnn.visual_projection.out_features=768 \\\n' +
                '            ensemblor.batch_size=16 \\\n' +
                '            ensemblor.beam_width=2 \\\n' +
                '            ensemblor.metrics=\'[bertscore,radgraph]\' \\\n' +
                '            ckpt_dir=ckpt \\\n' +
                '            name=rl_findings_bertscore_128'
        }

        setConfigSteps(
            [<Paragraph>
                <pre>{train_nll}</pre>
            </Paragraph>, <Paragraph>
                <pre>{train_rl}</pre>
            </Paragraph>, <Paragraph>
                <pre>{test}</pre>
            </Paragraph>],
        )
        setIsConfigModalOpen(true);
    };


    const dataSourceBaselineMimicIII = [
        {
            key: 'modelfindings1',
            model: 'RRG SCST (findings)',
            split: 'validate',
            rougel: 30.8,
            chexbert: "58.8",
            bertscore: '61.78',
            radgraph: "39.71",
        },
        {
            key: 'modelfindings2',
            model: 'RRG SCST (findings)',
            split: 'test',
            rougel: 26.5,
            chexbert: "62.2",
            bertscore: '58.5',
            radgraph: 34.7,
        },
    ];

    const columnsBaseline = [
        {
            title: 'Model',
            dataIndex: 'model',
            key: 'model',
        },
        {
            title: 'Split',
            dataIndex: 'split',
            key: 'split',
        },
        {
            title: 'ROUGEL',
            dataIndex: 'rougel',
            key: 'rougel',
        },
        {
            title: 'F1CheXbert',
            dataIndex: 'chexbert',
            key: 'chexbert',
        },
        {
            title: 'bertscore',
            dataIndex: 'bertscore',
            key: 'bertscore',
        },
        {
            title: 'F1RadGraph (ER)',
            dataIndex: 'radgraph',
            key: 'address',
        },
        {
            title: '',
            key: 'config',
            render: (text, record, index) => <a onClick={() => showConfig(record)}>Config</a>,
        }
    ];


    const onLoginFinish = async (values) => {
        setLoginEpilogue(<Spin/>)
        const ret = await downloadApi.downloadPhysionet(values["username"], values["password"], currentDataset)
        if ("error" in ret) {
            message.error(ret["error"]);
            setLoginEpilogue("")
        } else {
            setLoginEpilogue(<Link href={ret["file_url"]}>{ret["file_url"]}</Link>)
        }
    };

    const onLoginFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const showLoginModal = (dataset) => {
        setLoginIsModalOpen(true);
        setCurrentDataset(dataset)
    };

    const handleLoginCancel = () => {
        setLoginIsModalOpen(false);
        setLoginEpilogue("")
    };

    const configPretrainedFindings = 'rl_checkpoint=0.617898_11_358234.pth\n' +
        'python bin/ensemble.py config/RRG/baseline-mimic.yml \\\n' +
        '            dataset.seq.processing=ifcc_clean_report \\\n' +
        '            dataset.image.root=data/RRG/mimic-cxr/findings/ \\\n' +
        '            dataset.seq.root=data/RRG/mimic-cxr/findings/ \\\n' +
        '            dataset.seq.file=findings.tok \\\n' +
        '            dataset.seq.tokenizer_max_len=128 \\\n' +
        '            dataset.image.file=image.tok \\\n' +
        '            dataset.image.image_path=data/images/ \\\n' +
        '            dataset.image.multi_image=3 \\\n' +
        '            model.proto=RRG_SCST \\\n' +
        '            model.top_k=0 \\\n' +
        '            model.scores_weights=\'[0.01,0.495,0.495]\' \\\n' +
        '            model.scores_args=\'[{},{reward_level: partial}]\' \\\n' +
        '            model.scores=[bertscore,radgraph] \\\n' +
        '            model.use_nll=true \\\n' +
        '            model.cnn.backbone=densenet121 \\\n' +
        '            model.cnn.visual_projection.in_features=1024 \\\n' +
        '            model.cnn.visual_projection.out_features=768 \\\n' +
        '            ensemblor.batch_size=16 \\\n' +
        '            ensemblor.beam_width=2 \\\n' +
        '            ensemblor.metrics=\'[bertscore,radgraph,chexbert,rougel]\' \\\n' +
        '            ensemblor.splits=[validate,test] \\\n' +
        '            ensemblor.ckpt=${rl_checkpoint} \\\n' +
        '            ckpt_dir=ckpt \\\n' +
        '            name=emnlp22_rl_findings_bertscore_128\n'

    const mimiccxrLineImpCount = `$ wc -l *
    2224 test.image.tok
    2224 test.impression.tok
  185816 train.image.tok
  185816 train.impression.tok
    1521 validate.image.tok
    1521 validate.impression.tok
  379122 total
`
    const mimiccxrLineFindCount = `$ wc -l *
    2347 test.findings.tok
    2347 test.image.tok
  152173 train.findings.tok
  152173 train.image.tok
    1196 validate.findings.tok
    1196 validate.image.tok
  311432 total
`

    const dataSourceMimicCXR = [
        {
            key: 'mimic1',
            name: 'X-rays Chest (impression)',
            num_im: 322981,
            num: 189561,
            tr: "185816",
            val: "1521",
            te: "2224",
        },
        {
            key: 'mimic2',
            name: 'X-rays Chest (findings)',
            num_im: 276778,
            num: 155716,
            tr: "152173",
            val: "1196",
            te: "2347",
        },
    ];

    const columnsMimicCXR = [{
        title: 'Modality/Anatomy', dataIndex: 'name', key: 'name',
    }, {
        title: 'Number of images', dataIndex: 'num_im', key: 'num',
    }, {
        title: 'Number of reports', dataIndex: 'num', key: 'num',
    }, {
        title: 'Train', dataIndex: 'tr', key: 'tr',
    }, {
        title: 'Validation', dataIndex: 'val', key: 'val',
    }, {
        title: 'Test', dataIndex: 'te', key: 'te',
    },];

    return (
        <>
            <Space direction={"vertical"}>
                <div style={{width: 900}}>
                    <PageHeader
                        title=""
                        className="site-page-header"
                        subTitle="Improving the Factual Correctness of Radiology Report Generation with Semantic Rewards"
                        extra={[
                            <Button key="b3" icon={<FilePdfOutlined/>}
                                    href={"https://aclanthology.org/2022.findings-emnlp.319"}>Paper</Button>,
                            <Button key="b2"
                                    icon={<SettingOutlined/>}
                                    href={"/papers/emnlp2022/"}>Config</Button>,
                        ]}
                        avatar={{
                            src: 'https://d1nhio0ox7pgb.cloudfront.net/_img/g_collection_png/standard/512x512/graph_fork.png',
                        }}
                    >
                        <Content
                            extraContent={
                                <img
                                    src="https://pbs.twimg.com/media/FSvRXQWX0AIcy7S.jpg"
                                    alt="content"
                                    width="146px"
                                />
                            }
                        >
                            <>
                                <Paragraph>
                                    Neural image-to-text radiology report generation systems offer the potential to
                                    improve
                                    radiology reporting by reducing the repetitive process of report drafting and
                                    identifying possible medical errors. We propose a new method, the RadGraph
                                    reward,
                                    to
                                    further improve the factual completeness and correctness
                                    of generated radiology reports. More precisely, we leverage the RadGraph dataset
                                    containing annotated chest X-ray reports with entities and relations between
                                    entities.
                                </Paragraph>
                            </>
                        </Content>
                    </PageHeader>

                    <Title level={3}>1. Installation</Title>
                    <Paragraph><Alert
                        message={<>To replicate the results, you first need to install <img style={{"width": 14}}
                                                                                            src={"/favicon/favicon-64x64.png"}
                                                                                            alt={""}/> <Link
                            href={"/documentation/installation/"}> ViLMedic.</Link></>}
                        type="info" showIcon/>
                    </Paragraph>


                    <Title level={3}>2. Data</Title>
                    <Alert
                        message="Informational Notes"
                        description="The dataset used in this section is from the MIMIC-CXR database.
                        In order to download the data, you will need a PhysioNet account with signed agreements on the dataset."
                        type="info"
                        showIcon
                    />
                    <br/>
                    <Paragraph>
                        The dataset used in this paper is the MIMIC-CXR Radiology Report Generation dataset.
                    </Paragraph>

                    <Title level={4}>2.1 MIMIC-CXR</Title>

                    <Paragraph>
                        <blockquote>Johnson, A.E.W., Pollard, T.J., Berkowitz, S.J. et al. MIMIC-CXR, a de-identified
                            publicly available database of chest radiographs with free-text reports. Sci Data 6, 317
                            (2019). https://doi.org/10.1038/s41597-019-0322-0
                        </blockquote>
                        The dataset consists of multimodal tuples (images, impression/findings).
                    </Paragraph>

                    <Table dataSource={dataSourceMimicCXR} columns={columnsMimicCXR} pagination={false}/>

                    <Alert
                        message="Informational Notes"
                        description={<>
                            <Paragraph>You can instantly download the preprocessed and resized (512px) mimic-cxr dataset
                                with images using the following form with your PhysioNet credentials. Your credentials
                                are used to ping the mimic-cxr-jpg PhysioNet resource
                                (<Link>https://physionet.org/content/mimic-cxr-jpg/2.0.0/</Link>) to ensure you
                                have access.
                            </Paragraph>
                            <Paragraph>
                                If you do not wish to proceed that way, you can recreate the dataset
                                following the instructions <img style={{"width": 14}}
                                                                src={"/favicon/favicon-64x64.png"}
                                                                alt={""}/> <Link
                                href={"https://github.com/jbdel/vilmedic/tree/main/data/make_datasets/mimic_cxr"}>
                                ViLMedic</Link> and using the following command:
                            </Paragraph>
                            <SyntaxHighlighter language="bash" style={codeStyle}>
                                python make_mimic_cxr.py --task rrg
                            </SyntaxHighlighter>
                        </>}
                        type="info"
                        showIcon
                    />
                    <br/>
                    <Paragraph>
                        The dataset and splits can be instantly downloaded using <Link
                        onClick={() => showLoginModal("emnlp23-rrg-mimic-cxr")}>this
                        form</Link>.<br/>
                        You should have the following dataset for <b>impressions</b>:
                        <SyntaxHighlighter language="bash" style={codeStyle}>
                            {mimiccxrLineImpCount}
                        </SyntaxHighlighter>
                        You should have the following dataset for <b>findings</b>:
                        <SyntaxHighlighter language="bash" style={codeStyle}>
                            {mimiccxrLineFindCount}
                        </SyntaxHighlighter>
                    </Paragraph>
                    <Title level={4}>3. Results</Title>
                    <Paragraph>
                        <Table dataSource={dataSourceBaselineMimicIII} columns={columnsBaseline} size={"middle"}
                               pagination={false}/>
                    </Paragraph>
                    <Paragraph>

                        <Alert
                            message="F1RadGraph"
                            description={<>
                                The F1RadGraph (ER) metric can be computed using the following python package: <Link
                                href={"https://pypi.org/project/radgraph/"}>radgraph</Link>
                            </>
                            }
                            type="warning"
                            showIcon
                        />
                    </Paragraph>
                    <Title level={4}>4. Checkpoints</Title>
                    <Paragraph>
                        You can download the pretrain model on findings to replicate the evaluation using this
                        link:<br/>
                        <Link
                            href={"https://storage.googleapis.com/vilmedic_dataset/checkpoints/RRG/emnlp22_rl_findings_bertscore_128.zip"}>https://storage.googleapis.com/vilmedic_dataset/checkpoints/RRG/emnlp22_rl_findings_bertscore_128.zip</Link>
                    </Paragraph>

                    <Paragraph>
                        Unzip the folder in: "ckpt/emnlp22_rl_findings_bertscore_128" and run:
                    </Paragraph>
                    <SyntaxHighlighter language="bash" style={codeStyle}>
                        {configPretrainedFindings}
                    </SyntaxHighlighter>
                </div>
            </Space>

            <Modal title={"Download the " + currentDataset + " dataset"} open={isLoginModalOpen} footer={null}
                   onCancel={handleLoginCancel}>
                <LoginPassword onFinish={onLoginFinish}
                               onFinishFailed={onLoginFinishFailed}
                               preamble={"Enter here the credential of your physionet account."}
                               epilogue={loginEpilogue}
                />
            </Modal>

            <Modal title="Config" width={700} open={isConfigModalOpen} onOk={handleConfigOk}
                   onCancel={handleConfigCancel}>

                <Steps current={inConfigCurrent}>
                    <Step key={"1"} title={"Train NLL"}/>
                    <Step key={"2"} title={"Train RL"}/>
                    <Step key={"3"} title={"Test"}/>
                </Steps>
                <div className="steps-content">{configSteps[inConfigCurrent]}</div>
                <div className="steps-action">
                    {inConfigCurrent < configSteps.length - 1 && (
                        <Button type="primary" onClick={() => configNext()}>
                            Next
                        </Button>
                    )}
                    {inConfigCurrent > 0 && (
                        <Button style={{margin: '0 8px'}} onClick={() => configPrev()}>
                            Previous
                        </Button>
                    )}
                </div>
            </Modal>
        </>
    );
};

export default Emnlp2022
