import React, {useEffect} from 'react'
import Rrs from "./RRS"

const RRSContainer = () => {

    useEffect(() => {
    }, [])

    return (
        <Rrs/>
    )
}

export default RRSContainer