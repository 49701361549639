import React, {useContext} from 'react'
import {Alert, List, Space, Typography} from 'antd';
import "./style/style.css"
import SyntaxHighlighter from 'react-syntax-highlighter';
import GlobalContext from "contexts/GlobalContext";

const {Paragraph} = Typography;

const codeGit = `git clone https://github.com/jbdel/vilmedic
pip install -e .
`
const codeTree = `|-- vilmedic
\t|-- data
\t|-- bin
\t|-- config
\t|-- vilmedic
\t|  |-- datasets
\t|  |-- executors
\t|  |-- networks
\t|  |  |  |-- blocks
\t|  |  |  |-- models
\t|  |-- scorers
`
const Installation = () => {
    const {codeStyle} = useContext(GlobalContext);
    return (
        <div style={{width: 900}}>
            <Space direction={"vertical"}>
                <Paragraph>
                    <img style={{"width": 14}} src={"/favicon/favicon-64x64.png"} alt={"vilmedic-front"}/> ViLMedic is built
                    on top of
                    important packages, <img style={{"width": 14}} src={"/images/pytorch.png"} alt={"pytorch"}/> pytorch
                    and <img style={{"width": 14}} src={"/images/hf.png"} alt={"hf"}/> huggingface
                </Paragraph>

                <Alert
                    message="Informational Notes"
                    description={
                        <>
                            <Paragraph>
                                ViLMedic is easier to install in a new conda environment
                            </Paragraph>
                            <SyntaxHighlighter language="bash" style={codeStyle}>
                                conda create --name vilmedic python==3.8 -y
                            </SyntaxHighlighter>
                        </>
                    }
                    type="info"
                    showIcon
                />

                <List
                    size="large"
                    header={<div>There is two ways of installing ViLMedic:</div>}
                    bordered
                >
                    <List.Item>
                        If you plan to use the model-zoo only, you can install ViLMedic using pip:
                        <SyntaxHighlighter language="bash" style={codeStyle}>
                            pip install vilmedic
                        </SyntaxHighlighter>
                    </List.Item>
                    <List.Item>
                        If you plan on training or replicate solutions, install ViLMedic in development mode:
                        <SyntaxHighlighter language="bash" style={codeStyle}>
                            {codeGit}
                        </SyntaxHighlighter>
                    </List.Item>
                </List>
                ViLMedic is structured as follows:
                <SyntaxHighlighter language="bash" style={codeStyle}>
                    {codeTree}
                </SyntaxHighlighter>
            </Space>
        </div>

    )
}

export default Installation
