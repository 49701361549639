import React, {useEffect} from 'react'
import StudyNLG from "./StudyNLG.jsx"
import {Layout,} from 'antd';

const {Content} = Layout;
const StudyNLGContainer = () => {

    useEffect(() => {
    }, [])


    // const centeredContainerStyle = {
    //     display: 'flex',
    //     justifyContent: 'center',
    //     height: '100vh',
    //     overflowY: 'auto', // Add this line to enable vertical scrolling
    // };

    return (

        <Layout>
            <Content>
                <div >
                    <StudyNLG/>
                </div>
            </Content>
        </Layout>

    )
}

export default StudyNLGContainer