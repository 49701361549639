import React, {useEffect, useState} from 'react'
import {
    Button,
    Divider,
    Dropdown,
    Input,
    message,
    notification,
    Select,
    Space,
    Spin,
    Table,
    Tooltip,
    Typography,
    Upload
} from 'antd';
import {InboxOutlined, UserOutlined, UnlockOutlined, FileAddOutlined, DownOutlined} from '@ant-design/icons';
import bionlp24Api from "services/misc/bionlp24";

const {Dragger} = Upload;


const columnsAll = [
    {
        title: 'Team',
        dataIndex: 'team',
        key: 'team',
    },
    {
        title: 'Dataset',
        dataIndex: 'dataset',
        key: 'dataset',
    },
    {
        title: 'BLEU4',
        dataIndex: 'bleu',
        key: 'bleu',
        render: (text) =>
            text === null ? 'N/A' : (text * 100).toFixed(2)
    },
    {
        title: 'ROUGEL',
        dataIndex: 'rougel',
        key: 'rougel',
        render: (text) =>
            text === null ? 'N/A' : (text * 100).toFixed(2)
    },
    {
        title: 'Bertscore',
        dataIndex: 'bertscore',
        key: 'bertscore',
        render: (text) =>
            text === null ? 'Computing...' : (text * 100).toFixed(2)
    },
    {
        title: 'F1-cheXbert',
        dataIndex: 'f1chexbert',
        key: 'f1chexbert',
        render: (text) =>
            text === null ? 'Computing...' : text === undefined ? "N/A" : (text * 100).toFixed(2)
    },
    {
        title: 'F1-RadGraph',
        dataIndex: 'f1radgraph',
        key: 'f1radgraph',
        render: (text) =>
            text === null ? 'Computing...' : (text * 100).toFixed(2)
    },
];


const Retrieve = ({}) => {

    const [team, setTeam] = useState(null);
    const [waitForResult, setWaitForResult] = useState(false);
    const [data, setData] = useState(null);

    const openNotificationWithIcon = (type, description) => {
        notification[type]({
            message: 'Notification: ' + type,
            description: description,
            duration: 0,
        });
    };

    useEffect(() => {
        resetAll()
    }, [])


    function resetAll() {
        setWaitForResult(false)
        setData(null)
        setTeam(null)
    }

    const submitResults = async () => {
        setWaitForResult(true)


        if (!(team) || team === "") {
            message.error("No team provided")
            setWaitForResult(false)
            return
        }


        let ret = await bionlp24Api.getScoresForTeam(team)
        console.log(ret)

        if (!(Array.isArray(ret))) {
            openNotificationWithIcon("error", "Error happened when retrieving the submissions. Try later.")
            resetAll()
            return
        }

        if ('error' in ret) {
            openNotificationWithIcon("error", ret["error"])
            resetAll()
            return
        }
        setData(ret)
        setWaitForResult(false)
    }


    return (
        <>
            <Space direction={"vertical"}>
                <>
                    <Input placeholder="Team name" onChange={(e) => setTeam(e.target.value)}
                           prefix={<UserOutlined/>}
                           style={{width: 200}}/>
                    <Space direction={"horizontal"}>
                        <Button type="primary" onClick={() => submitResults()}>Submit</Button>
                    </Space>
                </>

                <div>
                    {waitForResult &&
                        <div style={{padding: 50}}>
                            <Spin tip="Retrieving scores...">
                                <div className="content"/>
                            </Spin>
                        </div>
                    }
                    {data &&
                        <div>
                            <Divider orientation="left">Team results</Divider>
                            <Table columns={columnsAll} dataSource={data}/>
                        </div>
                    }
                </div>
            </Space>
        </>

    )
}

export default Retrieve