import React from 'react'
import {Alert, Button, Divider, PageHeader, Popover, Row, Space, Tag, Typography} from 'antd';
import "./style/style.css"
import {
    GithubOutlined,
    FilePdfOutlined,
    WindowsOutlined,
    FileSearchOutlined, CaretRightOutlined, SettingOutlined, ChromeOutlined
} from '@ant-design/icons';
import {Link} from "react-router-dom";


const {Title, Paragraph, Text} = Typography;

const Home = () => {

    const Content = ({children, extraContent}) => (
        <Row key={"rowContent"}>
            <div key="children" style={{flex: 1,}}>{children}</div>
            <div key="extraContent" className="image">{extraContent}</div>
        </Row>
    );

    const showEmail = (
        <div>
            jbdel@stanford.edu
        </div>
    );

    return (
        <div style={{width: 900}}>
            <Space direction={"vertical"}>
                <PageHeader
                    title="ViLMedic"
                    className="site-page-header"
                    subTitle="(Vision-and-Language medical research)"
                    tags={<Tag color="blue">v1.3.1</Tag>}
                    extra={[
                        <Button key="b3" icon={<FilePdfOutlined/>}
                                href={"https://aclanthology.org/2022.acl-demo.3/"}>Paper</Button>,
                        <Button key="b2" icon={<GithubOutlined/>}
                                href={"https://github.com/jbdel/vilmedic"}>Github</Button>,
                        <Popover content={showEmail} title="Contact us">
                            <Button key="b1" type="primary">
                                Contact us
                            </Button>
                        </Popover>,
                    ]}
                    avatar={{
                        src: 'https://raw.githubusercontent.com/jbdel/vilmedic/main/vilmedic/logo.png',
                    }}
                >
                    <Content
                        extraContent={
                            <img
                                src="/images/acl2022.png"
                                alt="content"
                                width="146px"
                            />
                        }
                    >
                        <>
                            <Paragraph>
                                ViLMedic is a modular framework for vision and language
                                multimodal
                                research in the medical field.
                            </Paragraph>
                            <Paragraph>
                                This library contains reference implementations of state-of-the-art vision
                                and language architectures, referred as “blocks” and full solutions for multimodal
                                medical
                                tasks using one or
                                several blocks.
                            </Paragraph>
                            <div key={"bottom-links"}>
                                <Link to="/quickstart/" className="example-link">
                                    <CaretRightOutlined className="example-link-icon"/>
                                    {"Quickstart"}
                                </Link>
                                <Link to="/documentation/installation/" className="example-link">
                                    <FileSearchOutlined className="example-link-icon"/>
                                    {"Documentation"}
                                </Link>
                                <Link to="/software" className="example-link">

                                    <WindowsOutlined className="example-link-icon"/>
                                    {"Software"}
                                </Link>
                            </div>
                        </>
                    </Content>
                </PageHeader>
                <Divider/>
                <Paragraph>
                    <Alert message={<>ViLMedic also fueled the following <img style={{"width": 14}}
                                                                              src={"images/225px-Logo_of_Stanford_University.png"}
                                                                              alt={""}/> stanford research</>}
                           type="info"/>

                </Paragraph>

                {/*################################################################################*/}

                <PageHeader
                    title=""
                    className="site-page-header"
                    subTitle={
                        <span>
                          RaLEs: a Benchmark for Radiology Language Evaluations
                        </span>
                    }
                    extra={[
                        <Button key="b3" icon={<FilePdfOutlined/>}
                                href={"https://openreview.net/forum?id=PWLGrvoqiR"}>Paper</Button>,

                    ]}
                    avatar={{
                        src: 'https://static.thenounproject.com/png/3353303-200.png',
                    }}
                >

                    <Content
                        extraContent={
                            <img
                                alt="content"
                                width="146px"
                                src="https://alregib.ece.gatech.edu/files/2023/04/NeurIPS_logo.png"
                            />
                        }
                    >
                        <>
                            <Paragraph>
                                <blockquote>
                                    The radiology report is a crucial communication tool between radiologists and other
                                    medical professionals. Recent work has revealed the importance of task-specific
                                    natural language processing methods in radiology, while benchmarks in English and
                                    biomedical domains have spurred the development of adaptable models. Introducing
                                    RaLEs, a benchmark for radiology language understanding and generation, this study
                                    emphasizes the distinct nature of the radiology domain, showing that advancements in
                                    general and biomedical areas don't always benefit radiology, thus underscoring the
                                    need for specialized radiology language models.
                                </blockquote>
                            </Paragraph>
                        </>
                    </Content>
                </PageHeader>


                <PageHeader
                    title=""
                    className="site-page-header"
                    subTitle={
                        <span>
                          ViLLA: Fine-Grained Vision-Language Representation Learning from Real-World Data
                        </span>
                    }
                    extra={[
                        <Button key="b3" icon={<FilePdfOutlined/>}
                                href={"https://openaccess.thecvf.com/content/ICCV2023/papers/Varma_ViLLA_Fine-Grained_Vision-Language_Representation_Learning_from_Real-World_Data_ICCV_2023_paper.pdf"}>Paper</Button>,
                        <Button key="b2" icon={<GithubOutlined/>}
                                href={"https://github.com/stanfordmimi/villa"}>Github</Button>,

                    ]}
                    avatar={{
                        src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2_FwCD7rmeDNn3Ng8BnL5zENzmlGOCNVo6JgNtb4&s',
                    }}
                >

                    <Content
                        extraContent={
                            <img
                                alt="content"
                                width="146px"
                                src="https://iccv2023.thecvf.com/img/LogoICCV23V04.svg"
                            />
                        }
                    >
                        <>
                            <Paragraph>
                                <blockquote>
                                    Vision-language models (VLMs) like CLIP and ALIGN are usually trained on simple
                                    image-caption pairs from the web. However, real-world datasets, especially in
                                    healthcare, have images paired with detailed text that highlights numerous specific
                                    attributes in the image, resulting in high pairwise complexity. Current VLMs falter
                                    in learning these fine-grained relationships, with performance dropping by as much
                                    as 37% on certain tasks. This study introduces ViLLA, a new model designed to
                                    capture these detailed relationships. ViLLA uses a mapping model to break down
                                    image-text pairs and a contrastive VLM for learning from the resultant pairs.
                                    Experiments show ViLLA outperforms other VLMs in tasks like zero-shot object
                                    detection and retrieval.
                                </blockquote>
                            </Paragraph>
                        </>
                    </Content>
                </PageHeader>

                <PageHeader
                    title=""
                    className="site-page-header"
                    subTitle={
                        <span>
                          RadAdapt: Radiology Report Summarization via Lightweight Domain Adaptation of <br/>Large Language Models
                        </span>
                    }
                    extra={[
                        <Button key="b3" icon={<FilePdfOutlined/>}
                                href={"https://aclanthology.org/2023.bionlp-1.42/"}>Paper</Button>,

                    ]}
                    avatar={{
                        src: 'https://cdn-icons-png.flaticon.com/512/1716/1716265.png',
                    }}
                >

                    <Content
                        extraContent={
                            <img
                                alt="content"
                                width="146px"
                                src="images/acl23logo.jpeg"
                            />
                        }
                    >
                        <>
                            <Paragraph>
                                <blockquote>
                                    This study investigates lightweight strategies for adapting large language models to
                                    radiology report summarization, finding that maximum adaptation through pretraining
                                    on clinical text and fine-tuning on RRS examples achieves the best performance with
                                    minimal parameter fine-tuning. The findings highlight the importance of domain
                                    adaptation and provide insights for developing effective natural language processing
                                    solutions in clinical tasks.
                                </blockquote>
                            </Paragraph>
                        </>
                    </Content>
                </PageHeader>

                <PageHeader
                    title=""
                    className="site-page-header"
                    subTitle={
                        <span>
                          Overview of the RadSum23 Shared Task on Multi-modal and Multi-anatomical <br/>
                            Radiology Report Summarization
                        </span>
                    }
                    extra={[
                        <Button key="b3" icon={<FilePdfOutlined/>}
                                href={"https://aclanthology.org/2023.bionlp-1.45/"}>Paper</Button>,
                        <Button key="b2" icon={<ChromeOutlined/>}
                                href={"/misc/bionlp23/sharedtask/"}>Challenge</Button>,

                    ]}
                    avatar={{
                        src: 'https://icon-library.com/images/summary-report-icon/summary-report-icon-26.jpg',
                    }}
                >

                    <Content
                        extraContent={
                            <img
                                alt="content"
                                width="146px"
                                src="/images/acl23logo.jpeg"
                            />
                        }
                    >
                        <>
                            <Paragraph>
                                <blockquote>
                                    The field of radiology report summarization is growing, with recent systems
                                    achieving promising results. However, the lack of publicly available datasets and
                                    the focus on chest X-rays limit the ability to reproduce and compare different
                                    systems. To address these limitations, a Radiology Report Summarization (RadSum)
                                    challenge was proposed, introducing new datasets covering various modalities and
                                    anatomies, including a multimodal dataset. The challenge received 112 submissions
                                    from 11 teams.
                                </blockquote>
                            </Paragraph>
                        </>
                    </Content>
                </PageHeader>

                <PageHeader
                    title=""
                    className="site-page-header"
                    subTitle={
                        <span>
                          Toward Expanding the Scope of Radiology Report

                          Summarization to Multiple <br/> Anatomies and Modalities
                        </span>
                    }
                    extra={[
                        <Button key="b3" icon={<FilePdfOutlined/>}
                                href={"https://aclanthology.org/2023.acl-short.41/"}>Paper</Button>,
                        <Button key="b2"
                                icon={<ChromeOutlined/>}
                                href={"/papers/acl2023"}>Website</Button>


                    ]}
                    avatar={{
                        src: 'https://cdn-icons-png.flaticon.com/512/10190/10190139.png',
                    }}
                >

                    <Content
                        extraContent={
                            <img
                                alt="content"
                                width="146px"
                                src="images/acl23logo.jpeg"
                            />
                        }
                    >
                        <>
                            <Paragraph>
                                <blockquote>
                                    Radiology report summarization (RRS) is a growing field aiming to generate concise
                                    summaries of radiology reports. However, current RRS research faces limitations,
                                    including experiments on private datasets and evaluation focused on chest X-rays. To
                                    address these limitations, a new dataset called MIMIC-RRS is proposed, consisting of
                                    three modalities and seven anatomies. Extensive experiments are conducted to
                                    evaluate model performance within and across these pairs, along with clinical
                                    efficacy assessment using the RadGraph metric.
                                </blockquote>
                            </Paragraph>
                        </>
                    </Content>
                </PageHeader>


                <PageHeader
                    title=""
                    className="site-page-header"
                    subTitle="BioNLP 2023 Shared Task"
                    extra={[
                        <Button key="b3" icon={<ChromeOutlined/>}
                                href={"https://aclweb.org/aclwiki/BioNLP_Workshop"}>Website</Button>,
                        <Button key="b2" icon={<ChromeOutlined/>}
                                href={"/misc/bionlp23/sharedtask/"}>Challenge</Button>,
                    ]}
                    avatar={{
                        src: 'https://cdn2.iconfinder.com/data/icons/eco-food-and-cosmetic-labels-2/128/Artboard_54-512.png'
                    }}
                >
                    <Content
                        extraContent={
                            <img
                                alt="content"
                                width="146px"
                                src="images/acl23logo.jpeg"
                            />
                        }
                    >
                        <>
                            <Paragraph>
                                <blockquote>
                                    The research area of radiology report summarization currently faces an
                                    important limitation: most research is carried out on chest X-rays. To palliate
                                    these limitations,
                                    we propose two datasets:
                                    <ul>
                                        <li>
                                            A shared summarization task that includes six different modalities and
                                            anatomies,
                                            totalling 79,779 samples, based on the MIMIC-III database.
                                        </li>
                                        <li>
                                            A shared summarization task on chest x-ray radiology reports with images and
                                            a brand
                                            new out-of-domain test-set from Stanford.
                                        </li>
                                    </ul>
                                </blockquote>
                            </Paragraph>
                        </>
                    </Content>
                </PageHeader>

                <PageHeader
                    title=""
                    className="site-page-header"
                    subTitle="Domino: Discovering Systematic Errors with Cross-Modal Embedding"
                    extra={[
                        <Button key="b3" icon={<FilePdfOutlined/>}
                                href={"https://openreview.net/forum?id=FPCMqjI0jXN"}>Paper</Button>,
                        <Button key="b2" icon={<GithubOutlined/>}
                                href={"https://github.com/HazyResearch/domino"}>Github</Button>,
                    ]}
                    avatar={{
                        src: 'https://cdn-icons-png.flaticon.com/512/2136/2136953.png'
                    }}
                >
                    <Content
                        extraContent={
                            <img
                                src="/images/iclr_logo.png"
                                alt="content"
                                width="146px"
                            />
                        }
                    >
                        <>
                            <Paragraph>
                                <blockquote>
                                    Domino, a slice discovery method designed to identify coherent, underperforming data
                                    slices (i.e. groups of similar validation data points on which the model makes
                                    errors).
                                    It leverages a powerful class of recently-developed cross-modal representation
                                    learning
                                    approaches, which yield semantically-meaningful representations by embedding images
                                    and
                                    text in the same latent space.
                                </blockquote>
                            </Paragraph>
                        </>
                    </Content>
                </PageHeader>

                <PageHeader
                    title=""
                    className="site-page-header"
                    subTitle="Improving the Factual Correctness of Radiology Report Generation with Semantic Rewards"
                    extra={[
                        <Button key="b3" icon={<FilePdfOutlined/>}
                                href={"https://aclanthology.org/2022.findings-emnlp.319"}>Paper</Button>,
                        <Button key="b2"
                                icon={<SettingOutlined/>}
                                href={"/papers/emnlp2022/"}>Config</Button>,
                    ]}
                    avatar={{
                        src: 'https://d1nhio0ox7pgb.cloudfront.net/_img/g_collection_png/standard/512x512/graph_fork.png',
                    }}
                >
                    <Content
                        extraContent={
                            <img
                                src="https://pbs.twimg.com/media/FSvRXQWX0AIcy7S.jpg"
                                alt="content"
                                width="146px"
                            />
                        }
                    >
                        <>
                            <Paragraph>
                                <blockquote>

                                    Neural image-to-text radiology report generation systems offer the potential to
                                    improve
                                    radiology reporting by reducing the repetitive process of report drafting and
                                    identifying possible medical errors. We propose a new method, the RadGraph reward,
                                    to
                                    further improve the factual completeness and correctness
                                    of generated radiology reports. More precisely, we leverage the RadGraph dataset
                                    containing annotated chest X-ray reports with entities and relations between
                                    entities.
                                </blockquote>
                            </Paragraph>
                        </>
                    </Content>
                </PageHeader>


                <PageHeader
                    title=""
                    className="site-page-header"
                    subTitle="RoentGen: Vision-Language Foundation Model for Chest X-ray Generation"
                    extra={[
                        <Button key="b3" icon={<FilePdfOutlined/>}
                                href={"https://arxiv.org/abs/2211.12737"}>Paper</Button>,
                        <Button key="b2"
                                icon={<ChromeOutlined/>}
                                href={"https://stanfordmimi.github.io/RoentGen/"}>Website</Button>,
                    ]}
                    avatar={{
                        src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBJWN1zv1AyHMt2BiuVrCGX-TEPhOZKhOlPg&usqp=CAU',
                    }}
                >

                    <Content
                        extraContent={
                            <img
                                src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                                alt=""
                                width="146px"
                            />
                        }
                    >
                        <>
                            <Paragraph>
                                <blockquote>
                                    We develop a strategy to overcome the large natural-medical distributional shift by
                                    adapting a pre-trained latent diffusion model on a corpus of publicly available
                                    chest
                                    x-rays (CXR) and their corresponding radiology (text) reports. We investigate the
                                    model's ability to generate high-fidelity, diverse synthetic CXR conditioned on text
                                    prompts. We assess the model outputs quantitatively using image quality metrics, and
                                    evaluate image quality and text-image alignment by human domain experts.
                                </blockquote>
                            </Paragraph>
                        </>
                    </Content>
                </PageHeader>

            </Space>
        </div>

    )
}

export default Home
