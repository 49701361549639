import React, {useEffect} from 'react'
import RadGPT from "./RadGPT.jsx"
import {Layout} from 'antd';
import {
    ProviderWrapper as ExplainReportProvider
} from "components/Layout/Pages/RadGPT/explainReport/ExplainReportContext"
import {ProviderWrapper as RadGraphProvider} from "components/Layout/Pages/RadGPT/explainReport/RadGraphContext"
import {
    ProviderWrapper as SummarizationProvider
} from "components/Layout/Pages/RadGPT/explainReport/SummarizationContext"
import {ProviderWrapper as ConversationProvider} from "components/Layout/Pages/RadGPT/explainReport/ConversationContext"

const {Content} = Layout;
const RadGPTContainer = () => {

    useEffect(() => {
    }, [])

    return (
        <ConversationProvider>
            <ExplainReportProvider>
                <RadGraphProvider>
                    <SummarizationProvider>
                        <Layout>
                            <Content>
                                <RadGPT/>
                            </Content>
                        </Layout>
                    </SummarizationProvider>
                </RadGraphProvider>
            </ExplainReportProvider>
        </ConversationProvider>
    )
}

export default RadGPTContainer