import React, {useEffect, useState} from 'react'
import HFAssign from "./HFAssign";

const HFAssignContainer = () => {

    return (
        <HFAssign/>
    )
}

export default HFAssignContainer