import React, {useEffect} from 'react'
import SMMILE from "./SMMILE.jsx"
import {Layout,} from 'antd';

const {Content} = Layout;
const SMMILEContainer = () => {

    useEffect(() => {
    }, [])


    const centeredContainerStyle = {
        display: 'flex',
        justifyContent: 'center',
        height: '100vh',
        overflowY: 'auto', // Add this line to enable vertical scrolling
    };

    return (

        <Layout>
            <Content>
                <div style={centeredContainerStyle}>
                    <SMMILE/>
                </div>
            </Content>
        </Layout>

    )
}

export default SMMILEContainer