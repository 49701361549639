import React, {useState} from 'react';
import * as style from 'react-syntax-highlighter/dist/esm/styles/hljs';

const Context = React.createContext(null);

const ProviderWrapper = (props) => {
    const [language, setLanguage] = useState("en")
    const [codeStyle, setCodeStyle] = useState(style.atelierSulphurpoolLight)
    const [errorMessage, setErrorMessage] = useState("")

    const VilmedicTag = () => (
        <>
            <img style={{"width": 14}} src={"/favicon/favicon-64x64.png"} alt={"vilmedic-front"}/> ViLMedic
        </>
    )

    const exposedValues = {
        language,
        setLanguage,
        codeStyle,
        errorMessage,
        setErrorMessage,
        VilmedicTag,
    }

    return (
        <Context.Provider value={exposedValues}>{props.children}</Context.Provider>
    )
}
export {Context, ProviderWrapper};
export default Context;