import React, {useState, useEffect} from 'react';
import {Modal, Button, List, Select, Tag, message} from 'antd';

import {Collapse} from 'antd';

const {Option} = Select;

const {Panel} = Collapse;

const DiseaseEditor = ({initialResponse, onSaveChanges, isModalVisible, setIsModalVisible, textAreaContent}) => {
    const [utteranceDict, setUtteranceDict] = useState({});
    const [selectedDiseases, setSelectedDiseases] = useState({}); // To reset the Select after adding a disease
    const [upperModalInfo, setUpperModalInfo] = useState({visible: false, sentence: ''});

    console.log(utteranceDict)

    useEffect(() => {
        if (initialResponse && Object.keys(utteranceDict).length === 0) {
            console.log("her")
            const updatedResponse = {};
            Object.keys(initialResponse).forEach(key => {
                updatedResponse[key] = initialResponse[key] || [];
            });
            setUtteranceDict(updatedResponse);
        }
    }, [initialResponse]);

    const handleOk = () => {
        const allSentencesHaveDiseases = Object.values(utteranceDict).every(diseases => diseases.length > 0);
        if (!allSentencesHaveDiseases) {
            message.error('Please ensure all sentences have at least one disease.');
            return;
        }
        setIsModalVisible(false);
        onSaveChanges(utteranceDict);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleAddDisease = (sentence, disease) => {
        if (disease === 'Upper Level') {
            setUpperModalInfo({visible: true, sentence});
        } else {
            updateDiseaseList(sentence, disease);
        }
    };

    const updateDiseaseList = (sentence, disease) => {
        const updatedDiseases = [...(utteranceDict[sentence] || []), disease];
        setUtteranceDict({...utteranceDict, [sentence]: updatedDiseases});
        setSelectedDiseases({...selectedDiseases, [sentence]: null}); // Reset select to undefined
    };

    const handleModalSelectUpper = (disease) => {
        const {sentence} = upperModalInfo;
        updateDiseaseList(sentence, disease);
        setUpperModalInfo({visible: false, sentence: ''});
    };

    const handleCancelUpper = () => {
        setUpperModalInfo({visible: false, sentence: ''});
    };


    const handleRemoveDisease = (sentence, disease) => {
        const filteredDiseases = utteranceDict[sentence].filter(d => d !== disease);
        setUtteranceDict({...utteranceDict, [sentence]: filteredDiseases});
    };


    const diseasesUpper = [
        "Diffuse air space opacity (Absent)",
        "Diffuse air space opacity (Present)",
        "Diffuse air space opacity (Uncertain)",
        "Consolidation (Absent)",
        "Consolidation (Present)",
        "Consolidation (Uncertain)",
        "Segmental collapse (Absent)",
        "Segmental collapse (Present)",
        "Segmental collapse (Uncertain)",
        "Solitary masslike opacity (Absent)",
        "Solitary masslike opacity (Present)",
        "Solitary masslike opacity (Uncertain)",
        "Multiple masslike opacities (Absent)",
        "Multiple masslike opacities (Present)",
        "Multiple masslike opacities (Uncertain)",
        "Pneumothorax (Absent)",
        "Pneumothorax (Present)",
        "Pneumothorax (Uncertain)",
        "Pleural Effusion (Absent)",
        "Pleural Effusion (Present)",
        "Pleural Effusion (Uncertain)",
        "Widened cardiac silhouette (Absent)",
        "Widened cardiac silhouette (Present)",
        "Widened cardiac silhouette (Uncertain)",
        "Mediastinal mass (Absent)",
        "Mediastinal mass (Present)",
        "Mediastinal mass (Uncertain)",
        "Widened aortic contour (Absent)",
        "Widened aortic contour (Present)",
        "Widened aortic contour (Uncertain)",
        "Fracture (Absent)",
        "Fracture (Present)",
        "Fracture (Uncertain)",
        "Chest wall finding (Absent)",
        "Chest wall finding (Present)",
        "Chest wall finding (Uncertain)",
        "Support Devices (Absent)",
        "Support Devices (Present)",
        "Support Devices (Uncertain)",
        "Subdiaphragmatic gas (Absent)",
        "Subdiaphragmatic gas (Present)",
        "Subdiaphragmatic gas (Uncertain)"
    ];
// Define the list of diseases
    const diseases = [
        "Upper Level",
        "No finding",
        "Other",
        "Edema (Absent)",
        "Edema (Present)",
        "Edema (Uncertain)",
        "Pneumonia (Absent)",
        "Pneumonia (Present)",
        "Pneumonia (Uncertain)",
        "Atelectasis (Absent)",
        "Atelectasis (Present)",
        "Atelectasis (Uncertain)",
        "Aspiration (Absent)",
        "Aspiration (Present)",
        "Aspiration (Uncertain)",
        "Lung collapse (Absent)",
        "Lung collapse (Present)",
        "Lung collapse (Uncertain)",
        "Perihilar airspace opacity (Absent)",
        "Perihilar airspace opacity (Present)",
        "Perihilar airspace opacity (Uncertain)",
        "Air space opacity–multifocal (Absent)",
        "Air space opacity–multifocal (Present)",
        "Air space opacity–multifocal (Uncertain)",
        "Mass/Solitary lung mass (Absent)",
        "Mass/Solitary lung mass (Present)",
        "Mass/Solitary lung mass (Uncertain)",
        "Nodule/Solitary lung nodule (Absent)",
        "Nodule/Solitary lung nodule (Present)",
        "Nodule/Solitary lung nodule (Uncertain)",
        "Cavitating mass with content (Absent)",
        "Cavitating mass with content (Present)",
        "Cavitating mass with content (Uncertain)",
        "Cavitating masses (Absent)",
        "Cavitating masses (Present)",
        "Cavitating masses (Uncertain)",
        "Emphysema (Absent)",
        "Emphysema (Present)",
        "Emphysema (Uncertain)",
        "Fibrosis (Absent)",
        "Fibrosis (Present)",
        "Fibrosis (Uncertain)",
        "Pulmonary congestion (Absent)",
        "Pulmonary congestion (Present)",
        "Pulmonary congestion (Uncertain)",
        "Hilar lymphadenopathy (Absent)",
        "Hilar lymphadenopathy (Present)",
        "Hilar lymphadenopathy (Uncertain)",
        "Bronchiectasis (Absent)",
        "Bronchiectasis (Present)",
        "Bronchiectasis (Uncertain)",
        "Simple pneumothorax (Absent)",
        "Simple pneumothorax (Present)",
        "Simple pneumothorax (Uncertain)",
        "Loculated pneumothorax (Absent)",
        "Loculated pneumothorax (Present)",
        "Loculated pneumothorax (Uncertain)",
        "Tension pneumothorax (Absent)",
        "Tension pneumothorax (Present)",
        "Tension pneumothorax (Uncertain)",
        "Simple pleural effusion (Absent)",
        "Simple pleural effusion (Present)",
        "Simple pleural effusion (Uncertain)",
        "Loculated pleural effusion (Absent)",
        "Loculated pleural effusion (Present)",
        "Loculated pleural effusion (Uncertain)",
        "Pleural scarring (Absent)",
        "Pleural scarring (Present)",
        "Pleural scarring (Uncertain)",
        "Hydropneumothorax (Absent)",
        "Hydropneumothorax (Present)",
        "Hydropneumothorax (Uncertain)",
        "Pleural Other (Absent)",
        "Pleural Other (Present)",
        "Pleural Other (Uncertain)",
        "Cardiomegaly (Absent)",
        "Cardiomegaly (Present)",
        "Cardiomegaly (Uncertain)",
        "Pericardial effusion (Absent)",
        "Pericardial effusion (Present)",
        "Pericardial effusion (Uncertain)",
        "Inferior mediastinal mass (Absent)",
        "Inferior mediastinal mass (Present)",
        "Inferior mediastinal mass (Uncertain)",
        "Superior mediastinal mass (Absent)",
        "Superior mediastinal mass (Present)",
        "Superior mediastinal mass (Uncertain)",
        "Tortuous Aorta (Absent)",
        "Tortuous Aorta (Present)",
        "Tortuous Aorta (Uncertain)",
        "Calcification of the Aorta (Absent)",
        "Calcification of the Aorta (Present)",
        "Calcification of the Aorta (Uncertain)",
        "Enlarged pulmonary artery (Absent)",
        "Enlarged pulmonary artery (Present)",
        "Enlarged pulmonary artery (Uncertain)",
        "Hernia (Absent)",
        "Hernia (Present)",
        "Hernia (Uncertain)",
        "Pneumomediastinum (Absent)",
        "Pneumomediastinum (Present)",
        "Pneumomediastinum (Uncertain)",
        "Tracheal deviation (Absent)",
        "Tracheal deviation (Present)",
        "Tracheal deviation (Uncertain)",
        "Acute humerus fracture (Absent)",
        "Acute humerus fracture (Present)",
        "Acute humerus fracture (Uncertain)",
        "Acute rib fracture (Absent)",
        "Acute rib fracture (Present)",
        "Acute rib fracture (Uncertain)",
        "Acute clavicle fracture (Absent)",
        "Acute clavicle fracture (Present)",
        "Acute clavicle fracture (Uncertain)",
        "Acute scapula fracture (Absent)",
        "Acute scapula fracture (Present)",
        "Acute scapula fracture (Uncertain)",
        "Compression fracture (Absent)",
        "Compression fracture (Present)",
        "Compression fracture (Uncertain)",
        "Shoulder dislocation (Absent)",
        "Shoulder dislocation (Present)",
        "Shoulder dislocation (Uncertain)",
        "Subcutaneous Emphysema (Absent)",
        "Subcutaneous Emphysema (Present)",
        "Subcutaneous Emphysema (Uncertain)",
        "Suboptimal central line (Absent)",
        "Suboptimal central line (Present)",
        "Suboptimal central line (Uncertain)",
        "Suboptimal endotracheal tube (Absent)",
        "Suboptimal endotracheal tube (Present)",
        "Suboptimal endotracheal tube (Uncertain)",
        "Suboptimal nasogastric tube (Absent)",
        "Suboptimal nasogastric tube (Present)",
        "Suboptimal nasogastric tube (Uncertain)",
        "Suboptimal pulmonary arterial catheter (Absent)",
        "Suboptimal pulmonary arterial catheter (Present)",
        "Suboptimal pulmonary arterial catheter (Uncertain)",
        "Pleural tube (Absent)",
        "Pleural tube (Present)",
        "Pleural tube (Uncertain)",
        "PICC line (Absent)",
        "PICC line (Present)",
        "PICC line (Uncertain)",
        "Port catheter (Absent)",
        "Port catheter (Present)",
        "Port catheter (Uncertain)",
        "Pacemaker (Absent)",
        "Pacemaker (Present)",
        "Pacemaker (Uncertain)",
        "Implantable defibrillator (Absent)",
        "Implantable defibrillator (Present)",
        "Implantable defibrillator (Uncertain)",
        "LVAD (Absent)",
        "LVAD (Present)",
        "LVAD (Uncertain)",
        "Intraaortic balloon pump (Absent)",
        "Intraaortic balloon pump (Present)",
        "Intraaortic balloon pump (Uncertain)",
        "Pneumoperitoneum (Absent)",
        "Pneumoperitoneum (Present)",
        "Pneumoperitoneum (Uncertain)"
    ];

    return (
        <>
            <Modal
                title="Select a Suboptimal Option"
                visible={upperModalInfo.visible}
                onCancel={handleCancelUpper}
                footer={null}
            >
                <Select
                    style={{width: '100%'}}
                    onChange={handleModalSelectUpper}
                    placeholder="Select an option"
                    showSearch
                >
                    {diseasesUpper.map(disease => (
                        <Select.Option key={disease} value={disease}>
                            {disease}
                        </Select.Option>
                    ))}
                </Select>
            </Modal>

            <Modal
                title="Edit Diseases"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width="80%"
            >
                <Collapse>
                    <Panel header="Disease tree" key="1">
                        <ul>
                            <li><b>1. No Finding</b></li>
                            <li>2. Lung Finding
                                <ul>
                                    <li>2.1. Lung Opacity
                                        <ul>
                                            <li><span>2.1.1. Air space opacity</span>
                                                <ul>
                                                    <li><span style={{color: 'green'}}>2.1.1.1. Diffuse air space opacity</span>
                                                        <ul>
                                                            <li><b>2.1.1.1.1. Edema</b></li>
                                                        </ul>
                                                    </li>
                                                    <li>2.1.1.2. Focal air space opacity
                                                        <ul>
                                                            <li><span
                                                                style={{color: 'green'}}>2.1.1.2.1. Consolidation</span>
                                                                <ul>
                                                                    <li><b>2.1.1.2.1.1. Pneumonia</b></li>
                                                                    <li><b>2.1.1.2.1.2. Atelectasis</b></li>
                                                                    <li><b>2.1.1.2.1.3. Aspiration</b></li>
                                                                </ul>
                                                            </li>
                                                            <li><span style={{color: 'green'}}>2.1.1.2.2. Segmental collapse</span>
                                                                <ul>
                                                                    <li><b>2.1.1.2.2.1. Lung collapse</b></li>
                                                                </ul>
                                                            </li>
                                                            <li><b>2.1.1.2.3. Perihilar airspace opacity</b></li>
                                                        </ul>
                                                    </li>
                                                    <li><b>2.1.1.3. Air space opacity–multifocal</b></li>
                                                </ul>
                                            </li>
                                            <li>2.1.2. Masslike opacity
                                                <ul>
                                                    <li><span style={{color: 'green'}}>2.1.2.1. Solitary masslike opacity</span>
                                                        <ul>
                                                            <li><b>2.1.2.1.1. Mass/Solitary lung mass</b></li>
                                                            <li><b>2.1.2.1.2. Nodule/Solitary lung nodule</b></li>
                                                            <li><b>2.1.2.1.3. Cavitating mass with content</b></li>
                                                        </ul>
                                                    </li>
                                                    <li><span style={{color: 'green'}}>2.1.2.2. Multiple masslike opacities</span>
                                                        <ul>
                                                            <li><b>2.1.2.2.1. Cavitating masses</b></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li><b>2.2. Emphysema</b></li>
                                    <li><b>2.3. Fibrosis</b></li>
                                    <li><b>2.4. Pulmonary congestion</b></li>
                                    <li><b>2.5. Hilar lymphadenopathy</b></li>
                                    <li><b>2.6. Bronchiectasis</b></li>
                                </ul>
                            </li>
                            <li>3. Pleural finding
                                <ul>
                                    <li><span style={{color: 'green'}}>3.1. Pneumothorax</span>
                                        <ul>
                                            <li><b>3.1.1. Simple pneumothorax</b></li>
                                            <li><b>3.1.2. Loculated pneumothorax</b></li>
                                            <li><b>3.1.3. Tension pneumothorax</b></li>
                                        </ul>
                                    </li>
                                    <li>3.2. Pleural Thickening
                                        <ul>
                                            <li><span style={{color: 'green'}}>3.2.1. Pleural Effusion</span>
                                                <ul>
                                                    <li><b>3.2.1.1. Simple pleural effusion</b></li>
                                                    <li><b>3.2.1.2. Loculated pleural effusion</b></li>
                                                </ul>
                                            </li>
                                            <li><b>3.2.2. Pleural scarring</b></li>
                                        </ul>
                                    </li>
                                    <li><b>3.3. Hydropneumothorax</b></li>
                                    <li><b>3.4. Pleural Other</b></li>
                                </ul>
                            </li>
                            <li><span style={{color: 'green'}}>4. Widened cardiac silhouette</span>
                                <ul>
                                    <li><b>4.1. Cardiomegaly</b></li>
                                    <li><b>4.2. Pericardial effusion</b></li>
                                </ul>
                            </li>
                            <li>5. Mediastinal finding
                                <ul>
                                    <li><span style={{color: 'green'}}>5.1. Mediastinal mass</span>
                                        <ul>
                                            <li><b>5.1.1. Inferior mediastinal mass</b></li>
                                            <li><b>5.1.2. Superior mediastinal mass</b></li>
                                        </ul>
                                    </li>
                                    <li>5.2. Vascular finding
                                        <ul>
                                            <li><span style={{color: 'green'}}>5.2.1. Widened aortic contour</span>
                                                <ul>
                                                    <li><b>5.2.1.1. Tortuous Aorta</b></li>
                                                </ul>
                                            </li>
                                            <li><b>5.2.2. Calcification of the Aorta</b></li>
                                            <li><b>5.2.3. Enlarged pulmonary artery</b></li>
                                        </ul>
                                    </li>
                                    <li><b>5.3. Hernia</b></li>
                                    <li><b>5.4. Pneumomediastinum</b></li>
                                    <li><b>5.5. Tracheal deviation</b></li>
                                </ul>
                            </li>
                            <li>6. Musculoskeletal finding
                                <ul>
                                    <li><span style={{color: 'green'}}>6.1. Fracture</span>
                                        <ul>
                                            <li><b>6.1.1. Acute humerus fracture</b></li>
                                            <li><b>6.1.2. Acute rib fracture</b></li>
                                            <li><b>6.1.3. Acute clavicle fracture</b></li>
                                            <li><b>6.1.4. Acute scapula fracture</b></li>
                                            <li><b>6.1.5. Compression fracture</b></li>
                                        </ul>
                                    </li>
                                    <li><b>6.2. Shoulder dislocation</b></li>
                                    <li><span style={{color: 'green'}}>6.3. Chest wall finding</span>
                                        <ul>
                                            <li><b>6.3.1. Subcutaneous Emphysema</b></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li><span style={{color: 'green'}}>7. Support Devices</span>
                                <ul>
                                    <li><b>7.1. Suboptimal central line</b></li>
                                    <li><b>7.2. Suboptimal endotracheal tube</b></li>
                                    <li><b>7.3. Suboptimal nasogastric tube</b></li>
                                    <li><b>7.4. Suboptimal pulmonary arterial catheter</b></li>
                                    <li><b>7.5. Pleural tube</b></li>
                                    <li><b>7.6. PICC line</b></li>
                                    <li><b>7.7. Port catheter</b></li>
                                    <li><b>7.8. Pacemaker</b></li>
                                    <li><b>7.9. Implantable defibrillator</b></li>
                                    <li><b>7.10. LVAD</b></li>
                                    <li><b>7.11. Intraaortic balloon pump</b></li>
                                </ul>
                            </li>
                            <li>8. Upper abdominal finding
                                <ul>
                                    <li><span style={{color: 'green'}}>8.1. Subdiaphragmatic gas</span>
                                        <ul>
                                            <li><b>8.1.1. Pneumoperitoneum</b></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </Panel>
                </Collapse>

                <Collapse>
                    <Panel header="Your edited Report" key="1">
                        <div style={{whiteSpace: 'pre-wrap'}}>

                            {textAreaContent}
                        </div>
                    </Panel>
                </Collapse>
                <List
                    dataSource={Object.keys(utteranceDict)}
                    renderItem={sentence => (
                        <List.Item>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <h3>{sentence}</h3>
                                <div>
                                    {utteranceDict[sentence].map(disease => (
                                        <Tag
                                            closable
                                            onClose={() => handleRemoveDisease(sentence, disease)}
                                            key={disease}
                                            style={{marginBottom: 8}}
                                        >
                                            {disease}
                                        </Tag>
                                    ))}
                                </div>
                                <Select
                                    style={{width: 200}}
                                    placeholder="Add disease"
                                    value={selectedDiseases[sentence] || null} // Controlled component
                                    onChange={(value) => handleAddDisease(sentence, value)}
                                    dropdownMatchSelectWidth={false}
                                    showSearch // Enables the search functionality
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {diseases.map(disease => (
                                        <Select.Option key={disease} value={disease}>
                                            {disease}
                                        </Select.Option>
                                    ))}
                                </Select>

                            </div>
                        </List.Item>
                    )}
                />
            </Modal>
        </>
    );
};

export default DiseaseEditor;
