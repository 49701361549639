import React, {createContext, useEffect, useState} from 'react';
import {Alert, Button, Form, Input, Modal, Typography} from "antd";
import downloadApi from "services/downloadResources";
import {GoogleOAuthProvider, useGoogleLogin} from '@react-oauth/google';

const {Title, Paragraph, Link} = Typography;

export const ModalPasswordRessourceContext = createContext();

export const ModalPasswordRessourceProvider = ({children}) => {
    return (
        <GoogleOAuthProvider clientId="161035530394-afr09dv9hi4m2as1pqcrftn9b9h0tqqp.apps.googleusercontent.com">
            <InnerProvider>
                {children}
            </InnerProvider>
        </GoogleOAuthProvider>
    )
}


const InnerProvider = ({children}) => {
    const [isLoginModalOpen, setLoginIsModalOpen] = useState(false);
    const [resourceName, setResourceName] = useState(null);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [source, setSource] = useState(null);
    const [form] = Form.useForm();
    // GOOGLE
    const [googleLoginSuccess, setGoogleLoginSuccess] = useState(null);
    const [googleSubmittedValues, setGoogleSubmittedValues] = useState(null);


    const signIn = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            console.log(tokenResponse);
            if ("hd" in tokenResponse && tokenResponse["hd"] === "stanford.edu")
                setGoogleLoginSuccess(true);
            else
                setError("Please login with your stanford address");
        },
        onError: (errorResponse) => {
            setError("Google login failed");
        }
    });

    useEffect(() => {
        const googleFetchData = async () => {
            if (googleLoginSuccess && googleSubmittedValues) {
                try {
                    const result = await downloadApi.downloadBucketWithPassword(resourceName, googleSubmittedValues["password"]);
                    handleDownloadResponse(result);
                } catch (error) {
                    setError(error.message);
                }
            }
        };

        googleFetchData();
    }, [googleLoginSuccess, googleSubmittedValues]);

    const onClickSubmit = async (values) => {
        setError(null);
        try {
            if (source === "stanford") {
                if (!("password" in values)) {
                    throw new Error("The form doesn't work for some reason. Sorry");
                }
                setGoogleSubmittedValues(values);
                signIn();
            } else if (source === "vilmedic") {
                if (!("password" in values)) {
                    throw new Error("The form doesn't work for some reason. Sorry");
                }
                const ret = await downloadApi.downloadBucketWithPassword(resourceName, values["password"]);
                handleDownloadResponse(ret);
            } else if (source === "physionet") {
                if (!("password" in values && "username" in values)) {
                    throw new Error("The form doesn't work for some reason. Sorry");
                }
                const ret = await downloadApi.downloadPhysionet(values["username"], values["password"], resourceName);
                handleDownloadResponse(ret);
            } else {
                throw new Error("Critical error: invalid source");
            }
        } catch (error) {
            setError(error.message);
        }
    };

    const handleDownloadResponse = (ret) => {
        if ("error" in ret) {
            throw new Error(ret["error"]);
        }

        let a = document.createElement('a');
        a.href = ret["file_url"];
        a.download = resourceName || 'download';
        a.click();
        setSuccess("Download has started")

    };

    const onClickSubmitFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleLoginCancel = () => {
        setLoginIsModalOpen(false);
        setError(null);
        setSuccess(null)
        setResourceName(null);
        setSource(null)
        setGoogleSubmittedValues(null)
        setGoogleLoginSuccess(null)
        form.resetFields();
    };

    const modal_password_ressource = (dataset, source) => {
        setError(null);
        setSuccess(null)
        setSource(source)
        setResourceName(dataset)
        setLoginIsModalOpen(true);
    };


    async function directDownloadDataset(resource_name) {
        const ret = await downloadApi.downloadBucket(resource_name)

        if ("error" in ret) {
            setError(ret["error"])
            return
        }

        let a = document.createElement('a');
        a.href = ret["file_url"];
        a.download = resource_name || 'download';
        a.click();
        setSuccess("Download has started")
    }

    return (
        <GoogleOAuthProvider clientId="161035530394-afr09dv9hi4m2as1pqcrftn9b9h0tqqp.apps.googleusercontent.com">
            <ModalPasswordRessourceContext.Provider
                value={{modal_password_ressource, directDownloadDataset}}>
                {children}
                <Modal title={"Password protected resource"} open={isLoginModalOpen} footer={null}
                       onCancel={handleLoginCancel}>
                    <Form
                        name="basic"
                        form={form}
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        onFinish={onClickSubmit}
                        onFinishFailed={onClickSubmitFailed}
                        autoComplete="off"
                    >
                        <Paragraph>
                            <Alert
                                message="Informational Notes"
                                description={
                                    source === "vilmedic"
                                        ? "This resource is password protected. If you do not have this password, send me a request at jbdel [at] stanford dot edu"
                                        : source === "stanford"
                                            ? "This resource is password protected and you must login through Google with your stanford address"
                                            : source === "physionet"
                                                ? "You can download this resource by entering your physionet credential in the form below, " +
                                                "provided your account has access to this physionet resource. " +
                                                "If you do not wish to proceed that way, you can email me at jbdel [at] stanford dot edu"
                                                : ""
                                }
                                type="info"
                                showIcon
                            />
                        </Paragraph>

                        {source === "physionet" &&
                            <Form.Item
                                label="Username"
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your username!',
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        }

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                        >
                            <Input.Password/>
                        </Form.Item>


                        <Form.Item
                            wrapperCol={{
                                offset: 8,
                                span: 16,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                    {error &&
                        <Paragraph>
                            <Alert
                                message="Error"
                                description={error}
                                type="error"
                                showIcon
                            />
                        </Paragraph>
                    }
                    {success &&
                        <Paragraph>
                            <Alert
                                message="Success"
                                description={success}
                                type="success"
                                showIcon
                            />
                        </Paragraph>
                    }
                </Modal>
            </ModalPasswordRessourceContext.Provider>
        </GoogleOAuthProvider>
    );
};
