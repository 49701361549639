import React, {useContext, useState} from 'react'
import {Alert, Space, Typography, Table, message, Modal, Spin, Card, Timeline, Steps, Button} from 'antd';
import downloadApi from "services/downloadResources"
import LoginPassword from "components/Utils/LoginPassword";
import "./style/style.css"
import SyntaxHighlighter from "react-syntax-highlighter";
import GlobalContext from "contexts/GlobalContext";
import handleDownload from 'components/Utils/fileDownloader'; // Adjust the import path as needed

const {Meta} = Card;
const {Title, Paragraph, Link} = Typography;
const {Step} = Steps;

const mimiciiiLineCount = `$ wc -l *
    6525 test.findings.tok
    6525 test.impression.tok
   59319 train.findings.tok
   59319 train.impression.tok
    7412 validate.findings.tok
    7412 validate.impression.tok
  159572 total
`

const pipinstallradgraph = `pip install radgraph`
const pipinstallf1chexbert = `pip install f1chexbert`

const mimiccxrLineCount = `$ wc -l *
    1624 test.findings.tok
    1624 test.image.tok
    1624 test.impression.tok
  125417 train.findings.tok
  125417 train.image.tok
  125417 train.impression.tok
     991 validate.findings.tok
     991 validate.image.tok
     991 validate.impression.tok
  384096 total
`
const coderadgraph = 'from radgraph import F1RadGraph\n' +
    'refs = ["no acute cardiopulmonary abnormality",\n' +
    '        "no evidence of acute pulmonary process moderately large size hiatal hernia"]\n' +
    '\n' +
    'hyps = ["no acute cardiopulmonary abnormality",\n' +
    '        "no evidence of acute cardiopulmonary process moderate hiatal hernia"]\n' +
    '\n' +
    'f1radgraph = F1RadGraph(reward_level="partial")\n' +
    'score, _, hypothesis_annotation_lists, reference_annotation_lists = f1radgraph(hyps=hyps,\n' +
    '                                                                               refs=refs)\n' +
    'print(score)\n' +
    '#0.7142857142857143'

const codechexbert = 'from f1chexbert import F1CheXbert\n' +
    'f1chexbert = F1CheXbert(device="cuda")\n' +
    'accuracy, accuracy_not_averaged, class_report, class_report_5 = f1chexbert(\n' +
    '    hyps=[\'Mild pleural effusions.\', \'Enlarged heart is seen.\'],\n' +
    '    refs=[\'No pleural effusions.\', \'Enlarged heart.\'])\n' +
    'print(class_report_5["micro avg"]["f1-score"])\n' +
    '#0.6666666666666666'
const SharedTask = () => {
    const {codeStyle} = useContext(GlobalContext);

    // Login
    const [isLoginModalOpen, setLoginIsModalOpen] = useState(false);
    const [currentDataset, setCurrentDataset] = useState("");
    const [loginEpilogue, setLoginEpilogue] = useState("");

    // Config
    const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);
    const [configSteps, setConfigSteps] = useState([]);
    const [inConfigCurrent, setInConfigCurrent] = useState(0);

    const configNext = () => {
        setInConfigCurrent(inConfigCurrent + 1);
    };

    const configPrev = () => {
        setInConfigCurrent(inConfigCurrent - 1);
    };

    const handleConfigOk = () => {
        setConfigSteps([])
        setInConfigCurrent(0)
        setIsConfigModalOpen(false);
    };

    const handleConfigCancel = () => {
        setConfigSteps([])
        setInConfigCurrent(0)
        setIsConfigModalOpen(false);
    };


    const showConfig = (record) => {
        console.log(record)
        let key = record.key
        let train = ""
        let test = ""
        if (key === "iiibert") {
            train = 'python bin/train.py config/RRS/baseline-transformer-mimic.yml \\\n' +
                '    trainor.batch_size=16 \\\n' +
                '    trainor.grad_accu=8 \\\n' +
                '    trainor.eval_start=2 \\\n' +
                '    trainor.lr_decay_params.patience=0 \\\n' +
                '    trainor.lr_decay_params.factor=0.8 \\\n' +
                '    trainor.optim_params.lr=0.0001 \\\n' +
                '    trainor.early_stop_metric=ROUGEL \\\n' +
                '    dataset.src.root=rrs-mimiciii/all/ \\\n' +
                '    dataset.src.file=findings.tok \\\n' +
                '    dataset.tgt.root=rrs-mimiciii/all/ \\\n' +
                '    dataset.tgt.file=impression.tok \\\n' +
                '    model.encoder.num_hidden_layers=8 \\\n' +
                '    model.decoder.num_hidden_layers=8 \\\n' +
                '    validator.batch_size=4 \\\n' +
                '    validator.metrics=[ROUGEL,ROUGE1,ROUGE2,radgraph,bertscore] \\\n' +
                '    validator.beam_width=4 \\\n' +
                '    name=rrs_mimiciii_bionlp_8 \\\n' +
                '    ckpt_dir=ckpt'

            test = 'python bin/ensemble.py config/RRS/baseline-transformer-mimic.yml \\\n' +
                '    dataset.src.root=rrs-mimiciii/all/ \\\n' +
                '    dataset.src.file=findings.tok \\\n' +
                '    dataset.tgt.root=rrs-mimiciii/all/ \\\n' +
                '    dataset.tgt.file=impression.tok \\\n' +
                '    model.encoder.num_hidden_layers=8 \\\n' +
                '    model.decoder.num_hidden_layers=8 \\\n' +
                '    ensemblor.batch_size=4 \\\n' +
                '    ensemblor.metrics=[ROUGEL,ROUGE1,ROUGE2,radgraph,bertscore] \\\n' +
                '    ensemblor.beam_width=4 \\\n' +
                '    name=rrs_mimiciii_bionlp_8 \\\n' +
                '    ckpt_dir=ckpt'
        }
        if (key === "iiibiomed") {
            train = 'python bin/train.py config/RRS/biomed-roberta-baseline-mimic.yml \\\n' +
                '    trainor.batch_size=16 \\\n' +
                '    trainor.grad_accu=8 \\\n' +
                '    trainor.early_stop_metric=ROUGEL \\\n' +
                '    dataset.src.root=rrs-mimiciii/all/ \\\n' +
                '    dataset.src.file=findings.tok \\\n' +
                '    dataset.tgt.root=rrs-mimiciii/all/ \\\n' +
                '    dataset.tgt.file=impression.tok \\\n' +
                '    validator.batch_size=4 \\\n' +
                '    validator.metrics=[ROUGEL,ROUGE1,ROUGE2,radgraph,bertscore] \\\n' +
                '    validator.beam_width=4 \\\n' +
                '    name=rrs_mimiciii_bionlp_roberta \\\n' +
                '    ckpt_dir=ckpt'

            test = 'python bin/ensemble.py config/RRS/biomed-roberta-baseline-mimic.yml \\\n' +
                '    dataset.src.root=rrs-mimiciii/all/ \\\n' +
                '    dataset.src.file=findings.tok \\\n' +
                '    dataset.tgt.root=rrs-mimiciii/all/ \\\n' +
                '    dataset.tgt.file=impression.tok \\\n' +
                '    ensemblor.batch_size=4 \\\n' +
                '    ensemblor.metrics=[ROUGEL,ROUGE1,ROUGE2,radgraph,bertscore] \\\n' +
                '    ensemblor.beam_width=4 \\\n' +
                '    name=rrs_mimiciii_bionlp_roberta \\\n' +
                '    ckpt_dir=ckpt'
        }
        if (key === "cxrbert") {
            train = "python bin/train.py config/RRS/baseline-transformer-mimic.yml \\\n" +
                "    trainor.batch_size=16 \\\n" +
                "    trainor.grad_accu=8 \\\n" +
                "    trainor.eval_start=2 \\\n" +
                "    trainor.lr_decay_params.patience=1 \\\n" +
                "    trainor.lr_decay_params.factor=0.8 \\\n" +
                "    trainor.optim_params.lr=0.0001 \\\n" +
                "    trainor.early_stop_metric='chexbert-5_micro avg_f1-score' \\\n" +
                "    dataset.src.root=mimic-cxr/ \\\n" +
                "    dataset.src.file=findings.tok \\\n" +
                "    dataset.tgt.root=mimic-cxr/ \\\n" +
                "    dataset.tgt.file=impression.tok \\\n" +
                "    model.encoder.num_hidden_layers=4 \\\n" +
                "    model.decoder.num_hidden_layers=4 \\\n" +
                "    validator.batch_size=4 \\\n" +
                "    validator.metrics=[ROUGEL,ROUGE1,ROUGE2,chexbert,radgraph,bertscore] \\\n" +
                "    validator.beam_width=4 \\\n" +
                "    name=rrs_mimicxr_bionlp_4 \\\n" +
                "    ckpt_dir=ckpt"

            test = "python bin/ensemble.py config/RRS/baseline-transformer-mimic.yml \\\n" +
                "    dataset.src.root=mimic-cxr/ \\\n" +
                "    dataset.src.file=findings.tok \\\n" +
                "    dataset.tgt.root=mimic-cxr/ \\\n" +
                "    dataset.tgt.file=impression.tok \\\n" +
                "    model.encoder.num_hidden_layers=4 \\\n" +
                "    model.decoder.num_hidden_layers=4 \\\n" +
                "    ensemblor.batch_size=4 \\\n" +
                "    ensemblor.metrics=[ROUGEL,ROUGE1,ROUGE2,chexbert,radgraph,bertscore] \\\n" +
                "    ensemblor.beam_width=4 \\\n" +
                "    name=rrs_mimicxr_bionlp_4 \\\n" +
                "    ckpt_dir=ckpt"
        }
        if (key === "cxrbiomed") {
            train = "python bin/train.py config/RRS/biomed-roberta-baseline-mimic.yml \\\n" +
                "    trainor.batch_size=16 \\\n" +
                "    trainor.grad_accu=8 \\\n" +
                "    trainor.early_stop_metric='chexbert-5_micro avg_f1-score' \\\n" +
                "    dataset.src.root=mimic-cxr/ \\\n" +
                "    dataset.src.file=findings.tok \\\n" +
                "    dataset.tgt.root=mimic-cxr/ \\\n" +
                "    dataset.tgt.file=impression.tok \\\n" +
                "    validator.batch_size=4 \\\n" +
                "    validator.metrics=[ROUGEL,ROUGE1,ROUGE2,chexbert,radgraph,bertscore] \\\n" +
                "    validator.beam_width=4 \\\n" +
                "    name=rrs_mimicxr_bionlp_biomed \\\n" +
                "    ckpt_dir=ckpt"

            test = "python bin/ensemble.py config/RRS/biomed-roberta-baseline-mimic.yml \\\n" +
                "    dataset.src.root=mimic-cxr/ \\\n" +
                "    dataset.src.file=findings.tok \\\n" +
                "    dataset.tgt.root=mimic-cxr/ \\\n" +
                "    dataset.tgt.file=impression.tok \\\n" +
                "    ensemblor.batch_size=4 \\\n" +
                "    ensemblor.metrics=[ROUGEL,ROUGE1,ROUGE2,chexbert,radgraph,bertscore] \\\n" +
                "    ensemblor.beam_width=4 \\\n" +
                "    name=rrs_mimicxr_bionlp_biomed \\\n" +
                "    ckpt_dir=ckpt\n"
        }


        setConfigSteps(
            [<Paragraph>
                <pre>{train}</pre>
            </Paragraph>, <Paragraph>
                <pre>{test}</pre>
            </Paragraph>],
        )
        setIsConfigModalOpen(true);
    };


    const onLoginFinish = async (values) => {
        setLoginEpilogue(<Spin/>)
        const ret = await downloadApi.downloadPhysionet(values["username"], values["password"], currentDataset)
        if ("error" in ret) {
            message.error(ret["error"]);
            setLoginEpilogue("")
        } else {
            setLoginEpilogue(<Link href={ret["file_url"]}>{ret["file_url"]}</Link>)
        }
    };
    const onLoginFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const showLoginModal = (dataset) => {
        setLoginIsModalOpen(true);
        setCurrentDataset(dataset)
    };

    const handleLoginCancel = () => {
        setLoginIsModalOpen(false);
        setLoginEpilogue("")
    };

    const dataSourceBaselineMimicCXR = [
        {
            key: 'cxrbert',
            model: 'bert-base (text-only)',
            split: 'validate',
            rougel: 55.09,
            chexbert: 71.94,
            bertscore: 68.66,
            radgraph: 50.24,
        },
        {
            key: 'cxrbiomed',
            model: 'allenai/biomed_roberta_base (text-only)',
            split: 'validate',
            rougel: 56.14,
            chexbert: 74.73,
            bertscore: 69.63,
            radgraph: 51.08,
        },
    ];

    const dataSourceBaselineMimicIII = [
        {
            key: 'iiibert',
            model: 'bert-base',
            split: 'validate',
            rougel: 30.27,
            chexbert: "N/A",
            bertscore: '51.80',
            radgraph: 26.70,
        },
        {
            key: 'iiibiomed',
            model: 'allenai/biomed_roberta_base',
            split: 'validate',
            rougel: '31.90',
            chexbert: "N/A",
            bertscore: '53.67',
            radgraph: 28.49,
        },
    ];

    const columnsBaseline = [
        {
            title: 'Model',
            dataIndex: 'model',
            key: 'model',
        },
        {
            title: 'Split',
            dataIndex: 'split',
            key: 'split',
        },
        {
            title: 'ROUGEL',
            dataIndex: 'rougel',
            key: 'rougel',
        },
        {
            title: 'F1CheXbert',
            dataIndex: 'chexbert',
            key: 'chexbert',
        },
        {
            title: 'bertscore',
            dataIndex: 'bertscore',
            key: 'bertscore',
        },
        {
            title: 'F1RadGraph',
            dataIndex: 'radgraph',
            key: 'address',
        },
        {
            title: '',
            key: 'config',
            render: (text, record, index) => <a onClick={() => showConfig(record)}>Config</a>,
        }
    ];

    const dataSourceMimicIIIdetailed = [{
        key: '1', name: 'CT Head', num: 31403, tr: "25122", val: "3140", te: "3141",
    }, {
        key: '4', name: 'CT Abdomen', num: 15990, tr: "12792", val: "1599", te: "1599",
    }, {
        key: '3', name: 'CT Chest', num: 12787, tr: "10229", val: "1278", te: "1280",
    }, {
        key: '5', name: 'MR Head', num: 7314, tr: "5851", val: "731", te: "732",
    }, {
        key: '2', name: 'CT Spine', num: 5518, tr: "4414", val: "551", te: "553",
    }, {
        key: '11', name: 'CT Neck', num: 1141, tr: 912, val: 114, te: 115,
    }, {
        key: '10', name: 'MR Spine', num: 2822, tr: "", val: "", te: "2822",
    }, {
        key: '6', name: 'CT Sinus', num: 1268, tr: "", val: "", te: "1268",
    }, {
        key: '9', name: 'MR Abdomen', num: 1062, tr: "", val: "", te: "1062",
    }, {
        key: '7', name: 'MR Pelvis', num: 254, tr: "", val: "", te: "254",
    }, {
        key: '8', name: 'MR Neck', num: 231, tr: "", val: "", te: "231",
    },
    ];

    const columnsMimicIIIdetailed = [{
        title: 'Modality/Anatomy', dataIndex: 'name', key: 'name',
    }, {
        title: 'Number of reports', dataIndex: 'num', key: 'num',
    }, {
        title: 'Train', dataIndex: 'tr', key: 'tr',
    }, {
        title: 'Validation', dataIndex: 'val', key: 'val',
    }, {
        title: 'Test', dataIndex: 'te', key: 'te',
    },];

    const columnsMimicIII = [{
        title: 'Dataset', dataIndex: 'name', key: 'name',
    }, {
        title: 'Number of reports', dataIndex: 'num', key: 'num',
    }, {
        title: 'Train', dataIndex: 'tr', key: 'tr',
    }, {
        title: 'Validation', dataIndex: 'val', key: 'val',
    }, {
        title: 'Test', dataIndex: 'te', key: 'te',
    },];
    const dataSourceMimicIII = [{
        key: '1.1', name: 'MIMIC-III', num: 73259, tr: "59320", val: "7413", te: "6526",
    }, {
        key: '2.1', name: 'MIMIC-III (hidden)', num: 6531, tr: "", val: "", te: "6531",
    },
    ];


    const dataSourceMimicCXR = [
        {
            key: 'mimic1',
            name: 'MIMIC Chest X-rays',
            num_im: 237564,
            num: 128032,
            tr: "125417",
            val: "991",
            te: "1624",
        },
        {
            key: 'chexpert',
            name: 'CheXpert Chest X-rays (hidden)',
            num_im: 1000,
            num: 1000,
            tr: "",
            val: "",
            te: "1000",
        }
    ];

    const columnsMimicCXR = [{
        title: 'Modality/Anatomy', dataIndex: 'name', key: 'name',
    }, {
        title: 'Number of images', dataIndex: 'num_im', key: 'num',
    }, {
        title: 'Number of reports', dataIndex: 'num', key: 'num',
    }, {
        title: 'Train', dataIndex: 'tr', key: 'tr',
    }, {
        title: 'Validation', dataIndex: 'val', key: 'val',
    }, {
        title: 'Test', dataIndex: 'te', key: 'te',
    },];


    async function directDownload(resource_name) {
        const ret = await downloadApi.downloadBucket(resource_name)
        let a = document.createElement('a');
        a.href = ret["file_url"];
        a.download = resource_name || 'download';
        a.click();
    }


    return (<>
        <div style={{width: 900}}>

            <Space direction={"vertical"} size={15}>
                <Alert
                    message="Informational Notes"
                    description={
                        <>
                            This challenge is now over. <br/>
                            The leaderboard remains accessible <Link href={"/misc/bionlp23/leaderboard/"}>at
                            this following address.</Link><br/>
                            <b>To download the dataset of this challenge, see you at <Link href={"/papers/acl2023/"}>at
                            the following address.</Link></b>
                        </>}
                    type="info"
                    showIcon
                />

                {/*<Alert message={<>Google group: <Link*/}
                {/*    href={"https://groups.google.com/g/bionlp23-radiology-summ"}>https://groups.google.com/g/bionlp23-radiology-summ</Link>.</>}*/}
                {/*       type="info" showIcon/>*/}
                <Title level={2}>Task 1B: Radiology Report Summarization</Title>
                <Paragraph>
                    Radiology report summarization is a growing area of research. Given the Findings and/or
                    Background sections of a radiology report, the goal is to generate a summary (called an
                    Impression section) that highlights the key observations and conclusions of the radiology study.
                </Paragraph>


                <Title level={3}>1. Challenge description</Title>

                The research area of radiology report summarization currently faces an
                important limitation: most research is carried out on chest X-rays. To palliate these limitations,
                we propose two datasets:
                <Paragraph>
                    <ul>
                        <li>
                            A shared summarization task that includes six different modalities and anatomies,
                            totalling 79,779 samples, based on the MIMIC-III database.
                        </li>
                        <li>
                            A shared summarization task on chest x-ray radiology reports with images and a brand
                            new out-of-domain test-set from Stanford.
                        </li>
                    </ul>
                    The emphasis of the workshop is on 1) the use multimodal input (for mimic-cxr) 2) the evaluation of
                    the submissions on the factual correctness of the summary using new metrics.
                </Paragraph>
                <Paragraph>
                    <Alert
                        message="Please Read"
                        description={<>All participants will be invited to write a paper describing their solution that
                            will be included in the <Link href={"https://aclanthology.org/venues/bionlp/"}>Proceedings
                                of the 22st Workshop
                                on Biomedical Language Processing</Link>. If you do not wish to write a paper, you must
                            at least provide a thorough description of your system that will be included in the
                            overview paper of the task. Otherwise, your submission (or reported scores) will not be
                            taken into account.
                        </>}
                        type="warning"
                        showIcon
                    />
                </Paragraph>
                <Title level={4}>1.1. Rules</Title>

                <Paragraph>
                    <ul>
                        <li>
                            Participants can use private data to train (or pretrain) their model, make a submission and
                            write a technical paper describing their solution.
                            Nonetheless, their score will not be counted towards the general leaderboard. To validate
                            your leaderboard ranking, all the data used for the submission must be in some way available
                            to researchers.
                        </li>
                        <li>
                            You are free to send submissions for one dataset only. Participants are not
                            required to participate to both challenges (mimic-iii and mimic-cxr), though we encourage
                            you to fully participate.
                        </li>
                        <li>
                            Participants can use mimic-cxr data to train or pretrain a mimic-iii model.
                        </li>
                    </ul>
                </Paragraph>

                <Title level={4}>1.2. Timeline</Title>

                <Timeline>
                    <Timeline.Item color="green">First call for participation: January 9th, 2023</Timeline.Item>
                    <Timeline.Item color="green">Releasing of training and validation and test data: January 10th,
                        2023</Timeline.Item>
                    <Timeline.Item color="green">Releasing of hidden test-sets: April 6th, 2023</Timeline.Item>
                    <Timeline.Item color="green">System submission deadline: April 28th, 2023</Timeline.Item>
                    <Timeline.Item color="green">System papers due date: May 2nd, 2023</Timeline.Item>
                    <Timeline.Item color="green">Notification of acceptance: June 1st, 2023</Timeline.Item>
                    <Timeline.Item color="green">Camera-ready system papers due: June 13th, 2023</Timeline.Item>
                    <Timeline.Item>BioNLP Workshop Date: July 13th or 14th, 2023</Timeline.Item>
                </Timeline>
                <Title level={3}>2. Get the data</Title>
                <Alert
                    message="Informational Notes"
                    description="The datasets used in this section are from the MIMIC-III and MIMIC-CXR database.
                        In order to download the data, you will need a PhysioNet account with signed agreements for both datasets."
                    type="info"
                    showIcon
                />
                <Paragraph>
                    Two datasets are used in this challenge: the MIMIC-III and MIMIC-CXR Radiology Report
                    Summarization datasets.
                </Paragraph>
                <Title level={4}>2.1 MIMIC-III</Title>
                <Paragraph>
                    <blockquote>Johnson, A. E. W., Pollard, T. J., Shen, L., Lehman, L. H., Feng, M., Ghassemi, M.,
                        Moody, B., Szolovits, P., Celi, L. A., & Mark, R. G. (2016). MIMIC-III, a freely accessible
                        critical care database. Scientific Data, 3, 160035.
                    </blockquote>
                </Paragraph>

                We create a new radiology report dataset based on mimic-iii. The dataset consists of the
                following splits:
                <Table dataSource={dataSourceMimicIII} columns={columnsMimicIII} size={"middle"}
                       pagination={false}/>
                <p>The detail per modality-anatomy pairs is as such:</p>
                <Table dataSource={dataSourceMimicIIIdetailed} columns={columnsMimicIIIdetailed} size={"middle"}
                       pagination={false}/>


                <Alert
                    message="Informational Notes"
                    description={<>You can instantly download the challenge mimic-iii dataset using <Link
                        onClick={() => showLoginModal("bionlp23-rrs-mimic-iii")}>the following form</Link> with your
                        PhysioNet
                        credentials. Your credentials are used to ping the mimic-iii PhysioNet
                        resource (<Link>https://physionet.org/content/mimiciii/1.4/</Link>) to ensure you have access.
                        If you do not wish to proceed that way, email me
                        at <Link>jbdel@stanford.edu</Link> to get the dataset.</>}
                    type="info"
                    showIcon
                />
                <Paragraph>
                    <b>The test sets has been split in two. One for your own use, and one for the hidden test set that
                        will be released on April 14th, 2023.</b><br/>
                    The full dataset is in the "all" folder and consists of the following files:
                    <SyntaxHighlighter language="bash" style={codeStyle}>
                        {mimiciiiLineCount}
                    </SyntaxHighlighter>
                    The other folder contain the data per modality/anatomy.
                </Paragraph>

                <Title level={4}>2.2 MIMIC-CXR and CheXpert</Title>

                <Paragraph>
                    <blockquote>Johnson, A.E.W., Pollard, T.J., Berkowitz, S.J. et al. MIMIC-CXR, a de-identified
                        publicly available database of chest radiographs with free-text reports. Sci Data 6, 317
                        (2019). https://doi.org/10.1038/s41597-019-0322-0
                    </blockquote>
                    These datasets consists of multimodal triplets (images, findings, impression). We encourage
                    participants to use images in their summarization pipeline to investigate multimodal summarization.
                    The datasets consists of the following splits:
                </Paragraph>

                <Table dataSource={dataSourceMimicCXR} columns={columnsMimicCXR} pagination={false}/>
                <Alert
                    message="Informational Notes"
                    description={<>You can instantly download the preprocessed and resized RRS mimic-cxr dataset with
                        images (9GB) using <Link onClick={() => showLoginModal("bionlp23-rrs-mimic-cxr")}>the following
                            form</Link> with your PhysioNet credentials. Your credentials are used to ping the
                        mimic-cxr-jpg PhysioNet
                        resource (<Link>https://physionet.org/content/mimic-cxr-jpg/2.0.0/</Link>) to ensure you have
                        access.
                        If you do not wish to proceed that way, you can recreate the dataset using <Link
                            href={"https://github.com/jbdel/vilmedic/tree/main/data/make_datasets/mimic_cxr"}>
                            ViLMedic</Link>.</>}
                    type="info"
                    showIcon
                />
                <Paragraph>
                    <b>The CheXpert test-set will be released as hidden test-set on April 14th, 2023.</b><br/>
                    <SyntaxHighlighter language="bash" style={codeStyle}>
                        {mimiccxrLineCount}
                    </SyntaxHighlighter>
                </Paragraph>
                <Title level={3}>3. Evaluation metrics</Title>
                <Paragraph>
                    <ul>
                        <li>
                            For MIMIC-CXR, the evaluation metrics will be BLEU, ROUGE, <Link
                            href={"https://pypi.org/project/rouge/"}>ROUGE-L</Link>, <Link
                            href={"https://aclanthology.org/2020.acl-main.458/"}>
                            F1CheXbert </Link> and <Link
                            href={"https://aclanthology.org/2022.findings-emnlp.319"}>
                            F1RadGraph</Link>.
                        </li>
                        <li>
                            For MIMIC-III, the evaluation metrics will be BLEU, ROUGE, <Link
                            href={"https://pypi.org/project/rouge/"}>ROUGE-L</Link> and <Link
                            href={"https://aclanthology.org/2022.findings-emnlp.319"}>
                            F1RadGraph</Link>.
                        </li>
                    </ul>
                    The following metric packages are provided by the <img style={{"width": 14}}
                                                                           src={"/favicon/favicon-64x64.png"}
                                                                           alt={""}/> ViLMedic library.
                    <Title level={4}>3.1 F1RadGraph</Title>
                    The F1RadGraph score used in this challenge is described as RG_ER from <Link
                    href={"https://aclanthology.org/2022.findings-emnlp.319"}> this paper</Link>.
                    <SyntaxHighlighter language="bash" style={codeStyle}>
                        {pipinstallradgraph}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter customStyle={{textAlign: "left"}} language="python" style={codeStyle}>
                        {coderadgraph}
                    </SyntaxHighlighter>
                    <Title level={4}>3.2 F1CheXbert</Title>
                    The F1CheXbert used in this challenge is over 5 observations (Cardiomegaly", "Edema",
                    "Consolidation", "Atelectasis", "Pleural Effusion), and micro averaged.
                    <SyntaxHighlighter language="bash" style={codeStyle}>
                        {pipinstallf1chexbert}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter customStyle={{textAlign: "left"}} language="python" style={codeStyle}>
                        {codechexbert}
                    </SyntaxHighlighter>


                </Paragraph>

                <Title level={3}>4. Baselines</Title>
                <Alert message={<>To replicate the baselines, you first need to install <img style={{"width": 14}}
                                                                                             src={"/favicon/favicon-64x64.png"}
                                                                                             alt={""}/> <Link
                    href={"/documentation/installation/"}> ViLMedic.</Link></>}
                       type="info" showIcon/>
                <Paragraph>
                    <Title level={5}>MIMIC-III</Title>
                    <Table dataSource={dataSourceBaselineMimicIII} columns={columnsBaseline} size={"middle"}
                           pagination={false}/>
                    <Title level={5}>MIMIC-CXR</Title>
                    <Table dataSource={dataSourceBaselineMimicCXR} columns={columnsBaseline} size={"middle"}
                           pagination={false}/>
                </Paragraph>

                <Title level={4}>Organizers</Title>
                <Alert message="If you have any question or issue, please email us at jbdel at stanford dot edu"
                       type="info" showIcon/>

                <Space direction={"horizontal"}>
                    <Link href={"https://jbdel.github.io/"}>
                        <Card
                            hoverable
                            style={{width: 240}}
                            cover={<img alt="example"
                                        src="https://aimi.stanford.edu/sites/g/files/sbiybj20451/files/styles/stanford_circle/public/media/image/image5_0.png?itok=3bYPWD8q"/>}
                        >
                            <Meta title={<>JB Delbrouck<br/>Stanford University <br/> Postdoc</>}
                                  description="https://jbdel.github.io/"/>
                        </Card>
                    </Link>
                    <Link href={"https://maya-varma.com/"}>
                        <Card
                            hoverable
                            style={{width: 240}}
                            cover={<img alt="example" src="https://maya-varma.com/maya3.png"/>}
                        >
                            <Meta title={<>Maya Varma<br/>Stanford University <br/> PhD Student</>}
                                  description="https://maya-varma.com/"/>
                        </Card>
                    </Link>
                    <Link href={"https://scholar.google.com/citations?user=5e_2WW4AAAAJ&hl=fr"}>
                        <Card
                            hoverable
                            style={{width: 240}}
                            cover={<img alt="example"
                                        src="https://aimi.stanford.edu/sites/g/files/sbiybj20451/files/styles/stanford_circle/public/media/image/pierre2_2_0.jpg.png?itok=yxth_m2A"/>}
                        >
                            <Meta title={<>Pierre Chambon<br/>Stanford University <br/> Fellow</>}
                                  description="&nbsp;"/>
                        </Card>
                    </Link>
                    <Link href={"https://profiles.stanford.edu/curtis-langlotz"}>
                        <Card
                            hoverable
                            style={{width: 240}}
                            cover={<img alt="example"
                                        src="https://aimi.stanford.edu/sites/g/files/sbiybj20451/files/styles/stanford_circle/public/media/image/curtis-langlotz_profilephoto_0.jpeg.png?itok=J4IeNNm6"/>}
                        >
                            <Meta title={<>Curtis Langlotz
                                <br/>Stanford University <br/> Professor</>}
                                  description="https://profiles.stanford.edu/curtis-langlotz"/>
                        </Card>
                    </Link>
                </Space>
                <Title level={4}>References</Title>
                <ul>
                    <li>
                        Jean-benoit Delbrouck, Khaled Saab, Maya Varma, Sabri Eyuboglu, Pierre Chambon, Jared Dunnmon,
                        Juan Zambrano, Akshay Chaudhari, and Curtis Langlotz. 2022. ViLMedic: a framework for research
                        at the intersection of vision and language in medical AI. In Proceedings of the 60th Annual
                        Meeting of the Association for Computational Linguistics: System Demonstrations, pages 23–34,
                        Dublin, Ireland. Association for Computational Linguistics.
                    </li>
                    <li>
                        Delbrouck, Jean-Benoit, et al. "Improving the factual correctness of radiology report generation
                        with semantic rewards." EMNLP 2022 findings.
                    </li>
                    <li>
                        Yuhao Zhang, Derek Merck, Emily Tsai, Christopher D. Manning, and Curtis Langlotz. 2020.
                        Optimizing the Factual Correctness of a Summary: A Study of Summarizing Radiology Reports. In
                        Proceedings of the 58th Annual Meeting of the Association for Computational Linguistics, pages
                        5108–5120, Online. Association for Computational Linguistics.
                    </li>
                </ul>
            </Space>
        </div>

        <Modal title={"Download the " + currentDataset + " dataset"} open={isLoginModalOpen} footer={null}
               onCancel={handleLoginCancel}>
            <LoginPassword onFinish={onLoginFinish}
                           onFinishFailed={onLoginFinishFailed}
                           preamble={"Enter here the credential of your physionet account."}
                           epilogue={loginEpilogue}
            />
        </Modal>


        <Modal title="Config" width={700} open={isConfigModalOpen} onOk={handleConfigOk} onCancel={handleConfigCancel}>

            <Steps current={inConfigCurrent}>
                <Step key={"1"} title={"Train & Validation"}/>
                <Step key={"2"} title={"Test"}/>
            </Steps>
            <div className="steps-content">{configSteps[inConfigCurrent]}</div>
            <div className="steps-action">
                {inConfigCurrent < configSteps.length - 1 && (
                    <Button type="primary" onClick={() => configNext()}>
                        Next
                    </Button>
                )}
                {inConfigCurrent > 0 && (
                    <Button style={{margin: '0 8px'}} onClick={() => configPrev()}>
                        Previous
                    </Button>
                )}
            </div>
        </Modal>

    </>);
};

export default SharedTask
