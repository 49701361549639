import React, {useEffect} from 'react'
import Quickstart from "./Quickstart"

const QuickstartContainer = () => {

    useEffect(() => {
    }, [])

    return (
        <Quickstart/>
    )
}

export default QuickstartContainer