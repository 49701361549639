import config from "services/config";


const callDeID = (report) => {
    return config.modelsApi.post("/deid", {
        report: report
    }).then(response => {
        return response.data
    }).catch(() => {
        return {
            error: 'Backend is offline or received critical error',
        }
    })

}


const callRRG = (image) => {
    return config.modelsApi.post("/rrg", {
        image: image
    }).then(response => {
        return response.data
    }).catch(() => {
        return {
            error: 'Backend is offline or received critical error',
        }
    })

}

const exportedObject = {
    callRRG,
    callDeID,
}

export default exportedObject