import React, {useContext, useState} from 'react'
import {
    Affix,
    Space,
    Input,
    Table,
    message,
    Modal,
    Spin,
    Alert,
    Button,
    Typography,
    Divider, List, Tag, Slider, Segmented
} from 'antd';
import ExplainReportContext from "components/Layout/Pages/Misc/explainReport/ExplainReportContext";
import RadGraphContext from "components/Layout/Pages/Misc/explainReport/RadGraphContext";
import SummarizationContext from "components/Layout/Pages/Misc/explainReport/SummarizationContext";
import ConversationContext from "./ConversationContext";
import {QuestionCircleOutlined, WechatOutlined} from "@ant-design/icons";

const {TextArea} = Input;
const {Paragraph, Link} = Typography;

const ExplainReport = () => {
    const {
        reportContent,
        setReportContent,
        commonQuestions,
        setCommonQuestions,
        REPORT_DEFAULT_PROMPT,
        SLIDER_LEVEL_DEFAULT_VALUE,
        setExplainModalContent,
        explainModalContent,
        setLevelSliderValue,
        levelSliderValue,
        getQuestionAnswerPipeline,
        getNCommonQuestions,
        setCurrentQuestion,
        setCurrentResponse,
        getAnswerNewLevelSlider,
        onStartFollowUpQuestion,
    } = useContext(ExplainReportContext);

    const {
        getProcessedRadGraphAnnotations,
        processRadGraphAnnotations,
        radGraphTableRecord,
        setRadGraphTableRecord,
        radgraphReportContent,
        setRadgraphReportContent,
    } = useContext(RadGraphContext);

    const {
        summarizedReport,
        setSummarizedReport,
        sectionsReport,
        setSectionsReport,
        sectionsContentReport,
        setSectionsContentReport,
        currentReportSection,
        setCurrentReportSection,
        getSummaryOfReport,
    } = useContext(SummarizationContext);


    const {
        conversation,
        setConversation,
    } = useContext(ConversationContext);


    const [reportSentIsLoading, setReportSentIsLoading] = useState(false);
    const [isLevelSliderDisabled, setIsLevelSliderDisabled] = useState(false);
    const [isExplainModalOpen, setIsExplainModalOpen] = useState(false);

    const reportOnChange = (e) => {
        setReportContent(e.target.value);
    };


    const sectionOnChange = (section) => {
        setCurrentReportSection(section)

    };

    const onLevelSliderAfterChange = (level) => {
        getAnswerNewLevelSlider(level)
    };

    function resetAll() {
        setReportContent(REPORT_DEFAULT_PROMPT)
        setReportSentIsLoading(false)
        setRadGraphTableRecord(null)
        setIsExplainModalOpen(false)
        setExplainModalContent([{}, {}, {}])
        setReportSentIsLoading(false)
        setCommonQuestions([])
        setCurrentQuestion("")
        setCurrentResponse("")
        setIsLevelSliderDisabled(false)
        setLevelSliderValue(SLIDER_LEVEL_DEFAULT_VALUE)
        //summ section
        setSectionsReport([])
        setSectionsContentReport({})
        setCurrentReportSection("")
        setSummarizedReport("")
        setRadgraphReportContent("")
    }

    function displayError(msg) {
        message.error(msg);
        resetAll()
    }

    const handleExplainModalCancel = () => {
        setIsExplainModalOpen(false);
        setExplainModalContent([{}, {}, {}]);
    };

    const showExplainModal = async (text, enhance = false) => {
        console.log(text)
        setIsExplainModalOpen(true);
        setIsLevelSliderDisabled(true)
        await getQuestionAnswerPipeline(text, levelSliderValue, enhance)
        setIsLevelSliderDisabled(false)
    };


    async function submitReport() {
        setReportSentIsLoading(true)

        // Compute summary
        // let reportSummary = await getSummaryOfReport(reportContent)
        let reportSummary = {"report": {"report": reportContent}}

        if (typeof reportSummary !== 'object') {
            displayError("Backend is offline");
            resetAll()
            return
        }

        if ("error" in reportSummary) {
            displayError(reportSummary["error"]);
            resetAll()
            return
        }
        reportSummary = reportSummary["report"]
        setSectionsReport(Object.keys(reportSummary))
        setSectionsContentReport(reportSummary)
        const defaultSection =
            (
                "impression" in reportSummary ? "impression" :
                    "findings" in reportSummary ? "findings" :
                        "summary" in reportSummary ? "summary" : "report"
            )
        setCurrentReportSection(defaultSection)
        let summary = reportSummary[defaultSection]
        setSummarizedReport(summary)

        // get annotations
        const rgAnnotations = await getProcessedRadGraphAnnotations(summary)

        if (typeof rgAnnotations !== 'object') {
            displayError("Backend is offline");
            resetAll()
            return
        }

        if ("error" in rgAnnotations) {
            displayError(rgAnnotations["error"]);
            resetAll()
            return
        }

        processRadGraphAnnotations(rgAnnotations, showExplainModal)
        setReportSentIsLoading(false)

        // let questions = []
        getNCommonQuestions(reportContent, 5, "radiology report").then(questions => {
            setCommonQuestions(questions)
        })
            .catch(error => {
                console.log(error)
                setCommonQuestions(null)
            });

    }

    if (reportSentIsLoading)
        return <Spin/>

    const columns = [
        {
            title: 'Observation',
            dataIndex: 'observation',
            key: 'observation',
        },
        {
            title: 'Located at',
            dataIndex: 'located_at',
            key: 'located_at',
            render: (_, {located_at}) => (
                <>
                    {located_at.join(", ")}
                </>
            ),
        },
        {
            title: 'Tags',
            key: 'tags',
            dataIndex: 'tags',
            render: (_, {tags}) => (
                <>
                    {tags.map((tag) => {
                        let color = 'orange'
                        if (tag === 'definitely present') {
                            color = 'geekblue';
                        }
                        if (tag === 'definitely absent') {
                            color = 'volcano';
                        }
                        return (
                            <Tag color={color} key={tag}>
                                {tag.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
        {
            title: 'Suggestive of',
            dataIndex: 'suggestive_of',
            key: 'suggestive_of',
            render: (_, {suggestive_of}) => {
                if (!suggestive_of) {
                    return null;
                }
                const suggs = suggestive_of.reduce((acc, sugg, i) => {
                    const link = (
                        <Link key={sugg} onClick={() => showExplainModal(sugg, true)}>
                            {sugg}
                        </Link>
                    );
                    return i === 0 ? [link] : [...acc, ", ", link];
                }, []);
                return <>{suggs}</>;
            },
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                let q = "define " + record.observation.replace("No", "").replace("Uncertain", "")
                return (
                    <Space size="middle">
                        <Link onClick={() => showExplainModal(q, true)}>{q.toLowerCase()}</Link>
                    </Space>
                )
            }

        },
    ];

    if (radGraphTableRecord) {
        return (
            <>
                <Space direction={"vertical"}>
                    <div style={{width: 900}}>
                        <Affix offsetTop={20} style={{float: "right"}}>
                            <Button type="primary" onClick={() => resetAll()} danger>
                                Reset
                            </Button>
                        </Affix>
                        <Divider orientation="left">Summary of your report</Divider>
                        <Paragraph>
                            <center>{radgraphReportContent.map((word) => <>{word} </>)}</center>
                        </Paragraph>
                        <Divider orientation="left">Observations</Divider>
                        <Table columns={columns} dataSource={radGraphTableRecord}/>
                        {/*common questions */}
                        <Divider orientation="left">Common questions</Divider>
                        {commonQuestions === null &&
                            <Alert
                                message="Warning"
                                description="OpenAI is down or overloaded. Please retry."
                                type="warning"
                            />}
                        {(Array.isArray(commonQuestions) && commonQuestions.length === 0) ?
                            <Spin/>
                            :
                            <List
                                size="small"
                                bordered
                                dataSource={commonQuestions.concat(["What is the best web site to learn more about my study?",
                                    "What is the best scientific article to review the observations of my study?"])}
                                renderItem={item =>
                                    <List.Item>
                                        <Link onClick={() => showExplainModal(item)}>
                                            {item}
                                        </Link>
                                    </List.Item>}
                            />
                        }
                        {/* Your report */}
                        <Divider orientation="left">Your report</Divider>
                        <Paragraph>
                            {sectionsReport.length > 1 &&
                                <Segmented options={sectionsReport}
                                           defaultValue={currentReportSection}
                                           onChange={sectionOnChange}
                                />
                            }
                            <blockquote>
                                {sectionsContentReport[currentReportSection]}
                            </blockquote>
                        </Paragraph>
                    </div>
                </Space>

                <Modal title={""}
                       open={isExplainModalOpen}
                       onOk={handleExplainModalCancel}
                       onCancel={handleExplainModalCancel}
                       maskClosable={false}
                >
                    <div style={{margin: "50px"}}>
                        <center>Verbose complexity</center>
                        <Slider
                            marks={{
                                0: 'Patient',
                                1: 'Informed',
                                2: {
                                    style: {
                                        color: '#f50',
                                    },
                                    label: <strong>Radiologist</strong>,
                                }
                            }}
                            step={1}
                            defaultValue={levelSliderValue}
                            min={0}
                            max={2}
                            onAfterChange={onLevelSliderAfterChange}
                            disabled={isLevelSliderDisabled}
                        />
                    </div>
                    {/*question and answers*/}
                    <Space direction={"vertical"}>
                        {typeof explainModalContent[levelSliderValue] === "object" &&
                        !Array.isArray(explainModalContent[levelSliderValue]) &&
                        explainModalContent[levelSliderValue] !== null ?
                            <>
                                {Object.keys(explainModalContent[levelSliderValue]).length === 0 && <Spin/>}
                                {Object.keys(explainModalContent[levelSliderValue]).length > 0 &&
                                    <>
                                        {/*question*/}
                                        {explainModalContent[levelSliderValue]["question"] ?
                                            <b>
                                                <Space direction={"horizontal"}>
                                                    <QuestionCircleOutlined/> {explainModalContent[levelSliderValue]["question"]}
                                                </Space>
                                            </b>
                                            :
                                            <>
                                                <Spin/>
                                            </>
                                        }
                                        {/*response*/}
                                        {explainModalContent[levelSliderValue]["response"] ?
                                            <>
                                                {explainModalContent[levelSliderValue]["response"]}
                                            </>
                                            :
                                            <>
                                                <Spin/>
                                            </>
                                        }
                                        {/*followup button*/}
                                        {explainModalContent[levelSliderValue]["showFollowUpButton"] &&
                                            <Button
                                                type="primary"
                                                icon={<WechatOutlined/>}
                                                onClick={() => onStartFollowUpQuestion()}
                                                style={{marginTop: "15px"}}>
                                                I have a follow-up question
                                            </Button>
                                        }
                                        {/*follow-up Questions*/}
                                        {"followUpQuestions" in explainModalContent[levelSliderValue] &&
                                            <>
                                                {explainModalContent[levelSliderValue]["followUpQuestions"] ?
                                                    <List
                                                        size="small"
                                                        bordered
                                                        dataSource={explainModalContent[levelSliderValue]["followUpQuestions"]}
                                                        renderItem={item =>
                                                            <List.Item>
                                                                <Link
                                                                    onClick={() => showExplainModal(item)}>{item}</Link>
                                                            </List.Item>}
                                                    />
                                                    :
                                                    <>
                                                        <Spin/>
                                                    </>
                                                }
                                            </>
                                        }
                                    </>
                                }
                            </>
                            :
                            <>
                                <Alert message="Something is wrong" type="error" showIcon/>
                            </>
                        }
                    </Space>
                </Modal>

            </>
        )
    }


    return (
        <>
            <Space direction={"vertical"}>
                <div style={{width: 900}}>
                    <Paragraph>
                        Please paste your radiology report:
                    </Paragraph>
                    {reportContent.trim() &&
                        <Affix offsetTop={20} style={{float: "right", marginBottom: 10}}>
                            <Button type="primary" onClick={() => submitReport()}>
                                Explain my report
                            </Button>
                        </Affix>
                    }
                    <TextArea
                        style={{width: 900}}
                        autoSize={{minRows: 4}}
                        onChange={reportOnChange}
                        defaultValue={REPORT_DEFAULT_PROMPT}
                    />
                </div>
            </Space>
        </>
    );
};

export default ExplainReport