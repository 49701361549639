import React from 'react'
import {Layout} from 'antd'
import {Switch, Route} from "react-router-dom";


import HomeContainer from 'components/Layout/Pages/Home/HomeContainer'
import PrivacyContainer from 'components/Layout/Pages/Home/PrivacyContainer'
import QuickstartContainer from "components/Layout/Pages/Quickstart/QuickstartContainer";
import Installation from "components/Layout/Pages/Documentation/Installation/Installation";
import Philosophy from "components/Layout/Pages/Documentation/Philosophy/Philosophy";
import Configs from "components/Layout/Pages/Documentation/Configs/Configs";
import SolutionContainer from "components/Layout/Pages/Solution/SolutionContainer";
import SharedTaskContainer from "components/Layout/Pages/Misc/BioNLP23/SharedTaskContainer";
import MiscScriptsContainer from "components/Layout/Pages/Misc/Scripts/MiscScriptsContainer";
import HFAssignContainer from "components/Layout/Pages/Misc/HFAssign/HFAssignContainer";
import StructuredReportsReviewContainer
    from "components/Layout/Pages/Misc/StructuredReportsReview/StructuredReportsReviewContainer";
import {default as BioNLP23Leaderboard} from "components/Layout/Pages/Misc/BioNLP23/Leaderboard";
import {default as BioNLP24Leaderboard} from "components/Layout/Pages/Misc/BioNLP24/Leaderboard";
import {default as AIMI23Leaderboard} from "components/Layout/Pages/Misc/AIMISummer23/Leaderboard";
import {default as AIMI24Leaderboard} from "components/Layout/Pages/Misc/AIMISummer24/Leaderboard";
import {default as RRGSol} from "components/Layout/Pages/Solution/RRG/RRGContainer";
import {default as RRSSol} from "components/Layout/Pages/Solution/RRS/RRSContainer";
import {default as MVQASol} from "components/Layout/Pages/Solution/MVQA/MVQAContainer";
import {default as RRGSoft} from "components/Layout/Pages/Software/RRG/RRGContainer";
import {default as RadGraphSoft} from "components/Layout/Pages/Software/RadGraph/RadGraphContainer";
import {default as DeIdSoft} from "components/Layout/Pages/Software/DeID/DeIDContainer";
import Emnlp2022Container from "components/Layout/Pages/Papers/EMNLP2022/emnlp2022Container";
import Acl2023Container from "components/Layout/Pages/Papers/ACL2023/acl2023Container";
import ExplainReportContainer from "components/Layout/Pages/Misc/explainReport/explainReportContainer";
import ExplainReportAnnotationContainer
    from "components/Layout/Pages/Misc/explainReport/annotations/annotateReportContainer";
import MimicCxrContainer from "components/Layout/Pages/Datasets/MIMIC-CXR/MimicCxrContainer";
import ImageDatasetsContainer from "components/Layout/Pages/Datasets/Images/ImageDatasetsContainer";
import ReportDatasetsContainer from "components/Layout/Pages/Datasets/Reports/ReportDatasetsContainer";
import PapersDatasetsContainer from "components/Layout/Pages/Datasets/Papers/PapersDatasetsContainer";

import {ProviderWrapper as ExplainReportProvider} from "components/Layout/Pages/Misc/explainReport/ExplainReportContext"
import {ProviderWrapper as RadGraphProvider} from "components/Layout/Pages/Misc/explainReport/RadGraphContext"
import {ProviderWrapper as SummarizationProvider} from "components/Layout/Pages/Misc/explainReport/SummarizationContext"
import {ProviderWrapper as ConversationProvider} from "components/Layout/Pages/Misc/explainReport/ConversationContext"

const {Content} = Layout;

const MainContent = () => {
    return (
        <Content style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
        }}
        >
            <div className="site-layout-content" style={{minHeight: window.innerHeight}}>
                <Switch>
                    <Route exact path="/">
                        <HomeContainer/>
                    </Route>
                    <Route exact path="/privacy">
                        <PrivacyContainer/>
                    </Route>
                    <Route exact path="/quickstart/">
                        <QuickstartContainer/>
                    </Route>
                    <Route exact path="/datasets/mimic-cxr/">
                        <MimicCxrContainer/>
                    </Route>

                    <Route exact path="/datasets/images/">
                        <ImageDatasetsContainer/>
                    </Route>
                    <Route exact path="/datasets/reports/">
                        <ReportDatasetsContainer/>
                    </Route>
                    <Route exact path="/datasets/papers/">
                        <PapersDatasetsContainer/>
                    </Route>

                    <Route exact path="/documentation/installation/">
                        <Installation/>
                    </Route>
                    <Route exact path="/documentation/philosophy/">
                        <Philosophy/>
                    </Route>
                    <Route exact path="/documentation/configs/">
                        <Configs/>
                    </Route>
                    <Route exact path="/solution/">
                        <SolutionContainer/>
                    </Route>
                    <Route exact path="/solution/rrg/">
                        <RRGSol/>
                    </Route>
                    <Route exact path="/solution/mvqa/">
                        <MVQASol/>
                    </Route>
                    <Route exact path="/solution/rrs/">
                        <RRSSol/>
                    </Route>
                    <Route exact path="/misc/bionlp23/sharedtask/">
                        <SharedTaskContainer/>
                    </Route>
                    <Route exact path="/misc/bionlp23/leaderboard/">
                        <BioNLP23Leaderboard/>
                    </Route>
                    <Route exact path="/misc/bionlp24/leaderboard/">
                        <BioNLP24Leaderboard/>
                    </Route>
                    <Route exact path="/misc/aimi23/leaderboard/">
                        <AIMI23Leaderboard/>
                    </Route>
                    <Route exact path="/misc/aimi24/leaderboard/">
                        <AIMI24Leaderboard/>
                    </Route>
                    <Route exact path="/misc/explainreport/">
                        <ConversationProvider>
                            <ExplainReportProvider>
                                <RadGraphProvider>
                                    <SummarizationProvider>
                                        <ExplainReportContainer/>
                                    </SummarizationProvider>
                                </RadGraphProvider>
                            </ExplainReportProvider>
                        </ConversationProvider>
                    </Route>
                    <Route exact path="/misc/scripts/">
                        <MiscScriptsContainer/>
                    </Route>
                    <Route exact path="/misc/HFAssignment/">
                        <HFAssignContainer/>
                    </Route>
                    <Route exact path="/misc/explainreport/annotations/">
                        <ExplainReportAnnotationContainer/>
                    </Route>
                    <Route exact path="/papers/emnlp2022/">
                        <Emnlp2022Container/>
                    </Route>
                    <Route exact path="/papers/acl2023/">
                        <Acl2023Container/>
                    </Route>

                    {/*Software*/}
                    <Route exact path="/software/rrg/">
                        <RRGSoft/>
                    </Route>
                    <Route exact path="/software/radgraph/">
                        <RadGraphSoft/>
                    </Route>
                    <Route exact path="/software/deid/">
                        <DeIdSoft/>
                    </Route>


                </Switch>
            </div>
        </Content>
    )
}

export default MainContent
