import React, {useEffect, useState} from 'react'
import {
    Button,
    Divider,
    Dropdown,
    Input,
    message,
    notification,
    Select,
    Space,
    Spin,
    Table,
    Tooltip,
    Typography,
    Upload
} from 'antd';
import {InboxOutlined, UserOutlined, UnlockOutlined, FileAddOutlined, DownOutlined} from '@ant-design/icons';
import aimiSummer24Api from "services/misc/aimisummer24";

const {Dragger} = Upload;


const columnsAll = [
    {
        title: 'Team',
        dataIndex: 'team',
        key: 'team',
    },
    {
        title: 'Macro F1',
        dataIndex: 'macro_f1',
        key: 'macro_f1',
        render: (text) => text === null ? 'N/A' : text.toFixed(2),
    },
    {
        title: 'Macro AUROC',
        dataIndex: 'macro_auc',
        key: 'macro_auc',
        render: (text) => text === null ? 'N/A' : text.toFixed(2)
    },
    {
        title: 'No Finding AUROC',
        dataIndex: 'NoFindingAUROC',
        key: 'NoFindingAUROC',
        render: (text) => text === null ? 'N/A' : text.toFixed(2),

    },
    {
        title: 'No Finding F1',
        dataIndex: 'NoFindingF1',
        key: 'NoFindingF1',
        render: (text) => text === null ? 'N/A' : text.toFixed(2)
    },
    {
        title: 'Pleural Effusion AUROC',
        dataIndex: 'PleuralEffusionAUROC',
        key: 'PleuralEffusionAUROC',
        render: (text) => text === null ? 'N/A' : text.toFixed(2)
    },
    {
        title: 'Pleural Effusion F1',
        dataIndex: 'PleuralEffusionF1',
        key: 'PleuralEffusionF1',
        render: (text) => text === null ? 'N/A' : text.toFixed(2)
    },
    {
        title: 'Pneumonia AUROC',
        dataIndex: 'PneumoniaAUROC',
        key: 'PneumoniaAUROC',
        render: (text) => text === null ? 'N/A' : text.toFixed(2)
    },
    {
        title: 'Pneumonia F1',
        dataIndex: 'PneumoniaF1',
        key: 'PneumoniaF1',
        render: (text) => text === null ? 'N/A' : text.toFixed(2)
    },
    {
        title: 'Pneumothorax AUROC',
        dataIndex: 'PneumothoraxAUROC',
        key: 'PneumothoraxAUROC',
        render: (text) => text === null ? 'N/A' : text.toFixed(2)
    },
    {
        title: 'Pneumothorax F1',
        dataIndex: 'PneumothoraxF1',
        key: 'PneumothoraxF1',
        render: (text) => text === null ? 'N/A' : text.toFixed(2)
    },
    {
        title: 'Time',
        dataIndex: 'time',
        key: 'time',
        render: (text) => text ,
        defaultSortOrder: 'descend',
        sorter: (a, b) => new Date(a.time) - new Date(b.time),

    },


];


const Retrieve = ({}) => {

    const [team, setTeam] = useState(null);
    const [waitForResult, setWaitForResult] = useState(false);
    const [data, setData] = useState(null);

    const openNotificationWithIcon = (type, description) => {
        notification[type]({
            message: 'Notification: ' + type,
            description: description,
            duration: 0,
        });
    };

    useEffect(() => {
        resetAll()
    }, [])


    function resetAll() {
        setWaitForResult(false)
        setData(null)
        setTeam(null)
    }

    const submitResults = async () => {
        setWaitForResult(true)


        if (!(team) || team === "") {
            message.error("No team provided")
            setWaitForResult(false)
            return
        }


        let ret = await aimiSummer24Api.getScoresForTeam(team)

        if (!(Array.isArray(ret))) {
            openNotificationWithIcon("error", "Error happened when retrieving the submissions. Try later.")
            resetAll()
            return
        }

        if ('error' in ret) {
            openNotificationWithIcon("error", ret["error"])
            resetAll()
            return
        }
        setData(ret)
        console.log(ret)
        setWaitForResult(false)
    }


    return (
        <>
            <Space direction={"vertical"}>
                <>
                    <Input placeholder="Team name" onChange={(e) => setTeam(e.target.value)}
                           prefix={<UserOutlined/>}
                           style={{width: 200}}/>
                    <Space direction={"horizontal"}>
                        <Button type="primary" onClick={() => submitResults()}>Submit</Button>
                    </Space>
                </>

                <div>
                    {waitForResult &&
                        <div style={{padding: 50}}>
                            <Spin tip="Retrieving scores...">
                                <div className="content"/>
                            </Spin>
                        </div>
                    }
                    {data &&
                        <div>
                            <Divider orientation="left">Team results</Divider>
                            <Table columns={columnsAll} dataSource={data}/>
                        </div>
                    }
                </div>
            </Space>
        </>

    )
}

export default Retrieve