import React, {useEffect, useRef} from 'react';
import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import dicomParser from 'dicom-parser';
import Hammer from 'hammerjs';
import cornerstoneMath from 'cornerstone-math';

// Configuring cornerstone tools and libraries
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneTools.init();

cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

const workerConfig = {
    maxWebWorkers: navigator.hardwareConcurrency || 1,
    startWebWorkersOnDemand: true,
    taskConfiguration: {
        'decodeTask': {
            loadCodecsOnStartup: true,
            initializeCodecsOnStartup: false,
            codecsPath: 'cornerstoneWADOImageLoaderCodecs.js',
            usePDFJS: false,
            strict: false
        }
    }
};

cornerstoneWADOImageLoader.webWorkerManager.initialize(workerConfig);
localStorage.setItem("debug", "cornerstoneTools");

const DICOMViewer = ({fileUrl}) => {
    const dicomRef = useRef(null);

    useEffect(() => {
        const element = dicomRef.current;
        cornerstone.enable(element);

        const loadAndDisplayImage = async () => {
            try {
                const image = await cornerstone.loadImage(`wadouri:${fileUrl}`);
                cornerstone.displayImage(element, image);
                addTools(element);
            } catch (error) {
                console.error('Error loading DICOM image:', error);
            }
        };

        loadAndDisplayImage();

        return () => cornerstone.disable(element);
    }, [fileUrl]);

    const addTools = (element) => {
        const WwwcTool = cornerstoneTools.WwwcTool;

        cornerstoneTools.addTool(WwwcTool)
        cornerstoneTools.setToolActive('Wwwc', {mouseButtonMask: 1})

        const ZoomMouseWheelTool = cornerstoneTools.ZoomMouseWheelTool;

        cornerstoneTools.addTool(ZoomMouseWheelTool)
        cornerstoneTools.setToolActive('ZoomMouseWheel', {mouseButtonMask: 1})

        // Add our tool, and set it's mode
        const PanTool = cornerstoneTools.PanTool;
        cornerstoneTools.addTool(PanTool);
        // Change the mouseButtonMask value to 4 for right mouse button
        cornerstoneTools.setToolActive('Pan', {mouseButtonMask: 4});

    };

    return (
        <>
            <div ref={dicomRef} style={{width: '100%', height: '100%'}}></div>
        </>
    );
};

export default DICOMViewer;
//https://tools.cornerstonejs.org/examples/