import React, {useContext, useState} from 'react'
import {
    Affix,
    Space,
    Input,
    Table,
    message,
    Modal,
    Spin,
    Alert,
    Button,
    Typography,
    Divider, List, Slider
} from 'antd';
import ExplainReportContext from "components/Layout/Pages/RadGPT/explainReport/ExplainReportContext";
import RadGraphContext from "components/Layout/Pages/RadGPT/explainReport/RadGraphContext";
import SummarizationContext from "components/Layout/Pages/RadGPT/explainReport/SummarizationContext";
import ConversationContext from "./ConversationContext";
import {QuestionCircleOutlined, WechatOutlined} from "@ant-design/icons";

const {TextArea} = Input;
const {Paragraph, Link, Title} = Typography;

const titleStyle = {
    marginBottom: '20px',
    fontWeight: 900,
    fontSize: '68px',
};


const ExplainReport = () => {
    const {
        reportContent,
        setReportContent,
        commonQuestions,
        setCommonQuestions,
        REPORT_DEFAULT_PROMPT,
        SLIDER_LEVEL_DEFAULT_VALUE,
        setExplainModalContent,
        explainModalContent,
        setLevelSliderValue,
        levelSliderValue,
        getQuestionAnswerPipeline,
        getNCommonQuestions,
        setCurrentQuestion,
        setCurrentResponse,
        getAnswerNewLevelSlider,
        onStartFollowUpQuestion,
    } = useContext(ExplainReportContext);

    const {
        getProcessedRadGraphAnnotations,
        processRadGraphAnnotations,
        radGraphTableRecord,
        setRadGraphTableRecord,
        radgraphReportContent,
        setRadgraphReportContent,
    } = useContext(RadGraphContext);

    const {
        summarizedReport,
        setSummarizedReport,
        sectionsReport,
        setSectionsReport,
        sectionsContentReport,
        setSectionsContentReport,
        currentReportSection,
        setCurrentReportSection,
        getSummaryOfReport,
    } = useContext(SummarizationContext);


    const {
        conversation,
        setConversation,
    } = useContext(ConversationContext);


    const [reportSentIsLoading, setReportSentIsLoading] = useState(false);
    const [isLevelSliderDisabled, setIsLevelSliderDisabled] = useState(false);
    const [isExplainModalOpen, setIsExplainModalOpen] = useState(false);

    const reportOnChange = (e) => {
        setReportContent(e.target.value);
    };


    const onLevelSliderAfterChange = (level) => {
        getAnswerNewLevelSlider(level)
    };


    function resetAll() {
        setReportContent(REPORT_DEFAULT_PROMPT)
        setReportSentIsLoading(false)
        setRadGraphTableRecord(null)
        setIsExplainModalOpen(false)
        setExplainModalContent([{}, {}, {}])
        setReportSentIsLoading(false)
        setCommonQuestions([])
        setCurrentQuestion("")
        setCurrentResponse("")
        setIsLevelSliderDisabled(false)
        setLevelSliderValue(SLIDER_LEVEL_DEFAULT_VALUE)
        //summ section
        setSectionsReport([])
        setSectionsContentReport({})
        setCurrentReportSection("")
        setSummarizedReport("")
        setRadgraphReportContent("")
    }

    function displayError(msg) {
        message.error(msg);
        resetAll()
    }

    const handleExplainModalCancel = () => {
        setIsExplainModalOpen(false);
        setExplainModalContent([{}, {}, {}]);
    };

    const showExplainModal = async (text, enhance = false) => {
        console.log(text)
        setIsExplainModalOpen(true);
        setIsLevelSliderDisabled(true)
        await getQuestionAnswerPipeline(text, levelSliderValue, enhance)
        setIsLevelSliderDisabled(false)
    };


    async function submitReport() {
        setReportSentIsLoading(true)

        // Compute summary
        // let reportSummary = await getSummaryOfReport(reportContent)
        let reportSummary = {"report": {"report": reportContent}}

        if (typeof reportSummary !== 'object') {
            displayError("Backend is offline");
            resetAll()
            return
        }

        if ("error" in reportSummary) {
            displayError(reportSummary["error"]);
            resetAll()
            return
        }
        reportSummary = reportSummary["report"]
        setSectionsReport(Object.keys(reportSummary))
        setSectionsContentReport(reportSummary)
        const defaultSection =
            (
                "impression" in reportSummary ? "impression" :
                    "findings" in reportSummary ? "findings" :
                        "summary" in reportSummary ? "summary" : "report"
            )
        setCurrentReportSection(defaultSection)
        let summary = reportSummary[defaultSection]
        setSummarizedReport(summary)

        // get annotations
        const rgAnnotations = await getProcessedRadGraphAnnotations(summary)

        if (typeof rgAnnotations !== 'object') {
            displayError("Backend is offline");
            resetAll()
            return
        }

        if ("error" in rgAnnotations) {
            displayError(rgAnnotations["error"]);
            resetAll()
            return
        }

        processRadGraphAnnotations(rgAnnotations, showExplainModal)
        setReportSentIsLoading(false)

        // let questions = []
        getNCommonQuestions(reportContent, 5, "radiology report").then(questions => {
            setCommonQuestions(questions)
        })
            .catch(error => {
                console.log(error)
                setCommonQuestions(null)
            });

    }

    if (reportSentIsLoading)
        return <Spin/>


    if (radGraphTableRecord) {
        return (
            <>
                <Space direction={"vertical"}>
                    <div style={{width: 900}}>
                        <Affix offsetTop={20} style={{float: "right"}}>
                            <Button type="primary" onClick={() => resetAll()} danger>
                                Reset
                            </Button>
                        </Affix>
                        <Divider orientation="left">Summary of your report</Divider>
                        <Paragraph>
                            <center>{radgraphReportContent.map((word) => <>{word} </>)}</center>
                        </Paragraph>
                        {/*common questions */}
                        <Divider orientation="left">Common questions</Divider>
                        {commonQuestions === null &&
                            <Alert
                                message="Warning"
                                description="OpenAI is down or overloaded. Please retry."
                                type="warning"
                            />}
                        {(Array.isArray(commonQuestions) && commonQuestions.length === 0) ?
                            <Spin/>
                            :
                            <List
                                size="small"
                                bordered
                                dataSource={commonQuestions.concat(["What is the best web site to learn more about my study?"])}
                                renderItem={item =>
                                    <List.Item>
                                        <Link onClick={() => showExplainModal(item)}>
                                            {item}
                                        </Link>
                                    </List.Item>}
                            />
                        }
                    </div>
                </Space>

                <Modal title={""}
                       open={isExplainModalOpen}
                       onOk={handleExplainModalCancel}
                       onCancel={handleExplainModalCancel}
                       maskClosable={false}
                >
                    {/*question and answers*/}
                    <Space direction={"vertical"}>
                        {typeof explainModalContent[levelSliderValue] === "object" &&
                        !Array.isArray(explainModalContent[levelSliderValue]) &&
                        explainModalContent[levelSliderValue] !== null ?
                            <>
                                {Object.keys(explainModalContent[levelSliderValue]).length === 0 && <Spin/>}
                                {Object.keys(explainModalContent[levelSliderValue]).length > 0 &&
                                    <>
                                        {/*question*/}
                                        {explainModalContent[levelSliderValue]["question"] ?
                                            <b>
                                                <Space direction={"horizontal"}>
                                                    <QuestionCircleOutlined/> {explainModalContent[levelSliderValue]["question"]}
                                                </Space>
                                            </b>
                                            :
                                            <>
                                                <Spin/>
                                            </>
                                        }
                                        {/*response*/}
                                        {explainModalContent[levelSliderValue]["response"] ?
                                            <>
                                                {explainModalContent[levelSliderValue]["response"]}
                                            </>
                                            :
                                            <>
                                                <Spin/>
                                            </>
                                        }
                                        {/*followup button*/}
                                        {explainModalContent[levelSliderValue]["showFollowUpButton"] &&
                                            <Button
                                                type="primary"
                                                icon={<WechatOutlined/>}
                                                onClick={() => onStartFollowUpQuestion()}
                                                style={{marginTop: "15px"}}>
                                                I have a follow-up question
                                            </Button>
                                        }
                                        {/*follow-up Questions*/}
                                        {"followUpQuestions" in explainModalContent[levelSliderValue] &&
                                            <>
                                                {explainModalContent[levelSliderValue]["followUpQuestions"] ?
                                                    <List
                                                        size="small"
                                                        bordered
                                                        dataSource={explainModalContent[levelSliderValue]["followUpQuestions"]}
                                                        renderItem={item =>
                                                            <List.Item>
                                                                <Link
                                                                    onClick={() => showExplainModal(item)}>{item}</Link>
                                                            </List.Item>}
                                                    />
                                                    :
                                                    <>
                                                        <Spin/>
                                                    </>
                                                }
                                            </>
                                        }
                                    </>
                                }
                            </>
                            :
                            <>
                                <Alert message="Something is wrong" type="error" showIcon/>
                            </>
                        }

                    </Space>
                </Modal>

            </>
        )
    }


    return (
        <>
            <Space direction={"vertical"}>
                <div style={{width: 900}}>
                    <Typography>
                        <Title style={titleStyle}>RadGPT</Title>
                    </Typography>
                    <TextArea
                        style={{width: 900}}
                        autoSize={{minRows: 4}}
                        onChange={reportOnChange}
                        defaultValue={REPORT_DEFAULT_PROMPT}
                    />
                    {reportContent.trim() &&
                        <Affix offsetTop={50} style={{float: "right", marginTop: 10}}>
                            <Button type="primary" onClick={() => submitReport()}>
                                Explain my report
                            </Button>
                        </Affix>
                    }

                </div>
                <p style={{
                    position: 'fixed',
                    bottom: 0,
                    width: 900,
                    textAlign: 'center',
                    backgroundColor: 'white', // Optional, to make sure it stands out
                    padding: '10px 0'
                }}>
                    This work was supported in part by the Medical Imaging and Data Resource Center (MIDRC), which is
                    funded by the National Institute of Biomedical Imaging and Bioengineering (NIBIB) of the National
                    Institutes of Health under contract 75N92020D00021 and through The Advanced Research Projects Agency
                    for Health (ARPA-H)
                </p>
            </Space>
        </>
    );
};

export default ExplainReport