import React, {useContext} from 'react'
import {Alert, Anchor, List, Space, Typography} from 'antd';
import "./style/style.css"
import SyntaxHighlighter from 'react-syntax-highlighter';
import GlobalContext from "contexts/GlobalContext";

const {Link} = Anchor;


const {Paragraph, Title, Text} = Typography;

const Configs = () => {
    const {codeStyle, VilmedicTag} = useContext(GlobalContext);
    return (
        <div style={{display: 'flex'}}>
            <div style={{width: 900}} id={"docPageContent"}>
                <Space direction={"vertical"}>


                </Space>
            </div>
            <div style={{marginLeft: '10px'}}>
                <Anchor>
                    <Link href="#anchor-model" title="Models"/>
                    <Link href="#anchor-blocks" title="Blocks"/>
                    <Link href="#anchor-configs" title="Configs"/>
                </Anchor>
            </div>
        </div>
    )
}

export default Configs
