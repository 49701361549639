import React, {useEffect, useState} from 'react'
import SharedTask from "./SharedTask";

const SharedTaskContainer = () => {

    return (
        <SharedTask/>
    )
}

export default SharedTaskContainer