import React from 'react'
import "./style/style.css"
import {Alert, Space, Upload, Typography, Button, Spin, PageHeader, Row} from 'antd';
import {EditFilled, FilePdfOutlined, SettingOutlined} from "@ant-design/icons";


const {Title, Paragraph, Text, Link} = Typography;


const RRG = ({fileList, imageOnChange, imageOnPreview, sendImage, report, imageSent}) => {
    const Content = ({children, extraContent}) => (
        <Row key={"rowContent"}>
            <div key="children" style={{flex: 1,}}>{children}</div>
            <div key="extraContent" className="image">{extraContent}</div>
        </Row>
    );

    return (
        <>
            <div style={{width: 900}}>
                <Title level={2}>Radiology Report Generation</Title>
                <PageHeader
                    title=""
                    className="site-page-header"
                    subTitle="Improving the Factual Correctness of Radiology Report Generation with Semantic Rewards"
                    extra={[
                        <Button key="b3" icon={<FilePdfOutlined/>}
                                href={"https://aclanthology.org/2022.findings-emnlp.319"}>Paper</Button>,
                        <Button key="b2"
                                icon={<SettingOutlined/>}
                                href={"/papers/emnlp2022/"}>Config</Button>,
                    ]}
                    avatar={{
                        src: 'https://d1nhio0ox7pgb.cloudfront.net/_img/g_collection_png/standard/512x512/graph_fork.png',
                    }}
                >
                    <Content
                        extraContent={
                            <img
                                src="https://pbs.twimg.com/media/FSvRXQWX0AIcy7S.jpg"
                                alt="content"
                                width="146px"
                            />
                        }
                    >
                        <>
                            <Paragraph>
                                Neural image-to-text radiology report generation systems offer the potential to
                                improve
                                radiology reporting by reducing the repetitive process of report drafting and
                                identifying possible medical errors. We propose a new method, the RadGraph
                                reward,
                                to
                                further improve the factual completeness and correctness
                                of generated radiology reports. More precisely, we leverage the RadGraph dataset
                                containing annotated chest X-ray reports with entities and relations between
                                entities.
                            </Paragraph>
                        </>
                    </Content>
                </PageHeader>
                <Space direction={"horizontal"} align={"start"}>
                    <Space direction={"vertical"}>
                        {fileList.length === 0 && <Alert message={"Upload a chest x-ray"} type="info" showIcon/>}
                        <Upload
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            listType="picture-card"
                            fileList={fileList}
                            onChange={imageOnChange}
                            onPreview={imageOnPreview}
                            accept={"image/png, image/jpeg"}
                        >
                            {fileList.length < 1 && '+ Upload'}
                        </Upload>
                        {fileList.length > 0 &&
                            <>
                                <Button icon={<EditFilled/>} onClick={() => sendImage()} type="primary">Generate
                                    impression</Button>
                            </>
                        }
                    </Space>
                    {imageSent &&
                        <Spin tip="Computing Findings">
                            <div className="content"/>
                        </Spin>}
                    {report.length > 0 && report.map((item, index) => (
                        <Alert
                            key={index}
                            message="Findings"
                            description={item}
                            type="info"
                        />
                    ))}
                </Space>
            </div>
        </>
    );
};

export default RRG
