import React, {useState} from 'react';
import explainReportApi from "services/explainReport";

const Context = React.createContext(null);
const ProviderWrapper = (props) => {

    const [summarizedReport, setSummarizedReport] = useState("");
    const [sectionsReport, setSectionsReport] = useState([])
    const [sectionsContentReport, setSectionsContentReport] = useState({})
    const [currentReportSection, setCurrentReportSection] = useState("")

    const getSummaryOfReport = async (report) => {
        return await explainReportApi.getSummarizationReport(report)
    }

    const exposedValues = {
        summarizedReport,
        setSummarizedReport,
        sectionsReport,
        setSectionsReport,
        sectionsContentReport,
        setSectionsContentReport,
        currentReportSection,
        setCurrentReportSection,
        getSummaryOfReport,
    }

    return (
        <Context.Provider value={exposedValues}>{props.children}</Context.Provider>
    )
}
export {Context, ProviderWrapper};
export default Context;