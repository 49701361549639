import config from "services/config";


const saveAnnotations = (annotations, selectedSpecialty, username, problemId) => {
    return config.miscApi
        .post('/smmile/saveAnnotations', {
            annotations: annotations,
            speciality: selectedSpecialty,
            username: username,
            original_problem_id: problemId, // Include original problem ID
        })
        .then((response) => {
            return response.data;
        })
        .catch(() => {
            return {
                error: 'Backend is offline or received critical error',
            };
        });
};


const fetchRows = (offset) => {
    return config.miscApi.post("/smmile/fetch-rows", {
        offset: offset,
    }).then(response => {
        return response.data
    }).catch(() => {
        return {
            error: 'Backend is offline or received critical error',
        }
    })
}

const retrieveAnnotation = (problem_id) => {
    return config.miscApi.post("/smmile/retrieveAnnotations", {
        problem_id: problem_id,
    }).then(response => {
        return response.data
    }).catch(() => {
        return {
            error: 'Backend is offline or received critical error',
        }
    })
}


const removeAnnotations = (parquet) => {
    return config.miscApi.post("/smmile/removeAnnotations", {
        parquet: parquet,
    }).then(response => {
        return response.data
    }).catch(() => {
        return {
            error: 'Backend is offline or received critical error',
        }
    })
}


const exportedObject = {
    saveAnnotations,
    fetchRows,
    removeAnnotations,
    retrieveAnnotation,
}

export default exportedObject