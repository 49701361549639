import React, {useEffect, useContext, useState} from 'react'
import {Alert, Divider, message, notification, Space, Spin, Table, Typography, Upload} from 'antd';
import aimiSummer23Api from "services/misc/aimisummer23";
import LeaderboardDragger from "./LeaderboardDragger"
import SyntaxHighlighter from "react-syntax-highlighter";
import GlobalContext from "contexts/GlobalContext";
import Retrieve from "./Retrieve";
import './style/style.css';

const {Title, Text, Paragraph, Link} = Typography;

const code_process_distance_pred = 'from sklearn.metrics import mean_absolute_error\n' +
    'from sklearn.metrics import mean_squared_error\n' +
    'from sklearn.metrics import r2_score\n' +
    'import math\n' +
    'result = {\n' +
    '            "mae": mean_absolute_error(true, preds),\n' +
    '            "mse": mean_squared_error(true, preds),\n' +
    '            "rmse": math.sqrt(mean_squared_error(true, preds)),\n' +
    '            "r-squared": r2_score(true, preds)\n' +
    '        }'

const code_process_distance_cat = 'import torchmetrics\n' +
    'acc = torchmetrics.classification.BinaryAUROC()\n' +
    'acc.update(torch.tensor([0.42, 0.56]), torch.tensor([0.0, 1.0]))\n' +
    'acc.compute()'

const DATASETS = {
    "distance-categorization": 501,
    "distance-prediction": 501,
}

const openNotificationWithIcon = (type, description) => {
    notification[type]({
        message: 'Notification: ' + type,
        description: description,
        duration: 0,
    });
};


const columnsSingle = {
    "distance-categorization": [
        {
            title: 'Accuracy',
            dataIndex: 'accuracy',
            key: 'accuracy',
            render: (text) =>
                text === null ? 'N/A' : (text * 100).toFixed(2)
        },
        {
            title: 'AUROC',
            dataIndex: 'auroc',
            key: 'auroc',
            render: (text) =>
                text === null ? 'N/A' : (text * 100).toFixed(2)
        },
    ],
    "distance-prediction": [
        {
            title: 'MAE',
            dataIndex: 'mae',
            key: 'mae',
            render: (text) =>
                text === null ? 'N/A' : (text).toFixed(2)
        },
        {
            title: 'MSE',
            dataIndex: 'mse',
            key: 'mse',
            render: (text) =>
                text === null ? 'N/A' : (text).toFixed(2)
        },
        {
            title: 'r-MSE',
            dataIndex: 'rmse',
            key: 'rmse',
            render: (text) =>
                text === null ? 'N/A' : (text).toFixed(2)
        },
        {
            title: 'R-squared',
            dataIndex: 'r-squared',
            key: 'r-squared',
            render: (text) =>
                text === null ? 'N/A' : (text).toFixed(2)
        },
    ]
}

const header_categorization = [
    {
        title: 'Team',
        dataIndex: 'team',
        key: 'team',
    },
    {
        title: 'Accuracy',
        dataIndex: 'accuracy',
        key: 'accuracy',
        render: (text) =>
            text === null ? 'N/A' : (text * 100).toFixed(2)
    },
    {
        title: 'AUROC',
        dataIndex: 'auroc',
        key: 'auroc',
        render: (text) =>
            text === null ? 'N/A' : (text * 100).toFixed(2),
        defaultSortOrder: 'descend',
        sorter: {
            compare: (a, b) => a.auroc - b.auroc,
            multiple: 1,
        },
    },
];

const header_prediction = [
    {
        title: 'Team',
        dataIndex: 'team',
        key: 'team',
    },
    {
        title: 'MAE',
        dataIndex: 'mae',
        key: 'mae',
        render: (text) =>
            text === null ? 'N/A' : (text).toFixed(2),
        defaultSortOrder: 'ascend',
        sorter: {
            compare: (a, b) => a.mae - b.mae,
            multiple: 1,
        },
    },
    {
        title: 'MSE',
        dataIndex: 'mse',
        key: 'mse',
        render: (text) =>
            text === null ? 'N/A' : (text).toFixed(2)
    },
    {
        title: 'r-MSE',
        dataIndex: 'rmse',
        key: 'rmse',
        render: (text) =>
            text === null ? 'N/A' : (text).toFixed(2)
    },
    {
        title: 'R-squared',
        dataIndex: 'r-squared',
        key: 'r-squared',
        render: (text) =>
            text === null ? 'N/A' : (text).toFixed(2)
    },
];


const Leaderboard = () => {

    const [waitForTeam, setWaitForTeam] = useState(false);
    const [waitForResult, setWaitForResult] = useState(false);
    const [hyps, setHyps] = useState(null);
    const [team, setTeam] = useState("");
    const [pin, setPin] = useState("");
    const [selectedDataset, setSelectedDataset] = useState(null);
    const [filename, setFilename] = useState("");
    const {
        codeStyle,
        errorMessage,
        setErrorMessage
    } = useContext(GlobalContext);

    // current_result
    const [data, setData] = useState(null);
    const [leaderboard, setLeaderboard] = useState({});

    const getLeaderboard = async (item) => {
        try {
            const response = await aimiSummer23Api.getScores(item);
            if ("error" in response)
                setErrorMessage(response["error"])
            return {[item]: response};
        } catch (error) {
            console.error(error);
            return {[item]: null};
        }
    };

    useEffect(() => {
        setErrorMessage("")
        Promise.all(Object.keys(DATASETS).map(getLeaderboard))
            .then(results => {
                // Combine all the results into a single object
                const newLeaderboard = results.reduce((acc, curr) => ({...acc, ...curr}), {});
                // Update the state with the new leaderboard
                setLeaderboard(newLeaderboard);
            });
    }, [])

    const resetState = () => {
        setData(null)
        setHyps(null)
        setTeam("")
        setPin("")
        setWaitForTeam(false)
        setWaitForResult(false)
        setFilename("")
    }
    const handleFileUpload = async ({fileList: newFileList}, dataset) => {
        resetState()
        setWaitForTeam(true)

        if (newFileList.length > 0) {
            let file = newFileList[0]
            let hyps = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);

            })
            hyps = atob(hyps.split(',')[1]).split("\n")

            // remove trailing blank line if present
            if (typeof hyps[hyps.length - 1] === 'string' && hyps[hyps.length - 1] === '') {
                hyps.pop();
            }

            if (hyps.length !== DATASETS[dataset]) {
                message.error(`Num of lines mismatch:  ${hyps.length} (your file) vs ${DATASETS[dataset]} (reference file) !`);
                resetState()
                return false
            }
            setHyps(hyps)
            setFilename(file["name"])
        }
    }
    const resetResults = async () => {
        resetState()
    }
    const submitResults = async (dataset) => {
        setWaitForResult(true)
        setWaitForTeam(false)

        if (!(team) || team === "") {
            message.error("No team provided")
            setWaitForResult(false)
            return
        }

        if (!(pin) || pin === "") {
            message.error("No pin provided")
            setWaitForResult(false)
            return
        }

        if (!Array.isArray(hyps) || hyps.length === 0) {
            message.error("No prediction provided")
            setWaitForResult(false)
            return
        }

        let ret = await aimiSummer23Api.computeScores(hyps, dataset, pin, team)

        if (!(typeof ret === 'object' && !Array.isArray(ret))) {
            resetState()
            openNotificationWithIcon("error", "Error happened in the scoring, please try again later")
            return
        }

        if ('error' in ret) {
            resetState()
            openNotificationWithIcon("error", ret["error"])
            return
        }
        ret["key"] = 1
        setData([ret])
        setHyps(null)
        setTeam("")
        setPin("")
        setWaitForTeam(false)
        setWaitForResult(false)
        setFilename("")
        openNotificationWithIcon("success", "Result successfully sent. Reload the page to refresh leaderboards.")

    }
    if (errorMessage) {
        return (
            <Alert
                message="An error has been caught"
                description={`Sorry. We encountered the following error: ${errorMessage}`}
                type="error"
                showIcon
            />
        );
    }

    return (
        <div style={{width: 1000}}>
            <Space direction={"vertical"} size={20}>
                <Title level={3}>1. Introduction</Title>
                <Paragraph>
                    <b>The Problem</b>: Given a chest X-ray, our goal in this project is to predict the distance from an
                    endotracheal tube to the carina. This is an important clinical task - endotracheal tubes that are
                    positioned too far (>5cm) above the carina will not work effectively.
                </Paragraph>
                <Paragraph>
                    <b>Distance Categorization :</b> Train a model to determine whether the position of a tube is
                    abnormal (>5.0 cm) or normal (≤ 5.0 cm).
                    <br/><br/>
                    <Paragraph>

                        <Alert
                            message="Please Read"
                            description={<>In this challenge, the <b>positive</b> class is "abnormal", i.e. (>5.0 cm)
                            </>}
                            type="warning"
                            showIcon
                        /></Paragraph>
                    <b>Distance Prediction:</b> Train a model that predicts the distance of the endotracheal tube from
                    the carina in centimeters.
                </Paragraph>
                <Title level={3}>2. Metrics</Title>

                <Paragraph>
                    <b>Distance Categorization :</b> We will measure AUROC, which is a metric commonly used in
                    healthcare
                    tasks. See this blog for a good explanation of AUROC: <Link
                    href={"https://glassboxmedicine.com/2019/02/23/measuring-performance-auc-auroc"}>https://glassboxmedicine.com/2019/02/23/measuring-performance-auc-auroc/</Link><br/>
                    <b>Distance Prediction:</b> We will measure standard regression errors between the
                    predicted distances and the true distances.
                </Paragraph>

                <Title level={3}>3. How to</Title>
                To submit your model predictions, please use Section 4 the following way:
                <Paragraph>
                    <b>How to:</b>
                    <ol>
                        <li>
                            Select the task you want to make the submission for (Distance Categorization or Distance
                            Prediction).
                        </li>

                        <li>
                            Upload your predictions (csv) with this exact structure (the image_path can be in an
                            arbitrary order).<br/><br/>
                            <Space direction={"horizontal"}>
                                <div>
                                    <center><b>Distance Categorization</b></center>
                                    <table className="dataframe" border="1">
                                        <thead>
                                        <tr style={{textAlign: "right"}}>
                                            <th></th>
                                            <th>image_path</th>
                                            <th>pred</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <th>0</th>
                                            <td>mimic-test/10299/50020/80298.jpg</td>
                                            <td>0.118713</td>
                                        </tr>
                                        <tr>
                                            <th>1</th>
                                            <td>mimic-test/10107/50200/80354.jpg</td>
                                            <td>0.036130</td>
                                        </tr>
                                        <tr>
                                            <th>2</th>
                                            <td>mimic-test/10355/50247/80017.jpg</td>
                                            <td>0.269714</td>
                                        </tr>
                                        <tr>
                                            <th>3</th>
                                            <td>mimic-test/10273/50396/80309.jpg</td>
                                            <td>0.210273</td>
                                        </tr>
                                        <tr>
                                            <th>4</th>
                                            <td>mimic-test/10382/50369/80479.jpg</td>
                                            <td>0.563101</td>
                                        </tr>
                                        ...
                                        <tr>
                                            <th>495</th>
                                            <td>mimic-test/10261/50427/80048.jpg</td>
                                            <td>0.054414</td>
                                        </tr>
                                        <tr>
                                            <th>496</th>
                                            <td>mimic-test/10270/50193/80498.jpg</td>
                                            <td>0.455810</td>
                                        </tr>
                                        <tr>
                                            <th>497</th>
                                            <td>mimic-test/10046/50179/80009.jpg</td>
                                            <td>0.421579</td>
                                        </tr>
                                        <tr>
                                            <th>498</th>
                                            <td>mimic-test/10046/50076/80064.jpg</td>
                                            <td>0.484923</td>
                                        </tr>
                                        <tr>
                                            <th>499</th>
                                            <td>mimic-test/10351/50017/80301.jpg</td>
                                            <td>0.163932</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <p>500 rows × 2 columns</p>
                                </div>
                                <Text style={{margin: 50}}>Or</Text>
                                <div>
                                    <center><b>Distance Prediction</b></center>
                                    <table className="dataframe" border="1">
                                        <thead>
                                        <tr style={{textAlign: "right"}}>
                                            <th></th>
                                            <th>image_path</th>
                                            <th>tube_distance</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <th>0</th>
                                            <td>mimic-test/10299/50020/80298.jpg</td>
                                            <td align={"right"}>7.0</td>
                                        </tr>
                                        <tr>
                                            <th>1</th>
                                            <td>mimic-test/10107/50200/80354.jpg</td>
                                            <td align={"right"}>4.5</td>
                                        </tr>
                                        <tr>
                                            <th>2</th>
                                            <td align={"right"}>mimic-test/10355/50247/80017.jpg</td>
                                            <td align={"right"}>0.5</td>
                                        </tr>
                                        <tr>
                                            <th>3</th>
                                            <td align={"right"}>mimic-test/10273/50396/80309.jpg</td>
                                            <td align={"right"}>1.1</td>
                                        </tr>
                                        <tr>
                                            <th>4</th>
                                            <td align={"right"}>mimic-test/10382/50369/80479.jpg</td>
                                            <td align={"right"}>5.4</td>
                                        </tr>
                                        ...
                                        <tr>
                                            <th>495</th>
                                            <td align={"right"}>mimic-test/10261/50427/80048.jpg</td>
                                            <td align={"right"}>2.0</td>
                                        </tr>
                                        <tr>
                                            <th>496</th>
                                            <td align={"right"}>mimic-test/10270/50193/80498.jpg</td>
                                            <td align={"right"}>0.4</td>
                                        </tr>
                                        <tr>
                                            <th>497</th>
                                            <td align={"right"}>mimic-test/10046/50179/80009.jpg</td>
                                            <td align={"right"}>0.6</td>
                                        </tr>
                                        <tr>
                                            <th>498</th>
                                            <td align={"right"}>mimic-test/10046/50076/80064.jpg</td>
                                            <td align={"right"}>2.3</td>
                                        </tr>
                                        <tr>
                                            <th>499</th>
                                            <td align={"right"}>mimic-test/10351/50017/80301.jpg</td>
                                            <td align={"right"}>6.8</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <p>500 rows × 2 columns</p>
                                </div>

                            </Space>
                        </li>
                        <li>
                            Enter your team name with a pin code (such as 1234). If this is your first submission, your
                            team name will be
                            created. The pin code ensures that only you team can submit for your team name, please
                            remember it.
                        </li>
                        <li>
                            Click submit and wait for the success message stating that your submission has been
                            recorded.
                        </li>
                    </ol>

                    Here is the code used to evaluate Distance Predictions:
                    <SyntaxHighlighter customStyle={{textAlign: "left"}} language="python" style={codeStyle}>
                        {code_process_distance_pred}
                    </SyntaxHighlighter>
                    And Distance Categorization:
                    <SyntaxHighlighter customStyle={{textAlign: "left"}} language="python" style={codeStyle}>
                        {code_process_distance_cat}
                    </SyntaxHighlighter>


                </Paragraph>

                <Title level={3}>4. Submit</Title>

                <LeaderboardDragger
                    handleFileUpload={handleFileUpload}
                    loading={waitForResult || waitForTeam}
                    hyps={hyps}
                    setTeam={setTeam}
                    setPin={setPin}
                    pin={pin}
                    filename={filename}
                    submitResults={submitResults}
                    resetResults={resetResults}
                    datasets={Object.keys(DATASETS)}
                    selectedDataset={selectedDataset}
                    setSelectedDataset={setSelectedDataset}
                />

                {waitForResult &&
                    <div style={{padding: 50}}>
                        <Spin tip="Computing scores, this could take a few minutes...">
                            <div className="content"/>
                        </Spin>
                    </div>
                }
                {data && selectedDataset &&
                    <div>
                        <Divider orientation="left">Submission</Divider>
                        <Table columns={columnsSingle[selectedDataset]} dataSource={data}/>
                    </div>
                }

                <Title level={3}>4. Retrieve my submissions</Title>
                <Retrieve/>

                <Title level={3}>5. Leaderboards</Title>


                {[
                    ["distance-categorization", "Distance Categorization", header_categorization],
                    ["distance-prediction", "Distance Prediction", header_prediction],
                ].map((dataset) =>
                    <>
                        <Title level={5}>{dataset[1]} ({DATASETS[dataset[0]] - 1} samples)</Title>
                        <div>
                            <Divider orientation="left">Leaderboard</Divider>
                            <Table columns={dataset[2]}
                                   dataSource={leaderboard[dataset[0]]}
                                   loading={(Object.keys(leaderboard).length === 0)}
                            />
                        </div>

                    </>
                )}


            </Space>
        </div>
    )

}

export default Leaderboard

