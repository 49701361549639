import {Alert, Button, Form, Input, Typography} from 'antd';
import React from 'react';

const {Paragraph} = Typography;

const LoginPassword = ({onFinish, onFinishFailed, preamble, epilogue}) => {
    return (
        <Form
            name="basic"
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >

            {preamble &&
                <Paragraph>
                    <Alert
                        message="Informational Notes"
                        description={preamble}
                        type="info"
                        showIcon
                    />
                </Paragraph>
            }

            <Form.Item
                label="Username"
                name="username"
                rules={[
                    {
                        required: true,
                        message: 'Please input your username!',
                    },
                ]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                label="Password"
                name="password"
                rules={[
                    {
                        required: true,
                        message: 'Please input your password!',
                    },
                ]}
            >
                <Input.Password/>
            </Form.Item>

            <Paragraph>
                {epilogue}
            </Paragraph>

            <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
            >
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};
export default LoginPassword;