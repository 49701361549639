import React, {useContext} from 'react'
import {Alert, Anchor, Button, List, PageHeader, Popover, Row, Space, Table, Tag, Typography} from 'antd';
import "./style/style.css"
import SyntaxHighlighter from 'react-syntax-highlighter';
import GlobalContext from "contexts/GlobalContext";
import {
    CaretRightOutlined,
    FilePdfOutlined,
    FileSearchOutlined,
    GithubOutlined,
    WindowsOutlined
} from "@ant-design/icons";

import {Link} from "react-router-dom";

const {Paragraph, Title, Text} = Typography;


const MimicCxr = () => {
    const {codeStyle, VilmedicTag} = useContext(GlobalContext);

    const Content = ({children, extraContent}) => (
        <Row key={"rowContent"}>
            <div key="children" style={{flex: 1,}}>{children}</div>
            <div key="extraContent" className="image">{extraContent}</div>
        </Row>
    );

    const dataSourceMimicCXR = [
        {
            key: 'mimic1',
            name: 'Chest X-rays',
            num_im: 322981,
            num: 189561,
            tr: "185816",
            val: "1521",
            te: "2224",
        },
    ];

    const columnsMimicCXR = [{
        title: 'Modality/Anatomy', dataIndex: 'name', key: 'name',
    }, {
        title: 'Number of images', dataIndex: 'num_im', key: 'num',
    }, {
        title: 'Number of reports', dataIndex: 'num', key: 'num',
    }, {
        title: 'Train', dataIndex: 'tr', key: 'tr',
    }, {
        title: 'Validation', dataIndex: 'val', key: 'val',
    }, {
        title: 'Test', dataIndex: 'te', key: 'te',
    },];


    const bibtex_entry = '@article{johnson2019mimic,\n' +
        '  title={MIMIC-CXR, a de-identified publicly available database of chest radiographs with free-text reports},\n' +
        '  author={Johnson, Alistair EW and Pollard, Tom J and Berkowitz, Seth J and Greenbaum, Nathaniel R and Lungren, Matthew P and Deng, Chih-ying and Mark, Roger G and Horng, Steven},\n' +
        '  journal={Scientific data},\n' +
        '  volume={6},\n' +
        '  number={1},\n' +
        '  pages={317},\n' +
        '  year={2019},\n' +
        '  publisher={Nature Publishing Group UK London}\n' +
        '}'

    return (
        <div style={{display: 'flex'}}>
            <div style={{width: 900}} id={"docPageContent"}>
                <Space direction={"vertical"}>
                    <PageHeader
                        title="MIMIC-CXR"
                        className="site-page-header"
                        subTitle=""
                        tags={[<Tag color="blue">RRG</Tag>,
                            <Tag color="orange">Physionet Access Required</Tag>]}
                        extra={[
                            <Button key="b3" icon={<FilePdfOutlined/>}
                                    href={"https://physionet.org/content/mimic-cxr/2.0.0/"}>Website</Button>,
                            <Button key="b1" type="primary">
                                Download
                            </Button>
                        ]}
                    >
                        <Content
                            extraContent={""}
                        >
                            <>
                                <Paragraph>
                                    <Table dataSource={dataSourceMimicCXR} columns={columnsMimicCXR}
                                           pagination={false}/>

                                </Paragraph>
                                <div key={"bottom-links"}>
                                    <Link to="/quickstart/" className="example-link">
                                        <CaretRightOutlined className="example-link-icon"/>
                                        {"Download images"}
                                    </Link>
                                    <Popover content={
                                        <SyntaxHighlighter language="bash" style={codeStyle}>
                                            {bibtex_entry}
                                        </SyntaxHighlighter>
                                    } title="BibTeX">
                                        <Link to="#" className="example-link">
                                            <FileSearchOutlined className="example-link-icon"/>
                                            {"BibTeX"}
                                        </Link>
                                    </Popover>

                                </div>
                            </>
                        </Content>
                    </PageHeader>

                </Space>
            </div>
            <div style={{marginLeft: '10px'}}>
                <Anchor>
                    <Link href="src/components/Layout/Pages/Datasets#anchor-model" title="Models"/>
                    <Link href="src/components/Layout/Pages/Datasets#anchor-blocks" title="Blocks"/>
                    <Link href="src/components/Layout/Pages/Datasets#anchor-configs" title="Configs"/>
                </Anchor>
            </div>
        </div>
    )
}

export default MimicCxr
