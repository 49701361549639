import React, {useContext, useState} from 'react'
import "./style/style.css"
import GlobalContext from "contexts/GlobalContext";
import {Alert, Button, message, Space, Steps, Table, Tooltip, Typography} from 'antd';
import DraggableTable from "../DraggableTable";
import Modal from "antd/es/modal/Modal";
import {QuestionCircleOutlined, SettingOutlined} from "@ant-design/icons";
import SyntaxHighlighter from "react-syntax-highlighter";

const {Step} = Steps;


const {Title, Paragraph, Text, Link} = Typography;


const RRG = () => {
    const {codeStyle} = useContext(GlobalContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [configSteps, setConfigSteps] = useState([]);
    const [inConfigCurrent, setInConfigCurrent] = useState(0);

    const next = () => {
        setInConfigCurrent(inConfigCurrent + 1);
    };

    const prev = () => {
        setInConfigCurrent(inConfigCurrent - 1);
    };

    const showConfig = (record) => {
        console.log(record)
        let key = record.key
        let train = ""
        let test = ""
        if (key === "mimic-cxr-bert-small") {
            train = 'python bin/train.py config/RRG/baseline-mimic.yml \\\n' +
                '    dataset.seq.processing=ifcc_clean_report \\\n' +
                '    dataset.seq.root=data/RRG/mimic-cxr/impression/ \\\n' +
                '    dataset.seq.file=impression.tok \\\n' +
                '    dataset.seq.tokenizer_max_len=60 \\\n' +
                '    dataset.image.root=data/RRG/mimic-cxr/impression/ \\\n' +
                '    dataset.image.multi_image=3 \\\n' +
                '    model.cnn.backbone=densenet121 \\\n' +
                '    model.cnn.visual_projection.in_features=1024 \\\n' +
                '    model.cnn.visual_projection.out_features=768 \\\n' +
                '    trainor.batch_size=16 \\\n' +
                '    trainor.grad_accu=8 \\\n' +
                '    trainor.optim_params.lr=0.0003 \\\n' +
                '    trainor.optimizer=Adam \\\n' +
                '    trainor.early_stop_metric=bertscore \\\n' +
                '    trainor.early_stop=10 \\\n' +
                '    validator.batch_size=8 \\\n' +
                '    validator.beam_width=2 \\\n' +
                '    validator.metrics=\'[bertscore]\' \\\n' +
                '    validator.splits=\'[validate]\' \\\n' +
                '    ckpt_dir=ckpt\n' +
                '    name=bert_small'
            test = "python bin/ensemble.py config/RRG/baseline-mimic.yml \\\n" +
                '    dataset.seq.processing=ifcc_clean_report \\\n' +
                '    dataset.seq.root=data/RRG/mimic-cxr/impression/ \\\n' +
                '    dataset.seq.file=impression.tok \\\n' +
                '    dataset.seq.tokenizer_max_len=60 \\\n' +
                '    dataset.image.root=data/RRG/mimic-cxr/impression/ \\\n' +
                '    dataset.image.multi_image=3 \\\n' +
                "    model.cnn.backbone=densenet121 \\\n" +
                "    model.cnn.visual_projection.in_features=1024 \\\n" +
                "    model.cnn.visual_projection.out_features=768 \\\n" +
                "    ensemblor.batch_size=16 \\\n" +
                "    ensemblor.beam_width=2 \\\n" +
                "    ensemblor.metrics='[ROUGEL,chexbert,radgraph]' \\\n" +
                "    ensemblor.splits='[test]' \\\n" +
                "    ckpt_dir=ckpt \\\n" +
                "    name=bert_small"
        }
        if (key === "mimic-cxr-BioMed-RoBERTa") {
            train = "python bin/train.py config/RRG/biomed-roberta-baseline-mimic.yml \\\n" +
                "            dataset.seq.tokenizer_max_len=120 \\\n" +
                "            dataset.seq.processing=ifcc_clean_report \\\n" +
                "            trainor.batch_size=8 \\\n" +
                "            trainor.grad_accu=16 \\\n" +
                "            trainor.early_stop_metric=\"chexbert-5_micro avg_f1-score\" \\\n" +
                "            validator.batch_size=8 \\\n" +
                "            validator.metrics=[chexbert] \\\n" +
                "            name=BioMed-RoBERTa \\\n" +
                "            ckpt_dir=ckpt"
            test = "python bin/ensemble.py config/RRG/biomed-roberta-baseline-mimic.yml \\\n" +
                "            dataset.seq.tokenizer_max_len=120 \\\n" +
                "            dataset.seq.processing=ifcc_clean_report \\\n" +
                "            ensemblor.batch_size=8 \\\n" +
                "            ensemblor.metrics='[ROUGEL,chexbert,radgraph]' \\\n" +
                "            name=BioMed-RoBERTa \\\n" +
                "            ckpt_dir=ckpt"
        }
        if (key.includes("mimic-cxr-bert-small-scst")) {
            window.open('/papers/emnlp2022/', '_blank', 'noreferrer');
            return
        }


        setConfigSteps(
            [<Paragraph>
                <pre>{train}</pre>
            </Paragraph>, <Paragraph>
                <pre>{test}</pre>
            </Paragraph>],
        )


        setIsModalOpen(true);
    };

    const handleOk = () => {
        setConfigSteps([])
        setInConfigCurrent(0)
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setConfigSteps([])
        setInConfigCurrent(0)
        setIsModalOpen(false);
    };


    const expandedRowRender = (record, index, indent, expanded) => {
        let data = undefined
        if (record.key === "mimic-cxr") {
            data = [
                {
                    "key": "mimic-cxr-BioMed-RoBERTa",
                    "name": "BioMed-RoBERTa",
                    "split": "validation",
                    "rougel": "32.7",
                    "f1chexbert": "49.1",
                    "f1chexbert5": "43.1",
                    "radgraph": "",
                    "index": 0,
                },
                {
                    "key": "mimic-cxr-BioMed-RoBERTa",
                    "name": "BioMed-RoBERTa",
                    "split": "test",
                    "rougel": "22.4",
                    "f1chexbert": "44.2",
                    "f1chexbert5": "45.8",
                    "radgraph": "",
                    "index": 1,
                },
                {
                    "key": "mimic-cxr-bert-small",
                    "name": "bert-small",
                    "split": "validation",
                    "rougel": "30.4",
                    "f1chexbert": "50.6",
                    "f1chexbert5": "47.6",
                    "radgraph": "28.9",
                    "index": 2,
                },
                {
                    "key": "mimic-cxr-bert-small",
                    "name": "bert-small",
                    "split": "test",
                    "rougel": "22.5",
                    "f1chexbert": "42.6",
                    "f1chexbert5": "46.8",
                    "radgraph": "21.1",
                    "index": 3,
                },
                {
                    "key": "mimic-cxr-bert-small-scst",
                    "name": "bert-small (RadGraph SCST)",
                    "split": "validate",
                    "rougel": "30.8",
                    "f1chexbert": "53.67",
                    "f1chexbert5": "58.81",
                    "radgraph": "29.18", //its radgraph hat er
                    "bertscore": "61.78",
                    "index": 4,
                },
                {
                    "key": "mimic-cxr-bert-small-scst",
                    "name": "bert-small (RadGraph SCST)",
                    "split": "test",
                    "rougel": "26.50",
                    "f1chexbert": "56.00",
                    "f1chexbert5": "62.15",
                    "radgraph": "23.48", //its radgraph hat er
                    "bertscore": "58.52",
                    "index": 5,
                },

            ]

        } else if (record.key === "open-i") {
            data = [
                {
                    "key": "0",
                    "name": "BioMed-RoBERTa",
                    "split": "validation",
                    "rougel": "32.72",
                    "f1chexbert": "49.12",
                    "f1chexbert5": "43.10",
                    "radgraph": "",
                    "index": 0,
                },
                {
                    "key": "1",
                    "name": "BioMed-RoBERTa",
                    "split": "test",
                    "rougel": "22.45",
                    "f1chexbert": "44.23",
                    "f1chexbert5": "45.8",
                    "radgraph": "",
                    "index": 1,
                },
            ]
        }

        const columns = [

            {
                title: 'Split',
                dataIndex: 'split',
                key: 'split',
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'ROUGEL',
                dataIndex: 'rougel',
                key: 'rougel',
                sorter: (a, b) => a.rougel - b.rougel,
            },
            {
                title: 'F1-cheXbert',
                dataIndex: 'f1chexbert',
                key: 'f1chexbert',
                sorter: (a, b) => a.f1chexbert - b.f1chexbert,

            },
            {
                title: 'F1-cheXbert-5',
                dataIndex: 'f1chexbert5',
                key: 'f1chexbert5',
                sorter: (a, b) => a.f1chexbert5 - b.f1chexbert5,
            },
            {
                title: "bertscore",
                dataIndex: 'bertscore',
                key: 'bertscore',
                sorter: (a, b) => a.bertscore - b.bertscore,

            },
            {
                title: "RadGraph",
                dataIndex: 'radgraph',
                key: 'radgraph',
                sorter: (a, b) => a.radgraph - b.radgraph,

            },
            {
                title: '',
                key: 'config',
                render: (text, record, index) => <a onClick={() => showConfig(record)}>Config</a>,
            }
        ];
        return <DraggableTable
            data={data}
            columns={columns}
        />;
    };


    const columns = [

        {
            title: 'Dataset',
            dataIndex: 'dataset',
            key: 'dataset',
        },
        {
            title: 'Size',
            dataIndex: 'size',
            key: 'size',
        },
        {
            title: 'Publisher',
            dataIndex: 'publisher',
            key: 'publisher',
        },
        {
            title: 'Link',
            key: 'operation',
            render: (text, record, index) => <a>Link</a>,
        },
    ];
    const data = [
        {
            "key": "mimic-cxr",
            "dataset": "mimic-cxr",
            "size": "152,173 samples",
            "publisher": "MIT LCP",
        },
        {
            "key": "open-i",
            "dataset": "open-i",
            "size": "3,820 samples",
            "publisher": "Indiana University",
        },
    ]

    console.log(inConfigCurrent)
    return (
        <>
            <div id="rrg_solution" style={{width: 900}}>
                <Space direction={"vertical"}>
                    <Title level={2}>Radiology Report Generation</Title>
                    <Paragraph>
                        An important new application of natural language generation (NLG) is to build assistive systems
                        that take X-ray images of a patient and generate a textual report describing clinical
                        observations in the images.
                    </Paragraph>
                    <Alert
                        message="Informational Notes"
                        description="The datasets used in this section are MIMIC-CXR, PadChest and CheXpert."
                        type="info"
                        showIcon
                    />

                    <Table
                        columns={columns}
                        expandable={{
                            expandedRowRender,
                            defaultExpandedRowKeys: ['mimic-cxr'],
                        }}
                        dataSource={data}
                    />

                </Space>
            </div>
            <Modal title="Config" width={700} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>

                <Steps current={inConfigCurrent}>
                    <Step key={"1"} title={"Train & Validation"}/>
                    <Step key={"2"} title={"Test"}/>
                </Steps>
                <div className="steps-content">{configSteps[inConfigCurrent]}</div>
                <div className="steps-action">
                    {inConfigCurrent < configSteps.length - 1 && (
                        <Button type="primary" onClick={() => next()}>
                            Next
                        </Button>
                    )}
                    {inConfigCurrent > 0 && (
                        <Button style={{margin: '0 8px'}} onClick={() => prev()}>
                            Previous
                        </Button>
                    )}
                </div>
            </Modal>
        </>
    );
};

export default RRG
