import config from "services/config";


const computeScores = (hyps, dataset, pin, team) => {
    return config.miscApi.post("/bioNLP24/compute_scores", {
        hyps: hyps,
        dataset: dataset,
        pin: pin,
        team: team,
    }).then(response => {
        return response.data
    }).catch(() => {
        return {
            error: 'Backend is offline or received critical error',
        }
    })
}

const getScoresForTeam = (team) => {
    return config.miscApi.post("/bioNLP24/get_scores_for_team", {
        team: team,
    }).then(response => {
        return response.data
    }).catch(() => {
        return {
            error: 'Backend is offline or received critical error',
        }
    })
}

const getScores = (dataset) => {
    return config.miscApi.post("/bioNLP24/get_scores", {
        dataset: dataset,
    }).then(response => {
        return response.data
    }).catch(() => {
        return {
            error: 'Backend is offline or received critical error',
        }
    })
}

const exportedObject = {
    computeScores,
    getScores,
    getScoresForTeam,
}

export default exportedObject