import React from 'react'
import {Layout, Menu} from 'antd'
import {Link, useLocation} from "react-router-dom";

import {
    CaretRightOutlined,
    FilePdfOutlined,
    FileSearchOutlined,
    SettingOutlined,
    BoxPlotFilled,
    EditFilled,
    SecurityScanFilled,
    NodeIndexOutlined,
    LayoutFilled,
    BulbOutlined,
    WindowsOutlined,
    FileDoneOutlined,
} from '@ant-design/icons';

const {Sider} = Layout;

function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

const items = [
    getItem("ViLMedic", '/vilmedic', <img style={{"width": 14}} src={"/favicon/favicon-64x64.png"}
                                          alt={""}/>, [
        getItem(<Link to="/">Paper</Link>, '/', <FilePdfOutlined/>,),
        getItem(<Link to="/quickstart/">Quick start</Link>, '/quickstart/', <CaretRightOutlined/>,),
    ]),
    getItem("Documentation", '/documentation', <FileSearchOutlined/>, [
        getItem(<Link to="/documentation/installation/">Installation</Link>, '/documentation/installation/',
            <SettingOutlined/>),
        getItem(<Link to="/documentation/philosophy/">Philosophy</Link>, '/documentation/philosophy/', <BulbOutlined/>),
        getItem(<Link to="/documentation/configs/">Configs</Link>, '/documentation/configs/', <FileDoneOutlined/>),
        getItem('Solutions', '/solution/', null, [
            getItem(<Link to="/solution/rrg/">Radiology Report Generation</Link>, '/solution/rrg/', <EditFilled/>),
            getItem(<Link to="/solution/rrs/">Radiology Report Summarization</Link>, '/solution/rrs/',
                <BoxPlotFilled/>),
            getItem(<Link to="/solution/mvqa/">Visual Question Answering</Link>, '/solution/mvqa/',
                <SecurityScanFilled/>),
            getItem(<Link to="">Self-supervised learning</Link>, '/solution/ssl/', <LayoutFilled/>),
        ], 'group'),
    ]),
    getItem("Software", '/software', <WindowsOutlined/>, [
        getItem(<Link to="/software/rrg/">Radiology Report Generation</Link>, '/software/rrg/', <EditFilled/>),
        getItem(<Link to="/software/radgraph/">RadGraph</Link>, '/software/radgraph/', <NodeIndexOutlined/>),
        getItem(<Link to="/software/deid/">De-Identification</Link>, '/software/deid/', <NodeIndexOutlined/>),
    ]),
];

const SiderContent = () => {
    const location = useLocation()
    return (
        <Sider width={300} defaultCollapsed={false}>
            <Menu
                selectedKeys={[location.pathname]}
                mode="inline"
                // defaultSelectedKeys={['/']}
                openKeys={['/vilmedic', '/documentation', '/software']}
                style={{
                    height: '100%',
                    borderRight: 0,
                }}
                items={items}
            />
        </Sider>
    )
}

export default SiderContent
