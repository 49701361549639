import React, {useContext, useState} from 'react'
import {
    Space,
    Input,
    Table,
    message,
    Modal,
    Spin,
    Steps,
    Button,
    Typography,
    Alert, PageHeader, Row, Popover
} from 'antd';
import SyntaxHighlighter from "react-syntax-highlighter";
import GlobalContext from "contexts/GlobalContext";
import LoginPassword from "components/Utils/LoginPassword";
import downloadApi from "services/downloadResources";
import {ChromeOutlined, FilePdfOutlined, FileSearchOutlined, SettingOutlined} from "@ant-design/icons";
import {ModalPasswordRessourceContext} from 'components/Utils/ModalPasswordRessource';

const {Title, Paragraph, Link} = Typography;
const {Step} = Steps;

const pipinstallradgraph = `pip install radgraph`
const pipinstallf1chexbert = `pip install f1chexbert`

const coderadgraph = 'from radgraph import F1RadGraph\n' +
    'refs = ["no acute cardiopulmonary abnormality",\n' +
    '        "no evidence of acute pulmonary process moderately large size hiatal hernia"]\n' +
    '\n' +
    'hyps = ["no acute cardiopulmonary abnormality",\n' +
    '        "no evidence of acute cardiopulmonary process moderate hiatal hernia"]\n' +
    '\n' +
    'f1radgraph = F1RadGraph(reward_level="partial")\n' +
    'score, _, hypothesis_annotation_lists, reference_annotation_lists = f1radgraph(hyps=hyps,\n' +
    '                                                                               refs=refs)\n' +
    'print(score)\n' +
    '#0.7142857142857143'

const codechexbert = 'from f1chexbert import F1CheXbert\n' +
    'f1chexbert = F1CheXbert(device="cuda")\n' +
    'accuracy, accuracy_not_averaged, class_report, class_report_5 = f1chexbert(\n' +
    '    hyps=[\'Mild pleural effusions.\', \'Enlarged heart is seen.\'],\n' +
    '    refs=[\'No pleural effusions.\', \'Enlarged heart.\'])\n' +
    'print(class_report_5["micro avg"]["f1-score"])\n' +
    '#0.6666666666666666'

const Acl2023 = ({data}) => {
    const {codeStyle} = useContext(GlobalContext);
    const {modal_password_ressource, directDownloadDataset} = useContext(ModalPasswordRessourceContext);

    const Content = ({children, extraContent}) => (
        <Row key={"rowContent"}>
            <div key="children" style={{flex: 1,}}>{children}</div>
            <div key="extraContent" className="image">{extraContent}</div>
        </Row>
    );


    const functionMap = {
        directDownloadDataset,
        modal_password_ressource,
    };

    return (
        <>
            <Space direction={"vertical"}>
                <div style={{width: 900}}>
                    <PageHeader
                        title=""
                        className="site-page-header"
                        subTitle={
                            <span>
                          Toward Expanding the Scope of Radiology Report

                          Summarization to Multiple <br/> Anatomies and Modalities
                        </span>
                        }
                        extra={[
                            <Button key="b3" icon={<FilePdfOutlined/>}
                                    href={"https://aclanthology.org/2023.acl-short.41/"}>Paper</Button>,


                        ]}
                        avatar={{
                            src: 'https://cdn-icons-png.flaticon.com/512/10190/10190139.png',
                        }}
                    >

                        <Content
                            extraContent={
                                <img
                                    alt="content"
                                    width="146px"
                                    src="/images/acl23logo.jpeg"
                                />
                            }
                        >
                            <>
                                <Paragraph>
                                    <blockquote>
                                        Radiology report summarization (RRS) is a growing field aiming to generate
                                        concise
                                        summaries of radiology reports. However, current RRS research faces limitations,
                                        including experiments on private datasets and evaluation focused on chest
                                        X-rays. To
                                        address these limitations, a new dataset called MIMIC-RRS is proposed,
                                        consisting of
                                        three modalities and seven anatomies. Extensive experiments are conducted to
                                        evaluate model performance within and across these pairs, along with clinical
                                        efficacy assessment using the RadGraph metric.
                                    </blockquote>
                                </Paragraph>
                            </>
                        </Content>
                    </PageHeader>

                    <PageHeader
                        title=""
                        className="site-page-header"
                        subTitle={
                            <span>
                          Overview of the RadSum23 Shared Task on Multi-modal and Multi-anatomical <br/>
                            Radiology Report Summarization
                        </span>
                        }
                        extra={[
                            <Button key="b3" icon={<FilePdfOutlined/>}
                                    href={"https://aclanthology.org/2023.bionlp-1.45/"}>Paper</Button>,
                            <Button key="b2" icon={<ChromeOutlined/>}
                                    href={"/misc/bionlp23/sharedtask/"}>Challenge</Button>,

                        ]}
                        avatar={{
                            src: 'https://icon-library.com/images/summary-report-icon/summary-report-icon-26.jpg',
                        }}
                    >

                        <Content
                            extraContent={
                                <img
                                    alt="content"
                                    width="146px"
                                    src="/images/acl23logo.jpeg"
                                />
                            }
                        >
                            <>
                                <Paragraph>
                                    <blockquote>
                                        The field of radiology report summarization is growing, with recent systems
                                        achieving promising results. However, the lack of publicly available datasets
                                        and
                                        the focus on chest X-rays limit the ability to reproduce and compare different
                                        systems. To address these limitations, a Radiology Report Summarization (RadSum)
                                        challenge was proposed, introducing new datasets covering various modalities and
                                        anatomies, including a multimodal dataset. The challenge received 112
                                        submissions
                                        from 11 teams.
                                    </blockquote>
                                </Paragraph>
                            </>
                        </Content>
                    </PageHeader>

                    <Title level={3}>1. Data</Title>
                    {data.map((dataset, index) => {
                        return (
                            <PageHeader
                                title={dataset.title}
                                className="site-page-header"
                                subTitle=""
                                tags={dataset.tags}
                                key={index}
                                extra={[
                                    <Button key="b3" icon={<FilePdfOutlined/>}
                                            href={dataset.website_entry} target="_blank">Website</Button>,
                                    <Button
                                        onClick={() => functionMap[dataset.func_name](dataset.resource_name, dataset.resource_source)}
                                        key="b1"
                                        type="primary">
                                        Download
                                    </Button>
                                ]}
                            >
                                <Content
                                    extraContent={""}
                                >
                                    <>
                                        <Paragraph>
                                            {dataset.content}
                                        </Paragraph>
                                        <div key={"bottom-links"}>
                                            <Popover content={
                                                <SyntaxHighlighter language="bash" style={codeStyle}>
                                                    {dataset.bibtex_entry}
                                                </SyntaxHighlighter>
                                            } title="BibTeX">
                                                <Link to="#" className="example-link">
                                                    <FileSearchOutlined className="example-link-icon"/>
                                                    {"BibTeX"}
                                                </Link>
                                            </Popover>

                                        </div>
                                    </>
                                </Content>
                            </PageHeader>)
                    })
                    }
                    <p></p>
                    <Title level={3}>2. MIMIC-III preprocessing</Title>
                    <Paragraph>
                        For each report, we extract the findings and impression section. However, the findings section
                        is not
                        always clearly labeled as “findings". With the help
                        of a board-certified radiologist, we identify alternate section headers that reference findings
                        for each
                        modality-anatomy pair. As an example, for CT
                        head, findings may be referenced in reports with the
                        section headings “non-contrast head ct", “ct head",
                        “ct head without contrast", “ct head without iv contrast", “head ct", “head ct without iv
                        contrast",
                        or “cta head". We identify 537 candidate section
                        headers that reference findings across our dataset.
                        We also discarded reports where multiple studies
                        are pooled in the same radiology report, leading
                        to multiple intricate observations in the impression section.
                    </Paragraph>
                    <Paragraph>
                        The preprocessing script can be download using <Link
                        href={"/public_resources/preprocess_mimic_iii.ipynb"} target="_blank">
                        link
                    </Link>.
                    </Paragraph>
                    <Title level={3}>3. Metrics</Title>

                    <Title level={4}>3.1 F1RadGraph</Title>
                    The F1RadGraph score used in this challenge is described as RG_ER from <Link
                    href={"https://aclanthology.org/2022.findings-emnlp.319"}> this paper</Link>.
                    <SyntaxHighlighter language="bash" style={codeStyle}>
                        {pipinstallradgraph}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter customStyle={{textAlign: "left"}} language="python" style={codeStyle}>
                        {coderadgraph}
                    </SyntaxHighlighter>
                    <Title level={4}>3.2 F1CheXbert</Title>
                    The F1CheXbert used in this challenge is over 5 observations (Cardiomegaly", "Edema",
                    "Consolidation", "Atelectasis", "Pleural Effusion), and micro averaged.
                    <SyntaxHighlighter language="bash" style={codeStyle}>
                        {pipinstallf1chexbert}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter customStyle={{textAlign: "left"}} language="python" style={codeStyle}>
                        {codechexbert}
                    </SyntaxHighlighter>


                    <Title level={3}>4. References</Title>

                    <SyntaxHighlighter language="bash" style={codeStyle}>
                        {'@inproceedings{chen-etal-2023-toward,\n' +
                            '    title = "Toward Expanding the Scope of Radiology Report Summarization to Multiple Anatomies and Modalities",\n' +
                            '    author = "Chen, Zhihong  and\n' +
                            '      Varma, Maya  and\n' +
                            '      Wan, Xiang  and\n' +
                            '      Langlotz, Curtis  and\n' +
                            '      Delbrouck, Jean-Benoit",\n' +
                            '    booktitle = "Proceedings of the 61st Annual Meeting of the Association for Computational Linguistics (Volume 2: Short Papers)",\n' +
                            '    month = jul,\n' +
                            '    year = "2023",\n' +
                            '    address = "Toronto, Canada",\n' +
                            '    publisher = "Association for Computational Linguistics",\n' +
                            '    url = "https://aclanthology.org/2023.acl-short.41",\n' +
                            '    doi = "10.18653/v1/2023.acl-short.41",\n' +
                            '    pages = "469--484",\n' +
                            '}'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter language="bash" style={codeStyle}>
                        {'@inproceedings{delbrouck-etal-2023-overview,\n' +
                            '    title = "Overview of the {R}ad{S}um23 Shared Task on Multi-modal and Multi-anatomical Radiology Report Summarization",\n' +
                            '    author = "Delbrouck, Jean-Benoit  and\n' +
                            '      Varma, Maya  and\n' +
                            '      Chambon, Pierre  and\n' +
                            '      Langlotz, Curtis",\n' +
                            '    booktitle = "The 22nd Workshop on Biomedical Natural Language Processing and BioNLP Shared Tasks",\n' +
                            '    month = jul,\n' +
                            '    year = "2023",\n' +
                            '    address = "Toronto, Canada",\n' +
                            '    publisher = "Association for Computational Linguistics",\n' +
                            '    url = "https://aclanthology.org/2023.bionlp-1.45",\n' +
                            '    doi = "10.18653/v1/2023.bionlp-1.45",\n' +
                            '    pages = "478--482",\n' +
                            '}'}
                    </SyntaxHighlighter>
                </div>
            </Space>
        </>
    );
};

export default Acl2023
