import React, {useEffect, useState} from 'react'
import AnnotateReport from "./annotateReport";
import {Button, Input, message, notification, Select, Space, Spin} from "antd";
import {UserOutlined} from "@ant-design/icons";
import radGPTApi from "services/misc/annotationsRadGpt";


async function loadJSON(f) {
    const response = await fetch(f); // Fetch the JSON file
    return await response.json()
}

const emptyAnnotationDict = (obj) => {
    if (Array.isArray(obj)) {
        return obj.map(emptyAnnotationDict);
    }
    if (typeof obj === 'object' && obj !== null) {
        return Object.entries(obj).reduce((acc, [key, value]) => {
            acc[key] = (['concept', 'report'].includes(key)) ? value : emptyAnnotationDict(value);
            return acc;
        }, {});
    }
    // remove checkbox with -1 (we dont have checkbox anymore)
    return [null, -1];
};

const AnnotateReportContainer = () => {

    const [reports, setReports] = useState(null);
    const [annotations, setAnnotations] = useState(null);
    const [reportNumber, setReportNumber] = useState(0);
    const [user, setUser] = useState(null);
    const [study, setSudy] = useState("");


    useEffect(() => {
        resetUser()
    }, []);


    function resetUser() {
        setUser(null)
        setAnnotations(null)
        setSudy("")
        setReports(null)
        setReportNumber(0)
    }


    async function setRatingValue(rating, reportNumber, conceptIndex, questionIndex, isQuestionRating, isReportLevel, isCheckBox) {
        console.log(rating)

        let newAnnotation = annotations.slice()
        let index = (isCheckBox) ? 1 : 0

        if (questionIndex === null)
            newAnnotation[reportNumber]["concepts"][conceptIndex]["explanation"][index] = rating
        else {
            if (isReportLevel) {
                if (isQuestionRating)
                    newAnnotation[reportNumber]["qa"][questionIndex]["question"][index] = rating
                else
                    newAnnotation[reportNumber]["qa"][questionIndex]["answer"][index] = rating
            } else {
                if (isQuestionRating)
                    newAnnotation[reportNumber]["concepts"][conceptIndex]["qa"][questionIndex]["question"][index] = rating
                else
                    newAnnotation[reportNumber]["concepts"][conceptIndex]["qa"][questionIndex]["answer"][index] = rating
            }

        }
        setAnnotations(newAnnotation)
        let ret = await radGPTApi.saveAnnotations(user, study, newAnnotation)
        if ("save" in ret && ret["save"])
            notification["success"]({
                message: 'Saved',
                duration: 2,
                placement: 'bottomRight'
            });
        else
            notification["error"]({
                message: 'Save has failed for some reason! Refresh the page and reload your session.',
                duration: 5,
                placement: 'bottomRight'
            });
    }


    const selectStudyOnChange = (value) => {
        setSudy(value)
    };

    async function loadUser() {
        if (!(user)) {
            message.error("Write username")
            return
        }
        if (!(study)) {
            message.error("Select study")
            return
        }

        console.log("/annot_study/" + study + ".json")
        let json = await loadJSON("/annot_study/" + study + ".json")
        console.log(json)
        setReports(json)

        let ret = await radGPTApi.getAnnotations(user, study)
        let annot = ret["annotations"]
        console.log(annot)
        if (annot === null) {
            message.warning("This username has no annotations, beginning from scratch")
            setAnnotations(emptyAnnotationDict(json))
        } else
            setAnnotations(annot)
    }


    if (reports !== null && annotations !== null && user)
        return (
            <AnnotateReport reports={reports}
                            reportNumber={reportNumber}
                            setReportNumber={setReportNumber}
                            setRatingValue={setRatingValue}
                            annotations={annotations}
                            user={user}
                            resetUser={resetUser}
            />
        )


    return (
        <>
            <Space>
                <Select
                    defaultValue=""
                    style={{
                        width: 250,
                    }}
                    onChange={selectStudyOnChange}
                    options={[
                        {
                            value: 'ann_final_v1',
                            label: 'rater-training-final',
                        },
                        {
                            value: 'ann6',
                            label: 'rater-training-2',
                        },
                        {
                            value: 'ann4',
                            label: 'rater-training',
                        },
                        {
                            value: 'ann5',
                            label: 'rater-training-modified',
                        },
                    ]}
                />
                <Input placeholder="username" onChange={(e) => setUser(e.target.value)}
                       prefix={<UserOutlined/>}
                       style={{width: 200}}/><br/>
                <Button type="primary" onClick={loadUser}>Submit</Button>
            </Space>
        </>
    )


}

export default AnnotateReportContainer