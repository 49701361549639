import React, {useContext, useEffect} from 'react'
import ImageDatasets from "./ImageDatasets"
import {Alert, Table, Tag} from 'antd';
import "./style/style.css"
import SyntaxHighlighter from 'react-syntax-highlighter';
import GlobalContext from "contexts/GlobalContext";
import {ModalPasswordRessourceProvider} from 'components/Utils/ModalPasswordRessource';

const ImageDatasetsContainer = () => {
    const {codeStyle} = useContext(GlobalContext);

    useEffect(() => {
    }, [])

    const data = [
        {
            content: (
                <Table
                    dataSource={[
                        {
                            key: 'mimic-cxr-images-512',
                            name: 'Chest X-rays',
                            num_im: "371,920",
                        },
                    ]}
                    columns={[
                        {
                            title: 'Modality/Anatomy',
                            dataIndex: 'name',
                            key: 'name',
                        },
                        {
                            title: 'Number of images',
                            dataIndex: 'num_im',
                            key: 'num',
                        }
                    ]}
                    pagination={false}
                />
            ),
            processing: (
                <SyntaxHighlighter
                    customStyle={{textAlign: "left"}}
                    language="python"
                    style={codeStyle}>
                    {'from torchvision import transforms\n' +
                        'from PIL import Image, ImageFile\n' +
                        'ImageFile.LOAD_TRUNCATED_IMAGES = True\n' +
                        'transform = transforms.Compose([transforms.Resize(512)])\n' +
                        'transform((Image.open(in_image)).convert(\'RGB\')).save(out_image, quality=100, subsampling=0)'}
                </SyntaxHighlighter>
            ),
            title: 'MIMIC-CXR',
            bibtex_entry: '@article{johnson2019mimic,\n' +
                '  title={MIMIC-CXR, a de-identified publicly available database of chest radiographs with free-text reports},\n' +
                '  author={Johnson, Alistair EW and Pollard, Tom J and Berkowitz, Seth J and Greenbaum, Nathaniel R and Lungren, Matthew P and Deng, Chih-ying and Mark, Roger G and Horng, Steven},\n' +
                '  journal={Scientific data},\n' +
                '  volume={6},\n' +
                '  number={1},\n' +
                '  pages={317},\n' +
                '  year={2019},\n' +
                '  publisher={Nature Publishing Group UK London}\n' +
                '}',
            website_entry: 'https://physionet.org/content/mimic-cxr/2.0.0/',
            resource_name: 'mimic-cxr-images-512',
            func_name: 'modal_password_ressource',
            resource_source: 'physionet',
            tags: [<Tag color="blue">X-ray</Tag>, <Tag color="orange">Physionet Access</Tag>]
        },
        {
            content: (
                <Table
                    dataSource={[
                        {
                            key: 'CheXpert-v1.0-512',
                            name: 'Chest X-rays',
                            num_im: "224,316",
                        },
                    ]}
                    columns={[
                        {
                            title: 'Modality/Anatomy',
                            dataIndex: 'name',
                            key: 'name',
                        },
                        {
                            title: 'Number of images',
                            dataIndex: 'num_im',
                            key: 'num',
                        }
                    ]}
                    pagination={false}
                />
            ),
            processing: (
                <SyntaxHighlighter
                    customStyle={{textAlign: "left"}}
                    language="python"
                    style={codeStyle}>
                    {'from torchvision import transforms\n' +
                        'from PIL import Image, ImageFile\n' +
                        'ImageFile.LOAD_TRUNCATED_IMAGES = True\n' +
                        'transform = transforms.Compose([transforms.Resize(512)])\n' +
                        'transform((Image.open(in_image)).convert(\'RGB\')).save(out_image, quality=100, subsampling=0)'}
                </SyntaxHighlighter>
            ),
            title: 'Chexpert',
            bibtex_entry: '@inproceedings{irvin2019chexpert,\n' +
                '  title={Chexpert: A large chest radiograph dataset with uncertainty labels and expert comparison},\n' +
                '  author={Irvin, Jeremy and Rajpurkar, Pranav and Ko, Michael and Yu, Yifan and Ciurea-Ilcus, Silviana and Chute, Chris and Marklund, Henrik and Haghgoo, Behzad and Ball, Robyn and Shpanskaya, Katie and others},\n' +
                '  booktitle={Proceedings of the AAAI conference on artificial intelligence},\n' +
                '  volume={33},\n' +
                '  number={01},\n' +
                '  pages={590--597},\n' +
                '  year={2019}\n' +
                '}',
            website_entry: 'https://stanfordmlgroup.github.io/competitions/chexpert/',
            resource_name: 'CheXpert-v1.0-512',
            func_name: 'modal_password_ressource',
            resource_source: 'stanford',
            tags: [<Tag color="blue">X-ray</Tag>, <Tag color="red">Closed Access (Stanford)</Tag>]
        },
        {
            content: (
                <Table
                    dataSource={[
                        {
                            key: 'padchest-images-512',
                            name: 'Chest X-rays',
                            num_im: "160,000",
                        },
                    ]}
                    columns={[
                        {
                            title: 'Modality/Anatomy',
                            dataIndex: 'name',
                            key: 'name',
                        },
                        {
                            title: 'Number of images',
                            dataIndex: 'num_im',
                            key: 'num',
                        }
                    ]}
                    pagination={false}
                />
            ),
            processing: (
                <SyntaxHighlighter
                    customStyle={{textAlign: "left"}}
                    language="python"
                    style={codeStyle}>
                    {'from torchvision import transforms\n' +
                        'from PIL import Image, ImageFile\n' +
                        'ImageFile.LOAD_TRUNCATED_IMAGES = True\n' +
                        'transform = transforms.Compose([transforms.Resize(512)])\n' +
                        'transform((Image.open(in_image)).convert(\'RGB\')).save(out_image, quality=100, subsampling=0)'}
                </SyntaxHighlighter>
            ),
            title: 'PadChest',
            bibtex_entry: '@article{bustos2020padchest,\n' +
                '  title={Padchest: A large chest x-ray image dataset with multi-label annotated reports},\n' +
                '  author={Bustos, Aurelia and Pertusa, Antonio and Salinas, Jose-Maria and De La Iglesia-Vaya, Maria},\n' +
                '  journal={Medical image analysis},\n' +
                '  volume={66},\n' +
                '  pages={101797},\n' +
                '  year={2020},\n' +
                '  publisher={Elsevier}\n' +
                '}',
            website_entry: 'https://bimcv.cipf.es/bimcv-projects/padchest/',
            resource_name: 'padchest-images-512',
            func_name: 'modal_password_ressource',
            resource_source: 'vilmedic',
            tags: [<Tag color="blue">X-ray</Tag>, <Tag color="red">Closed Access</Tag>]
        },
        {
            content: (
                <>
                    <Table
                        dataSource={[
                            {
                                key: 'NIH-Google-ChestX-ray14',
                                name: 'Chest X-rays',
                                num_im: "112,121",
                            },
                        ]}
                        columns={[
                            {
                                title: 'Modality/Anatomy',
                                dataIndex: 'name',
                                key: 'name',
                            },
                            {
                                title: 'Number of images',
                                dataIndex: 'num_im',
                                key: 'num',
                            }
                        ]}
                        pagination={false}
                    />
                    <Alert
                        message="Informational Notes"
                        description={
                            <>
                                This dataset comprises the images of the RSNA Pneumonia Detection challenge and the
                                Long-Tailed Classification of Thorax Diseases paper. Original labels, challenge labels
                                and long-tail labels are provided in this resource.
                            </>}
                        type="info"
                        showIcon
                    />
                </>
            ),
            processing: (
                <SyntaxHighlighter
                    customStyle={{textAlign: "left"}}
                    language="python"
                    style={codeStyle}>
                    {'from torchvision import transforms\n' +
                        'from PIL import Image, ImageFile\n' +
                        'ImageFile.LOAD_TRUNCATED_IMAGES = True\n' +
                        'transform = transforms.Compose([transforms.Resize(512)])\n' +
                        'transform((Image.open(in_image)).convert(\'RGB\')).save(out_image, quality=100, subsampling=0)'}
                </SyntaxHighlighter>
            ),
            title: 'NIH-Google-ChestX-ray14',
            bibtex_entry: '@inproceedings{wang2017chestx,\n' +
                '  title={Chestx-ray8: Hospital-scale chest x-ray database and benchmarks on weakly-supervised classification and localization of common thorax diseases},\n' +
                '  author={Wang, Xiaosong and Peng, Yifan and Lu, Le and Lu, Zhiyong and Bagheri, Mohammadhadi and Summers, Ronald M},\n' +
                '  booktitle={Proceedings of the IEEE conference on computer vision and pattern recognition},\n' +
                '  pages={2097--2106},\n' +
                '  year={2017}\n' +
                '}\n' +
                'and\n' +
                '@inproceedings{holste2022long,\n' +
                '  title={Long-tailed classification of thorax diseases on chest x-ray: A new benchmark study},\n' +
                '  author={Holste, Gregory and Wang, Song and Jiang, Ziyu and Shen, Thomas C and Shih, George and Summers, Ronald M and Peng, Yifan and Wang, Zhangyang},\n' +
                '  booktitle={MICCAI Workshop on Data Augmentation, Labelling, and Imperfections},\n' +
                '  pages={22--32},\n' +
                '  year={2022},\n' +
                '  organization={Springer}\n' +
                '}',
            website_entry: 'https://cloud.google.com/healthcare-api/docs/resources/public-datasets/nih-chest',
            resource_name: 'NIH-Google-ChestX-ray14-512',
            func_name: 'directDownloadDataset',
            tags: [<Tag color="blue">X-ray</Tag>, <Tag color="green">Labels</Tag>,
                <Tag color="orange">Open Access</Tag>]
        },
        {
            title: 'BIMCV-COVID19',
            website_entry: 'https://bimcv.cipf.es/bimcv-projects/bimcv-covid19/',
            resource_name: 'bimcv-covid19-512',
            func_name: 'modal_password_ressource',
            resource_source: 'vilmedic',
            tags: [<Tag color="blue">X-ray</Tag>, <Tag color="green">Labels</Tag>,
                <Tag color="red">Closed access</Tag>],
            content: (
                <>
                    <Table
                        dataSource={[
                            {
                                key: 'bimcv-covid19-512',
                                name: 'Chest X-rays',
                                num_im: "73,401",
                            },
                        ]}
                        columns={[
                            {
                                title: 'Modality/Anatomy',
                                dataIndex: 'name',
                                key: 'name',
                            },
                            {
                                title: 'Number of images',
                                dataIndex: 'num_im',
                                key: 'num',
                            }
                        ]}
                        pagination={false}
                    />
                </>
            ),
            processing: (
                <SyntaxHighlighter
                    customStyle={{textAlign: "left"}}
                    language="python"
                    style={codeStyle}>
                    {'from torchvision import transforms\n' +
                        'from PIL import Image, ImageFile\n' +
                        'ImageFile.LOAD_TRUNCATED_IMAGES = True\n' +
                        'transform = transforms.Compose([transforms.Resize(512)])\n' +
                        'transform((Image.open(in_image)).convert(\'RGB\')).save(out_image, quality=100, subsampling=0)'}
                </SyntaxHighlighter>
            ),
            bibtex_entry: "@article{vaya2020bimcv,\n" +
                "  title={BIMCV COVID-19+: a large annotated dataset of RX and CT images from COVID-19 patients},\n" +
                "  author={Vay{\\'a}, Maria De La Iglesia and Saborit, Jose Manuel and Montell, Joaquim Angel and Pertusa, Antonio and Bustos, Aurelia and Cazorla, Miguel and Galant, Joaquin and Barber, Xavier and Orozco-Beltr{\\'a}n, Domingo and Garc{\\'\\i}a-Garc{\\'\\i}a, Francisco and others},\n" +
                "  journal={arXiv preprint arXiv:2006.01174},\n" +
                "  year={2020}\n" +
                "}"
        },
        {
            title: 'BRAX',
            website_entry: 'https://physionet.org/content/brax/1.1.0/',
            resource_name: 'brax-512',
            func_name: 'modal_password_ressource',
            resource_source: 'physionet',
            tags: [<Tag color="blue">X-ray</Tag>, <Tag color="green">Labels</Tag>,
                <Tag color="orange">Physionet Access</Tag>],
            content: (
                <>
                    <Table
                        dataSource={[
                            {
                                key: 'NLM-TB-512',
                                name: 'Chest X-rays',
                                num_im: "40,967",
                            },
                        ]}
                        columns={[
                            {
                                title: 'Modality/Anatomy',
                                dataIndex: 'name',
                                key: 'name',
                            },
                            {
                                title: 'Number of images',
                                dataIndex: 'num_im',
                                key: 'num',
                            }
                        ]}
                        pagination={false}
                    />
                </>
            ),
            processing: (
                <SyntaxHighlighter
                    customStyle={{textAlign: "left"}}
                    language="python"
                    style={codeStyle}>
                    {'from torchvision import transforms\n' +
                        'from PIL import Image, ImageFile\n' +
                        'ImageFile.LOAD_TRUNCATED_IMAGES = True\n' +
                        'transform = transforms.Compose([transforms.Resize(512)])\n' +
                        'transform((Image.open(in_image)).convert(\'RGB\')).save(out_image, quality=100, subsampling=0)'}
                </SyntaxHighlighter>
            ),
            bibtex_entry: "@article{reis2022brax,\n" +
                "  title={BRAX, Brazilian labeled chest x-ray dataset},\n" +
                "  author={Reis, Eduardo P and de Paiva, Joselisa PQ and da Silva, Maria CB and Ribeiro, Guilherme AS and Paiva, Victor F and Bulgarelli, Lucas and Lee, Henrique MH and Santos, Paulo V and Brito, Vanessa M and Amaral, Lucas TW and others},\n" +
                "  journal={Scientific Data},\n" +
                "  volume={9},\n" +
                "  number={1},\n" +
                "  pages={487},\n" +
                "  year={2022},\n" +
                "  publisher={Nature Publishing Group UK London}\n" +
                "}"
        },
        {
            title: 'Intermountain',
            website_entry: '',
            resource_name: 'intermountain-512',
            func_name: 'modal_password_ressource',
            resource_source: 'vilmedic',
            tags: [<Tag color="blue">X-ray</Tag>, <Tag color="green">Labels</Tag>,
                <Tag color="red">Closed Access</Tag>],
            content: (
                <>
                    <Table
                        dataSource={[
                            {
                                key: 'intermountain-512',
                                name: 'Chest X-rays',
                                num_im: "19,206",
                            },
                        ]}
                        columns={[
                            {
                                title: 'Modality/Anatomy',
                                dataIndex: 'name',
                                key: 'name',
                            },
                            {
                                title: 'Number of images',
                                dataIndex: 'num_im',
                                key: 'num',
                            }
                        ]}
                        pagination={false}
                    />
                </>
            ),
            processing: (
                <SyntaxHighlighter
                    customStyle={{textAlign: "left"}}
                    language="python"
                    style={codeStyle}>
                    {'from torchvision import transforms\n' +
                        'from PIL import Image, ImageFile\n' +
                        'ImageFile.LOAD_TRUNCATED_IMAGES = True\n' +
                        'transform = transforms.Compose([transforms.Resize(512)])\n' +
                        'transform((Image.open(in_image)).convert(\'RGB\')).save(out_image, quality=100, subsampling=0)'}
                </SyntaxHighlighter>
            ),
            bibtex_entry: ""
        },
        {
            content: (
                <>
                    <Table
                        dataSource={[
                            {
                                key: 'COVID-Net CXR-512',
                                name: 'Chest X-rays',
                                num_im: "30,000",
                            },
                        ]}
                        columns={[
                            {
                                title: 'Modality/Anatomy',
                                dataIndex: 'name',
                                key: 'name',
                            },
                            {
                                title: 'Number of images',
                                dataIndex: 'num_im',
                                key: 'num',
                            }
                        ]}
                        pagination={false}
                    />
                </>
            ),
            processing: (
                <SyntaxHighlighter
                    customStyle={{textAlign: "left"}}
                    language="python"
                    style={codeStyle}>
                    {'from torchvision import transforms\n' +
                        'from PIL import Image, ImageFile\n' +
                        'ImageFile.LOAD_TRUNCATED_IMAGES = True\n' +
                        'transform = transforms.Compose([transforms.Resize(512)])\n' +
                        'transform((Image.open(in_image)).convert(\'RGB\')).save(out_image, quality=100, subsampling=0)'}
                </SyntaxHighlighter>
            ),
            title: 'COVIDx CXR-3',
            bibtex_entry: "@Article{Wang2020,\n" +
                "\tauthor={Wang, Linda and Lin, Zhong Qiu and Wong, Alexander},\n" +
                "\ttitle={COVID-Net: a tailored deep convolutional neural network design for detection of COVID-19 cases from chest X-ray images},\n" +
                "\tjournal={Scientific Reports},\n" +
                "\tyear={2020},\n" +
                "\tmonth={Nov},\n" +
                "\tday={11},\n" +
                "\tvolume={10},\n" +
                "\tnumber={1},\n" +
                "\tpages={19549},\n" +
                "\tissn={2045-2322},\n" +
                "\tdoi={10.1038/s41598-020-76550-z},\n" +
                "\turl={https://doi.org/10.1038/s41598-020-76550-z}\n" +
                "}",
            website_entry: 'https://github.com/lindawangg/COVID-Net/tree/master',
            resource_name: 'COVIDx-CXR-3-512',
            func_name: 'directDownloadDataset',
            tags: [<Tag color="blue">X-ray</Tag>, <Tag color="green">Labels</Tag>,
                <Tag color="orange">Open Access</Tag>]
        },
        {
            content: (
                <Table
                    dataSource={[
                        {
                            key: 'SIIM-ACR-Pneumothorax-512',
                            name: 'Chest X-rays',
                            num_im: "15,294",
                        },
                    ]}
                    columns={[
                        {
                            title: 'Modality/Anatomy',
                            dataIndex: 'name',
                            key: 'name',
                        },
                        {
                            title: 'Number of images',
                            dataIndex: 'num_im',
                            key: 'num',
                        }
                    ]}
                    pagination={false}
                />
            ),
            processing: (
                <SyntaxHighlighter
                    customStyle={{textAlign: "left"}}
                    language="python"
                    style={codeStyle}>
                    {'from torchvision import transforms\n' +
                        'import numpy as np\n' +
                        'import pydicom\n' +
                        'from PIL import Image\n' +
                        '\n' +
                        'transform = transforms.Compose([transforms.Resize(512)])\n' +
                        '\n' +
                        'def dcm_to_pil(dcm_filename):\n' +
                        '    # Load the DICOM file\n' +
                        '    try:\n' +
                        '        dcm_data = pydicom.dcmread(dcm_filename)\n' +
                        '    except Exception as e:\n' +
                        '        print(f"Cannot read DICOM file {dcm_filename}: {str(e)}")\n' +
                        '        return None\n' +
                        '\n' +
                        '    # Check if DICOM file is a valid image\n' +
                        '    try:\n' +
                        '        dcm_data.pixel_array\n' +
                        '    except Exception as e:\n' +
                        '        print(f"DICOM file {dcm_filename} is not a valid image: {str(e)}")\n' +
                        '        return None\n' +
                        '\n' +
                        '    # Convert pixel data to numpy array and normalize\n' +
                        '    image_2d = dcm_data.pixel_array.astype(float)\n' +
                        '    image_2d_scaled = (np.maximum(image_2d, 0) / image_2d.max()) * 255.0\n' +
                        '\n' +
                        '    # Convert to uint8 and create PIL image\n' +
                        '    image_2d_scaled = np.uint8(image_2d_scaled)\n' +
                        '    final_image = Image.fromarray(image_2d_scaled)\n' +
                        '\n' +
                        '    return final_image\n' +
                        '\n' +
                        'transform(dcm_to_pil(in_file).convert(\'RGB\')).save(out_file_file, quality=100, subsampling=0)'}
                </SyntaxHighlighter>
            ),
            title: 'SIIM-ACR Pneumothorax',
            bibtex_entry: 'No BibTex Found',
            website_entry: 'https://siim.org/page/pneumothorax_challenge',
            resource_name: 'SIIM-ACR-Pneumothorax-512',
            func_name: 'directDownloadDataset',
            tags: [<Tag color="blue">X-ray</Tag>, <Tag color="green">Labels</Tag>,
                <Tag color="orange">Open Access</Tag>]
        },
        {
            content: (
                <Table
                    dataSource={[
                        {
                            key: 'candid-ptx-images-512',
                            name: 'Chest X-rays',
                            num_im: "19,237",
                        },
                    ]}
                    columns={[
                        {
                            title: 'Modality/Anatomy',
                            dataIndex: 'name',
                            key: 'name',
                        },
                        {
                            title: 'Number of images',
                            dataIndex: 'num_im',
                            key: 'num',
                        }
                    ]}
                    pagination={false}
                />
            ),
            processing: (
                <SyntaxHighlighter
                    customStyle={{textAlign: "left"}}
                    language="python"
                    style={codeStyle}>
                    {'from torchvision import transforms\n' +
                        'from PIL import Image, ImageFile\n' +
                        'ImageFile.LOAD_TRUNCATED_IMAGES = True\n' +
                        'transform = transforms.Compose([transforms.Resize(512)])\n' +
                        'transform((Image.open(in_image)).convert(\'RGB\')).save(out_image, quality=100, subsampling=0)'}
                </SyntaxHighlighter>
            ),
            title: 'CANDID-PTX',
            bibtex_entry: '@article{Feng2021,\n' +
                'author = "Sijing Feng and Damian Azzollini and Ji Soo Kim and Cheng Kai Jin and Eve Kim and Simon Gordon and Jason Yeoh and Min A Han and Andrew Lee and Aakash Patel and Martin Urschler and Amy Fong and Cameron Simmers and Gregory Tarr and Stuart Barnard and Ben Wilson",\n' +
                'title = "{CANDID-PTX}",\n' +
                'year = "2021",\n' +
                'month = "6",\n' +
                'url = "https://auckland.figshare.com/articles/dataset/CANDID-PTX/14173982",\n' +
                'doi = "10.17608/k6.auckland.14173982"\n' +
                '}',
            website_entry: 'https://auckland.figshare.com/articles/dataset/CANDID-PTX/14173982',
            resource_name: 'candid-ptx-images-512',
            func_name: 'directDownloadDataset',
            tags: [<Tag color="blue">X-ray</Tag>, <Tag color="orange">Open Access</Tag>]
        },
        {
            content: (
                <Table
                    dataSource={[
                        {
                            key: 'VinBigData',
                            name: 'Chest X-rays',
                            num_im: "18,000",
                        },
                    ]}
                    columns={[
                        {
                            title: 'Modality/Anatomy',
                            dataIndex: 'name',
                            key: 'name',
                        },
                        {
                            title: 'Number of images',
                            dataIndex: 'num_im',
                            key: 'num',
                        }
                    ]}
                    pagination={false}
                />
            ),
            processing: (
                <SyntaxHighlighter
                    customStyle={{textAlign: "left"}}
                    language="python"
                    style={codeStyle}>
                    {'import pydicom\n' +
                        'from PIL import Image\n' +
                        'import numpy as np\n' +
                        'from torchvision import transforms\n' +
                        'from pydicom.pixel_data_handlers.util import apply_voi_lut\n' +
                        '\n' +
                        'def read_xray(path, voi_lut=True, fix_monochrome=True):\n' +
                        '    # Original from: https://www.kaggle.com/raddar/convert-dicom-to-np-array-the-correct-way\n' +
                        '    dicom = pydicom.read_file(path)\n' +
                        '\n' +
                        '    # VOI LUT (if available by DICOM device) is used to transform raw DICOM data to\n' +
                        '    # "human-friendly" view\n' +
                        '    if voi_lut:\n' +
                        '        data = apply_voi_lut(dicom.pixel_array, dicom)\n' +
                        '    else:\n' +
                        '        data = dicom.pixel_array\n' +
                        '\n' +
                        '    # depending on this value, X-ray may look inverted - fix that:\n' +
                        '    if fix_monochrome and dicom.PhotometricInterpretation == "MONOCHROME1":\n' +
                        '        data = np.amax(data) - data\n' +
                        '\n' +
                        '    data = data - np.min(data)\n' +
                        '    data = data / np.max(data)\n' +
                        '    data = (data * 255).astype(np.uint8)\n' +
                        '\n' +
                        '    im = Image.fromarray(data)\n' +
                        '    return im\n' +
                        '\n' +
                        '\n' +
                        'transform = transforms.Compose([transforms.Resize(512)])\n' +
                        'transform(read_xray(in_file).convert(\'RGB\')).save(out_file.replace("dicom", "png"),\n' +
                        'quality=100, subsampling=0)'}
                </SyntaxHighlighter>
            ),
            title: 'VinBigData',
            bibtex_entry: '@article{nguyen2022vindr,\n' +
                '  title={VinDr-CXR: An open dataset of chest X-rays with radiologist’s annotations},\n' +
                '  author={Nguyen, Ha Q and Lam, Khanh and Le, Linh T and Pham, Hieu H and Tran, Dat Q and Nguyen, Dung B and Le, Dung D and Pham, Chi M and Tong, Hang TT and Dinh, Diep H and others},\n' +
                '  journal={Scientific Data},\n' +
                '  volume={9},\n' +
                '  number={1},\n' +
                '  pages={429},\n' +
                '  year={2022},\n' +
                '  publisher={Nature Publishing Group UK London}\n' +
                '}',
            website_entry: 'https://physionet.org/content/vindr-cxr/1.0.0/',
            resource_name: 'VinBigData-512',
            func_name: 'directDownloadDataset',
            tags: [<Tag color="blue">X-ray</Tag>, <Tag color="green">Labels</Tag>,
                <Tag color="orange">Open Access</Tag>]
        },
        {
            content: (
                <Table
                    dataSource={[
                        {
                            key: 'openi-indiana-university',
                            name: 'Chest X-rays',
                            num_im: "7,470",
                        },
                    ]}
                    columns={[
                        {
                            title: 'Modality/Anatomy',
                            dataIndex: 'name',
                            key: 'name',
                        },
                        {
                            title: 'Number of images',
                            dataIndex: 'num_im',
                            key: 'num',
                        }
                    ]}
                    pagination={false}
                />
            ),
            processing: (
                <SyntaxHighlighter
                    customStyle={{textAlign: "left"}}
                    language="python"
                    style={codeStyle}>
                    {'from torchvision import transforms\n' +
                        'from PIL import Image, ImageFile\n' +
                        'ImageFile.LOAD_TRUNCATED_IMAGES = True\n' +
                        'transform = transforms.Compose([transforms.Resize(512)])\n' +
                        'transform((Image.open(in_image)).convert(\'RGB\')).save(out_image, quality=100, subsampling=0)'}
                </SyntaxHighlighter>
            ),
            title: 'Openi-indiana-university',
            bibtex_entry: '@article{demner2012design,\n' +
                '  title={Design and development of a multimodal biomedical information retrieval system},\n' +
                '  author={Demner-Fushman, Dina and Antani, Sameer and Simpson, Matthew and Thoma, George R},\n' +
                '  journal={Journal of Computing Science and Engineering},\n' +
                '  volume={6},\n' +
                '  number={2},\n' +
                '  pages={168--177},\n' +
                '  year={2012},\n' +
                '  publisher={Demner-Fushman Dina; Antani Sameer; Simpson Matthew; Thoma George R.}\n' +
                '}',
            website_entry: 'https://openi.nlm.nih.gov/faq',
            resource_name: 'Openi-indiana-university-512',
            func_name: 'directDownloadDataset',
            tags: [<Tag color="blue">X-ray</Tag>, <Tag color="orange">Open Access</Tag>]
        },
        {
            content: (
                <Table
                    dataSource={[
                        {
                            key: 'JF-Healthcare-ObjectCXR',
                            name: 'Chest X-rays',
                            num_im: "9,000",
                        },
                    ]}
                    columns={[
                        {
                            title: 'Modality/Anatomy',
                            dataIndex: 'name',
                            key: 'name',
                        },
                        {
                            title: 'Number of images',
                            dataIndex: 'num_im',
                            key: 'num',
                        }
                    ]}
                    pagination={false}
                />
            ),
            processing: (
                <SyntaxHighlighter
                    customStyle={{textAlign: "left"}}
                    language="python"
                    style={codeStyle}>
                    {'from torchvision import transforms\n' +
                        'from PIL import Image, ImageFile\n' +
                        'ImageFile.LOAD_TRUNCATED_IMAGES = True\n' +
                        'transform = transforms.Compose([transforms.Resize(512)])\n' +
                        'transform((Image.open(in_image)).convert(\'RGB\')).save(out_image, quality=100, subsampling=0)'}
                </SyntaxHighlighter>
            ),
            title: 'JF-Healthcare-ObjectCXR',
            bibtex_entry: 'No BibTex Found',
            website_entry: 'https://www.kaggle.com/datasets/raddar/foreign-objects-in-chest-xrays',
            resource_name: 'JF-Healthcare-ObjectCXR-512',
            func_name: 'directDownloadDataset',
            tags: [<Tag color="blue">X-ray</Tag>, <Tag color="green">Labels</Tag>,
                <Tag color="orange">Open Access</Tag>]
        },
        {
            content: (
                <>
                    <Table
                        dataSource={[
                            {
                                key: 'NLM-TB-512',
                                name: 'Chest X-rays',
                                num_im: "800",
                            },
                        ]}
                        columns={[
                            {
                                title: 'Modality/Anatomy',
                                dataIndex: 'name',
                                key: 'name',
                            },
                            {
                                title: 'Number of images',
                                dataIndex: 'num_im',
                                key: 'num',
                            }
                        ]}
                        pagination={false}
                    />
                </>
            ),
            processing: (
                <SyntaxHighlighter
                    customStyle={{textAlign: "left"}}
                    language="python"
                    style={codeStyle}>
                    {'from torchvision import transforms\n' +
                        'from PIL import Image, ImageFile\n' +
                        'ImageFile.LOAD_TRUNCATED_IMAGES = True\n' +
                        'transform = transforms.Compose([transforms.Resize(512)])\n' +
                        'transform((Image.open(in_image)).convert(\'RGB\')).save(out_image, quality=100, subsampling=0)'}
                </SyntaxHighlighter>
            ),
            title: 'NLM Tuberculosis Datasets',
            bibtex_entry: "@article{jaeger2014two,\n" +
                "  title={Two public chest X-ray datasets for computer-aided screening of pulmonary diseases},\n" +
                "  author={Jaeger, Stefan and Candemir, Sema and Antani, Sameer and W{\\'a}ng, Y{\\`\\i}-Xi{\\'a}ng J and Lu, Pu-Xuan and Thoma, George},\n" +
                "  journal={Quantitative imaging in medicine and surgery},\n" +
                "  volume={4},\n" +
                "  number={6},\n" +
                "  pages={475},\n" +
                "  year={2014},\n" +
                "  publisher={AME Publications}\n" +
                "}",
            website_entry: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4256233/',
            resource_name: 'NLM-TB-512',
            func_name: 'directDownloadDataset',
            tags: [<Tag color="blue">X-ray</Tag>, <Tag color="green">Labels</Tag>,
                <Tag color="orange">Open Access</Tag>]
        },
    ]


    return (
        <ModalPasswordRessourceProvider>
            <ImageDatasets data={data}/>
        </ModalPasswordRessourceProvider>
    )
}

export default ImageDatasetsContainer