import React, {useEffect, useState} from 'react'
import DeID from "./DeID"

const DeIDContainer = () => {


    useEffect(() => {
    }, [])


    return (
        <DeID/>
    )
}

export default DeIDContainer