import React, {useState} from 'react'
import "./style/style.css"
import {Affix, Button, Divider, Input, message, Space, Spin, Table, Tag, Typography,} from 'antd';
import explainReportApi from "services/explainReport";


const {Paragraph, Link} = Typography;
const {TextArea} = Input;

const REPORT_DEFAULT_PROMPT = "1. nodular opacities in the left lower lung with additional small ground-glass opacities bilaterally may represent infection. chest ct recommended for further assessment given infectious symptoms. 2. abdominal wall varices of indeterminate etiology. 3. splenomegaly. 4. coronary artery calcification. acute findings were discussed with dr. ___ by dr. ___ by telephone at 6:54 p.m. on ___."
const RadGraph = () => {


    const [reportSentIsLoading, setReportSentIsLoading] = useState(false);
    const [radGraphTableRecord, setRadGraphTableRecord] = useState(null);
    const [report, setReport] = useState([]);
    const [reportInput, setReportInput] = useState(REPORT_DEFAULT_PROMPT);

    const getProcessedRadGraphAnnotations = async (report) => {
        return await explainReportApi.getProcessedRadGraphAnnotations(report)
    }


    const reportOnChange = (e) => {
        setReportInput(e.target.value);
    };


    function resetAll() {
        setReportInput(REPORT_DEFAULT_PROMPT)
        setReportSentIsLoading(false)
        setRadGraphTableRecord(null)
        setReport([])
    }

    function displayError(msg) {
        message.error(msg);
        resetAll()
    }


    async function submitReport() {
        setReportSentIsLoading(true)

        // get annotations
        const rgAnnotations = await getProcessedRadGraphAnnotations(reportInput)

        if (typeof rgAnnotations !== 'object') {
            displayError("Backend is offline");
            resetAll()
            return
        }

        if ("error" in rgAnnotations) {
            displayError(rgAnnotations["error"]);
            resetAll()
            return
        }

        // Table annotations
        let processed_annotations = rgAnnotations["processed_annotations"]
        console.log(processed_annotations)
        setReport(rgAnnotations["radgraph_text"].split(" "))

        processed_annotations = processed_annotations.map((record, index) => Object.assign({}, record, {key: index}))
        setRadGraphTableRecord(processed_annotations)
        setReportSentIsLoading(false)
    }

    const columns = [
        {
            title: 'Observation',
            dataIndex: 'observation',
            key: 'observation',
        },
        {
            title: 'Located at',
            dataIndex: 'located_at',
            key: 'located_at',
            render: (_, {located_at}) => (
                <>
                    {located_at.join(", ")}
                </>
            ),
        },
        {
            title: 'Tags',
            key: 'tags',
            dataIndex: 'tags',
            render: (_, {tags}) => (
                <>
                    {tags.map((tag) => {
                        let color = 'orange'
                        if (tag === 'definitely present') {
                            color = 'geekblue';
                        }
                        if (tag === 'definitely absent') {
                            color = 'volcano';
                        }
                        return (
                            <Tag color={color} key={tag}>
                                {tag.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
        {
            title: 'Suggestive of',
            dataIndex: 'suggestive_of',
            key: 'suggestive_of',
            render: (_, {suggestive_of}) => {
                if (!suggestive_of) {
                    return null;
                }
                const suggs = suggestive_of.reduce((acc, sugg, i) => {
                    const link = (
                        <>
                            {sugg}
                        </>
                    );
                    return i === 0 ? [link] : [...acc, ", ", link];
                }, []);
                return <>{suggs}</>;
            },
        },
    ];

    if (reportSentIsLoading)
        return <Spin/>

    if (radGraphTableRecord) {
        return (
            <>
                <Space direction={"vertical"}>
                    <div style={{width: 900}}>
                        <Affix offsetTop={20} style={{float: "right"}}>
                            <Button type="primary" onClick={() => resetAll()} danger>
                                Reset
                            </Button>
                        </Affix>
                        <Divider orientation="left">Summary of your report</Divider>
                        <Paragraph>
                            <center>{report.map((word) => <>{word} </>)}</center>
                        </Paragraph>
                        <Divider orientation="left">Observations</Divider>
                        <Table columns={columns} dataSource={radGraphTableRecord}/>
                    </div>
                </Space>


            </>
        )
    }


    return (
        <>
            <Space direction={"vertical"}>
                <div style={{width: 900}}>
                    <Paragraph>
                        Please paste your radiology report:
                    </Paragraph>
                    {reportInput.trim() &&
                        <Affix offsetTop={20} style={{float: "right", marginBottom: 10}}>
                            <Button type="primary" onClick={() => submitReport()}>
                                Explain my report
                            </Button>
                        </Affix>
                    }
                    <TextArea
                        style={{width: 900}}
                        autoSize={{minRows: 4}}
                        onChange={reportOnChange}
                        defaultValue={REPORT_DEFAULT_PROMPT}
                    />
                </div>
            </Space>
        </>
    );
};


export default RadGraph
