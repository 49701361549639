import React, {useState} from 'react';
import explainReportApi from "services/explainReport";

const Context = React.createContext(null);
const ProviderWrapper = (props) => {

    const [conversation, setConversation] = useState([]);

    const getSummaryOfReport = async (report) => {
        return await explainReportApi.getSummarizationReport(report)
    }

    const exposedValues = {
        conversation,
        setConversation,
    }

    return (
        <Context.Provider value={exposedValues}>{props.children}</Context.Provider>
    )
}
export {Context, ProviderWrapper};
export default Context;