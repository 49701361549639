import React from 'react'
import {Menu} from 'antd';
import "./style/style.css"
import {HomeOutlined} from '@ant-design/icons';

import {useLocation, Link,} from 'react-router-dom'

const Header = () => {
    const location = useLocation()
    const browserBarStyle = {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center'
    };

    return (
        <>
            <Menu selectedKeys={[location.pathname]} mode="horizontal" style={browserBarStyle}>
                <Menu.Item key="header-icon" icon={<img style={{"width": 14}} src={"/favicon/favicon-64x64.png"} alt={""}/>}>
                    <Link to="/">ViLMedic</Link>
                </Menu.Item>
            </Menu>

        </>
    )
}

export {Header}
