import React from 'react'
import {Layout} from 'antd'
import {BrowserRouter as Router} from "react-router-dom";

import 'antd/dist/antd.min.css';
import {Header} from 'components/Layout/Headers/Header.jsx'
import MainFooter from 'components/Layout/Footers/MainFooter/MainFooter.jsx'
import SiderContent from "./SiderContent";
import MainContent from './MainContent.jsx'


const MainLayout = () => {
    return (
        <Router>
            <Layout>
                <Header/>
                <Layout>
                    <SiderContent/>
                    <MainContent/>
                </Layout>
                <MainFooter/>
            </Layout>
        </Router>

    )
}

export default MainLayout
