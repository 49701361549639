import axios from "axios";

const API_URI = process.env.REACT_APP_API_URI
const modelsApi = axios.create({
    baseURL: API_URI + "/models",
    headers: {
        "Content-type": "application/json; charset=UTF-8",
        "ngrok-skip-browser-warning": "True",
    }
});


const miscApi = axios.create({
    baseURL: API_URI + "/misc",
    headers: {
        "Content-type": "application/json; charset=UTF-8",
        "ngrok-skip-browser-warning": "True",
    }
});


const exportedObject = {
    API_URI,
    modelsApi,
    miscApi,
}

export default exportedObject