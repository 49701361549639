import React, {useEffect, useState} from 'react'
import ExplainReport from "./explainReport";

const ExplainReportContainer = () => {

    return (
        <ExplainReport/>
    )
}

export default ExplainReportContainer