import React from 'react'
import "./style/style.css"
import ExplainReport from "./explainReport/explainReport"

const PlumbGPT = () => {

    const centeredContainerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    };


    return (
        <div style={centeredContainerStyle}>
            <ExplainReport/>
        </div>

    )
}

export default PlumbGPT
