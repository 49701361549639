import React, {useEffect} from 'react'
import MVQA from "./MVQA"

const MVQAContainer = () => {

    useEffect(() => {
    }, [])

    return (
        <MVQA/>
    )
}

export default MVQAContainer