import React, {useEffect, useContext, useState} from 'react'
import {Alert, Divider, message, notification, Space, Spin, Table, Typography, Upload} from 'antd';
import aimiSummer24Api from "services/misc/aimisummer24";
import LeaderboardDragger from "./LeaderboardDragger"
import SyntaxHighlighter from "react-syntax-highlighter";
import GlobalContext from "contexts/GlobalContext";
import Retrieve from "./Retrieve";
import './style/style.css';

const {Title, Text, Paragraph, Link} = Typography;

const code_process_distance_pred = 'from sklearn.metrics import f1_score, roc_auc_score\n' +
    'f1 = f1_score(y_true=y_true, y_pred=y_pred, average=None)\n' +
    'f1_macro = f1_score(y_true=y_true, y_pred=y_pred, average=\'macro\')\n' +
    'auc = roc_auc_score(y_true=y_true, y_score=y_prob, average=None)\n' +
    'auc_macro = roc_auc_score(y_true=y_true, y_score=y_prob, average=\'macro\')\n'


const DATASETS = {
    "disease-prediction": 2984,
}

const openNotificationWithIcon = (type, description) => {
    notification[type]({
        message: 'Notification: ' + type,
        description: description,
        duration: 0,
    });
};


const columnsSingle = {
    "disease-prediction": [
        {
            title: 'Macro F1',
            dataIndex: 'macro_f1',
            key: 'macro_f1',
            render: (text) => text === null ? 'N/A' : text.toFixed(2),
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.macro_f1 - b.macro_f1,
        },
        {
            title: 'Macro AUROC',
            dataIndex: 'macro_auc',
            key: 'macro_auc',
            render: (text) => text === null ? 'N/A' : text.toFixed(2)
        },
        {
            title: 'No Finding AUROC',
            dataIndex: 'NoFindingAUROC',
            key: 'NoFindingAUROC',
            render: (text) => text === null ? 'N/A' : text.toFixed(2),

        },
        {
            title: 'No Finding F1',
            dataIndex: 'NoFindingF1',
            key: 'NoFindingF1',
            render: (text) => text === null ? 'N/A' : text.toFixed(2)
        },
        {
            title: 'Pleural Effusion AUROC',
            dataIndex: 'PleuralEffusionAUROC',
            key: 'PleuralEffusionAUROC',
            render: (text) => text === null ? 'N/A' : text.toFixed(2)
        },
        {
            title: 'Pleural Effusion F1',
            dataIndex: 'PleuralEffusionF1',
            key: 'PleuralEffusionF1',
            render: (text) => text === null ? 'N/A' : text.toFixed(2)
        },
        {
            title: 'Pneumonia AUROC',
            dataIndex: 'PneumoniaAUROC',
            key: 'PneumoniaAUROC',
            render: (text) => text === null ? 'N/A' : text.toFixed(2)
        },
        {
            title: 'Pneumonia F1',
            dataIndex: 'PneumoniaF1',
            key: 'PneumoniaF1',
            render: (text) => text === null ? 'N/A' : text.toFixed(2)
        },
        {
            title: 'Pneumothorax AUROC',
            dataIndex: 'PneumothoraxAUROC',
            key: 'PneumothoraxAUROC',
            render: (text) => text === null ? 'N/A' : text.toFixed(2)
        },
        {
            title: 'Pneumothorax F1',
            dataIndex: 'PneumothoraxF1',
            key: 'PneumothoraxF1',
            render: (text) => text === null ? 'N/A' : text.toFixed(2)
        },
    ]
}


const header_prediction = [
    {
        title: 'Team',
        dataIndex: 'team',
        key: 'team',
    },
    {
        title: 'Macro F1',
        dataIndex: 'macro_f1',
        key: 'macro_f1',
        render: (text) => text === null ? 'N/A' : text.toFixed(2),
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.macro_f1 - b.macro_f1,
    },
    {
        title: 'Macro AUROC',
        dataIndex: 'macro_auc',
        key: 'macro_auc',
        render: (text) => text === null ? 'N/A' : text.toFixed(2)
    },
    {
        title: 'No Finding AUROC',
        dataIndex: 'NoFindingAUROC',
        key: 'NoFindingAUROC',
        render: (text) => text === null ? 'N/A' : text.toFixed(2),

    },
    {
        title: 'No Finding F1',
        dataIndex: 'NoFindingF1',
        key: 'NoFindingF1',
        render: (text) => text === null ? 'N/A' : text.toFixed(2)
    },
    {
        title: 'Pleural Effusion AUROC',
        dataIndex: 'PleuralEffusionAUROC',
        key: 'PleuralEffusionAUROC',
        render: (text) => text === null ? 'N/A' : text.toFixed(2)
    },
    {
        title: 'Pleural Effusion F1',
        dataIndex: 'PleuralEffusionF1',
        key: 'PleuralEffusionF1',
        render: (text) => text === null ? 'N/A' : text.toFixed(2)
    },
    {
        title: 'Pneumonia AUROC',
        dataIndex: 'PneumoniaAUROC',
        key: 'PneumoniaAUROC',
        render: (text) => text === null ? 'N/A' : text.toFixed(2)
    },
    {
        title: 'Pneumonia F1',
        dataIndex: 'PneumoniaF1',
        key: 'PneumoniaF1',
        render: (text) => text === null ? 'N/A' : text.toFixed(2)
    },
    {
        title: 'Pneumothorax AUROC',
        dataIndex: 'PneumothoraxAUROC',
        key: 'PneumothoraxAUROC',
        render: (text) => text === null ? 'N/A' : text.toFixed(2)
    },
    {
        title: 'Pneumothorax F1',
        dataIndex: 'PneumothoraxF1',
        key: 'PneumothoraxF1',
        render: (text) => text === null ? 'N/A' : text.toFixed(2)
    },

];


const Leaderboard = () => {

    const [waitForTeam, setWaitForTeam] = useState(false);
    const [waitForResult, setWaitForResult] = useState(false);
    const [hyps, setHyps] = useState(null);
    const [team, setTeam] = useState("");
    const [pin, setPin] = useState("");
    const [selectedDataset, setSelectedDataset] = useState(null);
    const [filename, setFilename] = useState("");
    const {
        codeStyle,
        errorMessage,
        setErrorMessage
    } = useContext(GlobalContext);

    // current_result
    const [data, setData] = useState(null);
    const [leaderboard, setLeaderboard] = useState({});

    const getLeaderboard = async (item) => {
        try {
            const response = await aimiSummer24Api.getScores(item);
            if ("error" in response)
                setErrorMessage(response["error"])
            return {[item]: response};
        } catch (error) {
            console.error(error);
            return {[item]: null};
        }
    };

    useEffect(() => {
        setErrorMessage("")
        Promise.all(Object.keys(DATASETS).map(getLeaderboard))
            .then(results => {
                // Combine all the results into a single object
                const newLeaderboard = results.reduce((acc, curr) => ({...acc, ...curr}), {});
                // Update the state with the new leaderboard
                setLeaderboard(newLeaderboard);
            });
    }, [])

    const resetState = () => {
        setData(null)
        setHyps(null)
        setTeam("")
        setPin("")
        setWaitForTeam(false)
        setWaitForResult(false)
        setFilename("")
    }
    const handleFileUpload = async ({fileList: newFileList}, dataset) => {
        resetState()
        setWaitForTeam(true)

        if (newFileList.length > 0) {
            let file = newFileList[0]
            let hyps = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);

            })
            hyps = atob(hyps.split(',')[1]).split("\n")

            // remove trailing blank line if present
            if (typeof hyps[hyps.length - 1] === 'string' && hyps[hyps.length - 1] === '') {
                hyps.pop();
            }

            if (hyps.length !== DATASETS[dataset]) {
                message.error(`Num of lines mismatch:  ${hyps.length} (your file) vs ${DATASETS[dataset]} (reference file) !`);
                resetState()
                return false
            }
            setHyps(hyps)
            setFilename(file["name"])
        }
    }
    const resetResults = async () => {
        resetState()
    }
    const submitResults = async (dataset) => {
        setWaitForResult(true)
        setWaitForTeam(false)

        if (!(team) || team === "") {
            message.error("No team provided")
            setWaitForResult(false)
            return
        }

        if (!(pin) || pin === "") {
            message.error("No pin provided")
            setWaitForResult(false)
            return
        }

        if (!Array.isArray(hyps) || hyps.length === 0) {
            message.error("No prediction provided")
            setWaitForResult(false)
            return
        }

        let ret = await aimiSummer24Api.computeScores(hyps, dataset, pin, team)
        console.log(ret)
        if (!(typeof ret === 'object' && !Array.isArray(ret))) {
            resetState()
            openNotificationWithIcon("error", "Error happened in the scoring, please try again later")
            return
        }

        if ('error' in ret) {
            resetState()
            openNotificationWithIcon("error", ret["error"])
            return
        }
        ret["key"] = 1
        setData([ret])
        setHyps(null)
        setTeam("")
        setPin("")
        setWaitForTeam(false)
        setWaitForResult(false)
        setFilename("")
        openNotificationWithIcon("success", "Result successfully sent. Reload the page to refresh leaderboards.")

    }
    if (errorMessage) {
        return (
            <Alert
                message="An error has been caught"
                description={`Sorry. We encountered the following error: ${errorMessage}`}
                type="error"
                showIcon
            />
        );
    }

    return (
        <div style={{width: 1000}}>
            <Space direction={"vertical"} size={20}>
                <Title level={3}>1. Introduction</Title>
                <Paragraph>
                    <b>The Problem</b>: Given a chest X-ray, our goal in this project is to predict the present
                    respiratory diseases.
                </Paragraph>
                <Title level={3}>2. Metrics</Title>

                <Paragraph>
                    <b>F1-score and AUROC</b>
                </Paragraph>

                <Title level={3}>3. How to</Title>
                To submit your model predictions, please use Section 4 the following way:
                <Paragraph>
                    <b>How to:</b>
                    <ol>
                        <li>
                            Select the disease-prediction task.
                        </li>

                        <li>
                            Upload your predictions (csv) with this exact structure (the study_id can be in an
                            arbitrary order).<br/><br/>
                            <Space direction={"horizontal"}>
                                <div>
                                    <center><b>Disease Prediction</b></center>
                                    <table className="dataframe" border="1">
                                        <thead>
                                        <tr style={{textAlign: "right"}}>
                                            <th>study_id</th>
                                            <th>Pneumothorax</th>
                                            <th>Pneumonia</th>
                                            <th>Pleural Effusion</th>
                                            <th>No Finding</th>
                                            <th>Pneumothorax Probs</th>
                                            <th>Pneumonia Probs</th>
                                            <th>Pleural Effusion Probs</th>
                                            <th>No Finding Probs</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>test/patient35172/study3</td>
                                            <td>0</td>
                                            <td>1</td>
                                            <td>0</td>
                                            <td>0</td>
                                            <td>0.10</td>
                                            <td>0.34</td>
                                            <td>0.28</td>
                                            <td>0.27</td>
                                        </tr>
                                        <tr>
                                            <td>test/patient43229/study5</td>
                                            <td>0</td>
                                            <td>1</td>
                                            <td>0</td>
                                            <td>0</td>
                                            <td>0.07</td>
                                            <td>0.06</td>
                                            <td>0.67</td>
                                            <td>0.20</td>
                                        </tr>
                                        <tr>
                                            <td>test/patient21668/study3</td>
                                            <td>0</td>
                                            <td>1</td>
                                            <td>0</td>
                                            <td>0</td>
                                            <td>0.27</td>
                                            <td>0.15</td>
                                            <td>0.36</td>
                                            <td>0.22</td>
                                        </tr>
                                        <tr>
                                            <td>test/patient35880/study5</td>
                                            <td>0</td>
                                            <td>0</td>
                                            <td>1</td>
                                            <td>0</td>
                                            <td>0.38</td>
                                            <td>0.27</td>
                                            <td>0.34</td>
                                            <td>0.02</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Space>
                        </li>
                        <li>
                            Enter your team name with a pin code (such as 1234). If this is your first submission, your
                            team name will be
                            created. The pin code ensures that only you team can submit for your team name, please
                            remember it.
                        </li>
                        <li>
                            Click submit and wait for the success message stating that your submission has been
                            recorded.
                        </li>
                    </ol>

                    Here is the code used to evaluate Disease Predictions:
                    <SyntaxHighlighter customStyle={{textAlign: "left"}} language="python" style={codeStyle}>
                        {code_process_distance_pred}
                    </SyntaxHighlighter>


                </Paragraph>

                <Title level={3}>4. Submit</Title>

                <LeaderboardDragger
                    handleFileUpload={handleFileUpload}
                    loading={waitForResult || waitForTeam}
                    hyps={hyps}
                    setTeam={setTeam}
                    setPin={setPin}
                    pin={pin}
                    filename={filename}
                    submitResults={submitResults}
                    resetResults={resetResults}
                    datasets={Object.keys(DATASETS)}
                    selectedDataset={selectedDataset}
                    setSelectedDataset={setSelectedDataset}
                />

                {waitForResult &&
                    <div style={{padding: 50}}>
                        <Spin tip="Computing scores, this could take a few minutes...">
                            <div className="content"/>
                        </Spin>
                    </div>
                }
                {data && selectedDataset &&
                    <div>
                        <Divider orientation="left">Submission</Divider>
                        <Table columns={columnsSingle[selectedDataset]} dataSource={data}/>
                    </div>
                }

                <Title level={3}>4. Retrieve my submissions</Title>
                <Retrieve/>

                <Title level={3}>5. Leaderboards</Title>


                {[
                    ["disease-prediction", "Disease Prediction", header_prediction],
                ].map((dataset) =>
                    <>
                        <Title level={5}>{dataset[1]} ({DATASETS[dataset[0]] - 1} samples)</Title>
                        <div>
                            <Divider orientation="left">Leaderboard</Divider>
                            <Table columns={dataset[2]}
                                   dataSource={leaderboard[dataset[0]]}
                                   loading={(Object.keys(leaderboard).length === 0)}
                            />
                        </div>

                    </>
                )}


            </Space>
        </div>
    )

}

export default Leaderboard

