import axios from "axios";

import config from "services/config";

const downloadAPI = axios.create({
    baseURL: config.API_URI + "/download",
    headers: {
        "Content-type": "application/json; charset=UTF-8",
        "ngrok-skip-browser-warning": "True",
    }
});


const downloadBucket = (resource_name) => {
    return downloadAPI.post("/google_bucket", {
        resource_name: resource_name
    }).then(response => {
        return response.data
    }).catch(() => {
        return {
            error: 'Backend is offline or received critical error',
        }
    })

}

const downloadBucketWithPassword = (resource_name, password) => {
    return downloadAPI.post("/google_bucket_with_password", {
        resource_name: resource_name,
        password: password
    }).then(response => {
        return response.data
    }).catch(() => {
        return {
            error: 'Backend is offline or received critical error',
        }
    })

}

const downloadPhysionet = (username, password, resource_name) => {
    return downloadAPI.post("/physionet", {
        username: username,
        password: password,
        resource_name: resource_name
    }).then(response => {
        return response.data
    }).catch(() => {
        return {
            error: 'Backend is offline or received critical error',
        }
    })

}

const exportedObject = {
    downloadPhysionet,
    downloadBucket,
    downloadBucketWithPassword,
}

export default exportedObject