import React, {useContext, useEffect, useState} from 'react'
import Acl2023 from "./acl2023";
import {ModalPasswordRessourceProvider} from 'components/Utils/ModalPasswordRessource';
import {Table, Tag, Space, Typography, Collapse} from "antd";
import SyntaxHighlighter from "react-syntax-highlighter";
import GlobalContext from "contexts/GlobalContext";

const {Title, Paragraph, Text} = Typography;
const {Panel} = Collapse;

const Acl2023Container = () => {
    const {codeStyle, VilmedicTag} = useContext(GlobalContext);

    const data = [
        {
            content: (
                <>
                    <Paragraph><>Summary:</>
                    </Paragraph>
                    <Table
                        dataSource={[{
                            key: '1.1', name: 'MIMIC-III', num: "73,259", tr: "59,320", val: "7,413", te: "6,526",
                        },
                        ]}
                        columns={[{
                            title: 'Dataset', dataIndex: 'name', key: 'name',
                        }, {
                            title: 'Number of reports', dataIndex: 'num', key: 'num',
                        }, {
                            title: 'Train', dataIndex: 'tr', key: 'tr',
                        }, {
                            title: 'Validation', dataIndex: 'val', key: 'val',
                        }, {
                            title: 'Test', dataIndex: 'te', key: 'te',
                        },]}
                        pagination={false}
                    />
                    <br/>
                    <Collapse ghost>
                        <Panel header="Details per anatomy/modality pair" key="1">
                            <Table
                                columns={[{
                                    title: 'Modality/Anatomy', dataIndex: 'name', key: 'name',
                                }, {
                                    title: 'Number of reports', dataIndex: 'num', key: 'num',
                                }, {
                                    title: 'Train', dataIndex: 'tr', key: 'tr',
                                }, {
                                    title: 'Validation', dataIndex: 'val', key: 'val',
                                }, {
                                    title: 'Test', dataIndex: 'te', key: 'te',
                                },]}
                                dataSource={[{
                                    key: '1', name: 'CT Head', num: "31,403", tr: "25,122", val: "3,140", te: "3,141",
                                }, {
                                    key: '4',
                                    name: 'CT Abdomen',
                                    num: "15,990",
                                    tr: "12,792",
                                    val: "1,599",
                                    te: "1,599",
                                }, {
                                    key: '3', name: 'CT Chest', num: "12,787", tr: "10,229", val: "1,278", te: "1,280",
                                }, {
                                    key: '5', name: 'MR Head', num: "7,314", tr: "5,851", val: "731", te: "732",
                                }, {
                                    key: '2', name: 'CT Spine', num: "5,518", tr: "4,414", val: "551", te: "553",
                                }, {
                                    key: '11', name: 'CT Neck', num: "1,141", tr: "912", val: "114", te: "115",
                                }, {
                                    key: '10', name: 'MR Spine', num: "2,822", tr: "", val: "", te: "2,822",
                                }, {
                                    key: '6', name: 'CT Sinus', num: "1,268", tr: "", val: "", te: "1,268",
                                }, {
                                    key: '9', name: 'MR Abdomen', num: "1,062", tr: "", val: "", te: "1,062",
                                }, {
                                    key: '7', name: 'MR Pelvis', num: "254", tr: "", val: "", te: "254",
                                }, {
                                    key: '8', name: 'MR Neck', num: "231", tr: "", val: "", te: "231",
                                },]}

                                pagination={false}
                            />
                        </Panel>
                    </Collapse>
                </>
            ),
            title: 'MIMIC-III-RRS',
            bibtex_entry: '@inproceedings{chen-etal-2023-toward,\n' +
                '    title = "Toward Expanding the Scope of Radiology Report Summarization to Multiple Anatomies and Modalities",\n' +
                '    author = "Chen, Zhihong  and\n' +
                '      Varma, Maya  and\n' +
                '      Wan, Xiang  and\n' +
                '      Langlotz, Curtis  and\n' +
                '      Delbrouck, Jean-Benoit",\n' +
                '    booktitle = "Proceedings of the 61st Annual Meeting of the Association for Computational Linguistics (Volume 2: Short Papers)",\n' +
                '    month = jul,\n' +
                '    year = "2023",\n' +
                '    address = "Toronto, Canada",\n' +
                '    publisher = "Association for Computational Linguistics",\n' +
                '    url = "https://aclanthology.org/2023.acl-short.41",\n' +
                '    doi = "10.18653/v1/2023.acl-short.41",\n' +
                '    pages = "469--484",\n' +
                '}',
            website_entry: 'https://physionet.org/content/mimiciii/1.4/',
            resource_name: 'bionlp23-rrs-mimic-iii',
            func_name: 'modal_password_ressource',
            resource_source: 'physionet',
            tags: [<Tag color="blue">11 anatomy/modality pairs</Tag>, <Tag color="orange">Physionet Access</Tag>]
        },
        {
            content: (
                <>
                    <Table
                        dataSource={[
                            {
                                key: 'mimic1',
                                name: 'MIMIC Chest X-rays',
                                num_im: "237,564",
                                num: "128,032",
                                tr: "125,417",
                                val: "991",
                                te: "1,624",
                            },
                        ]}
                        columns={[{
                            title: 'Modality/Anatomy', dataIndex: 'name', key: 'name',
                        }, {
                            title: 'Number of images', dataIndex: 'num_im', key: 'num',
                        }, {
                            title: 'Number of reports', dataIndex: 'num', key: 'num',
                        }, {
                            title: 'Train', dataIndex: 'tr', key: 'tr',
                        }, {
                            title: 'Validation', dataIndex: 'val', key: 'val',
                        }, {
                            title: 'Test', dataIndex: 'te', key: 'te',
                        },]}
                        pagination={false}
                    />

                </>
            ),
            title: 'MIMIC-CXR-RRS',
            bibtex_entry: '@inproceedings{chen-etal-2023-toward,\n' +
                '    title = "Toward Expanding the Scope of Radiology Report Summarization to Multiple Anatomies and Modalities",\n' +
                '    author = "Chen, Zhihong  and\n' +
                '      Varma, Maya  and\n' +
                '      Wan, Xiang  and\n' +
                '      Langlotz, Curtis  and\n' +
                '      Delbrouck, Jean-Benoit",\n' +
                '    booktitle = "Proceedings of the 61st Annual Meeting of the Association for Computational Linguistics (Volume 2: Short Papers)",\n' +
                '    month = jul,\n' +
                '    year = "2023",\n' +
                '    address = "Toronto, Canada",\n' +
                '    publisher = "Association for Computational Linguistics",\n' +
                '    url = "https://aclanthology.org/2023.acl-short.41",\n' +
                '    doi = "10.18653/v1/2023.acl-short.41",\n' +
                '    pages = "469--484",\n' +
                '}',
            website_entry: 'https://physionet.org/content/mimic-cxr/2.0.0/',
            resource_name: 'bionlp23-rrs-mimic-cxr',
            func_name: 'modal_password_ressource',
            resource_source: 'physionet',
            tags: [<Tag color="blue">X-ray</Tag>, <Tag color="orange">Physionet Access</Tag>]
        },
        {
            content: (
                <>
                    <Table
                        dataSource={[
                            {
                                key: 'mimic-iii-hid',
                                name: 'MIMIC-III (hidden)',
                                num_im: "",
                                num: "6,531",
                                tr: "",
                                val: "",
                                te: "6,531",
                            },
                            {
                                key: 'chexpert-hid',
                                name: 'CheXpert Chest X-rays (hidden)',
                                num_im: "1,000",
                                num: "1,000",
                                tr: "",
                                val: "",
                                te: "1,000",
                            }
                        ]}
                        columns={[{
                            title: 'Dataset', dataIndex: 'name', key: 'name',
                        }, {
                            title: 'Number of images', dataIndex: 'num_im', key: 'num',
                        }, {
                            title: 'Number of reports', dataIndex: 'num', key: 'num',
                        }, {
                            title: 'Train', dataIndex: 'tr', key: 'tr',
                        }, {
                            title: 'Validation', dataIndex: 'val', key: 'val',
                        }, {
                            title: 'Test', dataIndex: 'te', key: 'te',
                        },]}
                        pagination={false}
                    />

                </>
            ),
            title: 'Hidden test-sets (CXR and III)',
            bibtex_entry: '@inproceedings{delbrouck-etal-2023-overview,\n' +
                '    title = "Overview of the {R}ad{S}um23 Shared Task on Multi-modal and Multi-anatomical Radiology Report Summarization",\n' +
                '    author = "Delbrouck, Jean-Benoit  and\n' +
                '      Varma, Maya  and\n' +
                '      Chambon, Pierre  and\n' +
                '      Langlotz, Curtis",\n' +
                '    booktitle = "The 22nd Workshop on Biomedical Natural Language Processing and BioNLP Shared Tasks",\n' +
                '    month = jul,\n' +
                '    year = "2023",\n' +
                '    address = "Toronto, Canada",\n' +
                '    publisher = "Association for Computational Linguistics",\n' +
                '    url = "https://aclanthology.org/2023.bionlp-1.45",\n' +
                '    doi = "10.18653/v1/2023.bionlp-1.45",\n' +
                '    pages = "478--482",\n' +
                '}',
            website_entry: '/misc/bionlp23/sharedtask/',
            resource_name: 'bionlp23-test',
            func_name: 'directDownloadDataset',
            resource_source: 'physionet',
            tags: [<Tag color="blue">X-ray</Tag>, <Tag color="green">Open Access</Tag>]
        },
    ]
    return (
        <ModalPasswordRessourceProvider>
            <Acl2023 data={data}/>
        </ModalPasswordRessourceProvider>
    )
}

export default Acl2023Container