import React, {useState} from 'react';
import explainReportApi from "services/explainReport";
import {Typography} from "antd";

const {Link} = Typography;

const Context = React.createContext(null);
const ProviderWrapper = (props) => {
    const [radGraphTableRecord, setRadGraphTableRecord] = useState(null);
    const [radgraphReportContent, setRadgraphReportContent] = useState("");


    function handleConceptMouseOver(e) {
        const conceptId = e.target.getAttribute('concept-id');
        // useref would be better
        const links = document.querySelectorAll('a');
        links.forEach((b) => {
            if (b.getAttribute("concept-id") === conceptId) {
                b.style.backgroundColor = '#ff0';
            }
        });
    }

    function handleConceptMouseOut(e) {
        const conceptId = e.target.getAttribute('concept-id');
        // useref would be better
        const links = document.querySelectorAll('a');
        links.forEach((b) => {
            if (b.getAttribute("concept-id") === conceptId) {
                b.style.backgroundColor = 'white';
            }
        });
    }

    function handleConceptOnClick(e, showExplainModal) {
        const conceptId = e.target.getAttribute('concept-id');
        // useref would be better
        let s = []
        const links = document.querySelectorAll('a');
        links.forEach((b) => {
            if (b.getAttribute("concept-id") === conceptId) {
                s.push(b.textContent)
            }
        });
        showExplainModal("define " + s.join(" ") + "?", true)
    }


    const processRadGraphAnnotations = (rgAnnotations, showExplainModal) => {

        // Table annotations
        let processed_annotations = rgAnnotations["processed_annotations"]
        processed_annotations = processed_annotations.map((record, index) => Object.assign({}, record, {key: index}))
        setRadGraphTableRecord(processed_annotations)

        // make hyperlink report, this could be in processRadGraphAnnotations
        let radgraph_report = rgAnnotations["radgraph_text"].split(" ")
        rgAnnotations["processed_annotations"].map((annotation, index) => {
            annotation["located_at_start_ix"].map(start_ix => radgraph_report[start_ix] =
                <Link concept-id={"located_at_" + index}
                      onMouseEnter={(e) => handleConceptMouseOver(e)}
                      onMouseOut={(e) => handleConceptMouseOut(e)}
                      onClick={(e) => handleConceptOnClick(e, showExplainModal)}
                >{radgraph_report[start_ix]}</Link>)
            annotation["observation_start_ix"].map(start_ix => radgraph_report[start_ix] =
                <Link concept-id={"observation_" + index}
                      onMouseEnter={(e) => handleConceptMouseOver(e)}
                      onMouseOut={(e) => handleConceptMouseOut(e)}
                      onClick={(e) => handleConceptOnClick(e, showExplainModal)}
                >{radgraph_report[start_ix]}</Link>)
        })
        setRadgraphReportContent(radgraph_report)
    }

    const getProcessedRadGraphAnnotations = async (report) => {
        return await explainReportApi.getProcessedRadGraphAnnotations(report)
    }


    const exposedValues = {
        getProcessedRadGraphAnnotations,
        processRadGraphAnnotations,
        radGraphTableRecord,
        setRadGraphTableRecord,
        radgraphReportContent,
        setRadgraphReportContent,
    }

    return (
        <Context.Provider value={exposedValues}>{props.children}</Context.Provider>
    )
}
export {Context, ProviderWrapper};
export default Context;