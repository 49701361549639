import React, {useContext} from "react";
import MainLayout from "components/Layout/MainLayout";
import translations from "resources/translations.json";
import GlobalContext from "contexts/GlobalContext";
import {IntlProvider} from "react-intl";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import RadGPTContainer from "components/Layout/Pages/RadGPT/RadGPTContainer";
import PlumbGPTContainer from "components/Layout/Pages/PlumbGPT/PlumbGPTContainer";
import SMMILEContainer from "components/Layout/Pages/SMMILE/add/SMMILEContainer";
import SMMILEOverviewContainer from "components/Layout/Pages/SMMILE/show/SMMILEOverviewContainer";
import StudyNLGContainer from "components/Layout/Pages/ReaderStudy/NLG/StudyNLGContainer";
import StructuredReportsReviewContainer
    from "components/Layout/Pages/Misc/StructuredReportsReview/StructuredReportsReviewContainer";

const App = () => {
    const {language} = useContext(GlobalContext);

    return (
        <IntlProvider locale="en" messages={translations[language]}>
            <Router>
                <Switch>
                    {/*RadGPT APP*/}
                    <Route exact path="/radGPT">
                        <RadGPTContainer/>
                    </Route>
                    {/*PlumbGPT APP*/}
                    <Route exact path="/plumbGPT">
                        <PlumbGPTContainer/>
                    </Route>
                    <Route exact path="/misc/struct-report-review/">
                        <StructuredReportsReviewContainer/>
                    </Route>
                    {/*SMMILEContainer APP*/}
                    <Route path="/smmile/add">
                        <SMMILEContainer/>
                    </Route>
                    <Route path="/smmile/show">
                        <SMMILEOverviewContainer/>
                    </Route>
                    <Route path="/study/NLG">
                        <StudyNLGContainer/>
                    </Route>
                    {/*ViLMedic APP*/}
                    <Route path="/">
                        <MainLayout/>
                    </Route>
                </Switch>
            </Router>
        </IntlProvider>
    );
};

export default App;
