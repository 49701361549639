import React, {useContext, useEffect, useState, useRef} from 'react'
import {
    Space,
    Input,
    Table,
    message,
    Modal,
    Spin,
    Alert,
    Button,
    Card,
    Typography,
} from 'antd';
import structReportApi from "services/misc/struct_reports";
import DiseaseEditor from './DiseaseEditor'; // Import the component you created


const {TextArea} = Input;

const loadingOverlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    zIndex: 1000, // Ensure it covers other content
};


const StructuredReportsReview = () => {
    const [reports, setReports] = useState([]);
    const [textAreaContent, setTextAreaContent] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);
    const textAreaRef = useRef(null);
    const [currentUtterances, setCurrentUtterances] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [reviewer, setReviewer] = useState(null); // Initially no reviewer is selected


    // Effect to automatically resize the TextArea
    useEffect(() => {
        const textArea = textAreaRef.current?.resizableTextArea?.textArea;
        if (textArea) {
            textArea.style.height = 'auto'; // Reset height
            textArea.style.height = `${textArea.scrollHeight}px`; // Set to scroll height
        }
    }, [textAreaContent]);


    const saveChangesUtterances = async (updatedUtteranceDict) => {
        const response = await structReportApi.verifyUtterances(updatedUtteranceDict, reports[currentIndex][0]);
        console.log(updatedUtteranceDict)

        if ("error" in response) {
            message.error(response["error"]);
            return null
        }

        if ("response" in response && response["response"] === "ok") {
            if (currentIndex === reports.length - 1) {
                message.success("Saved! All done thank you. Just refresh to make sure.");
            } else if (currentIndex < reports.length - 1) {
                setCurrentIndex(currentIndex + 1)
                setTextAreaContent(reports[currentIndex + 1][2]);
                setCurrentUtterances(null);
                setIsModalVisible(false)
                message.success("Saved! next report has been displayed");
            }
        } else {
            message.error("Failed, no idea what happened sorry, please contact me");
            return null
        }
    };


    const handleSend = async () => {
        const response = await structReportApi.verifyReport(textAreaContent, reports[currentIndex][0]);
        if ("error" in response) {
            message.error(response["error"]);
            return null
        }

        if ("response" in response && response["response"] === "ok" && "utterance_dict" in response) {
            setCurrentUtterances(response["utterance_dict"])
            setIsModalVisible(true)
            return null

        } else {
            message.error("Failed, no idea what happened sorry, please contact me");
            return null
        }
    };


    useEffect(() => {
        const fetchReports = async () => {
            const fetchedReports = await structReportApi.getReports(reviewer);
            setReports(fetchedReports);
            if (fetchedReports && fetchedReports.length > currentIndex) {
                setTextAreaContent(fetchedReports[currentIndex][2]);
            }
        };

        if (reviewer !== null) {
            fetchReports();
        }
    }, [reviewer]);


    if (reviewer === null) {
        // Part 1: Reviewer selection interface
        return (
            <div style={{display: 'flex', justifyContent: 'center', padding: '20px'}}>
                <h2>Select Reviewer:</h2>
                {[1, 2, 3, 4, 5].map((num) => (
                    <button key={num} onClick={() => setReviewer(num)} style={{margin: '0 10px'}}>
                        Reviewer {num}
                    </button>
                ))}
            </div>
        );
    }


    if (reports.length === 0) {
        return (
            <div style={loadingOverlayStyle}>
                <Spin tip="Loading..."/>
            </div>
        )
    }
    if (reports.length > 0) {
        return (
            <>
                <Alert
                    message="Instructions"
                    description={
                        <div style={{whiteSpace: 'pre-wrap'}}>
                            Reports: The only acceptable sections are Exam Type, History, Technique, Comparison,
                            Findings and Impression.<br/>
                            Findings: The only acceptable section headers are:<br/>
                            - Lungs and Airways<br/>
                            - Pleura<br/>
                            - Cardiovascular<br/>
                            - Hila and Mediastinum<br/>
                            - Tubes, Catheters, and Support Devices<br/>
                            - Musculoskeletal and Chest Wall<br/>
                            - Abdominal<br/>
                            - Other<br/>
                            <b>If you modify the findings and impression, please scrupulously respect the following
                                template:</b><br/>
                            Findings:<br/>
                            Header 1:<br/>
                            - Observation 1<br/>
                            - Observation 2<br/>
                            - ...<br/>
                            Header 2:<br/>
                            - ...<br/>
                            Impression:<br/>
                            1. xx<br/>
                            2. yy<br/>
                            ...<br/>
                            <b>Findings and impression section CANNOT be removed. You may remove the other section if
                                completely out of place.</b>
                            <br/>
                        </div>
                    }
                    type="info"
                    showIcon
                />
                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>

                    <div style={{width: '32%'}}>
                        <Card title={`Original report (${currentIndex + 1}/${reports.length})`}>
                            <div style={{whiteSpace: 'pre-wrap'}}>
                                {reports[currentIndex][1]}
                            </div>
                        </Card>
                    </div>
                    <div style={{width: '32%'}}>
                        <Card title="Structured report">
                            <div style={{whiteSpace: 'pre-wrap'}}>
                                {reports[currentIndex][2]}
                            </div>
                        </Card>
                    </div>
                    <div style={{width: '32%'}}>
                        <Card title="Edited">
                            <TextArea
                                ref={textAreaRef}
                                value={textAreaContent}
                                onChange={e => setTextAreaContent(e.target.value)}
                                style={{resize: 'none', overflow: 'hidden'}} // Prevent manual resizing
                            />
                            <div style={{textAlign: 'right', marginTop: 8}}>
                                <Button type="primary" onClick={handleSend}>Send</Button>
                            </div>
                        </Card>
                    </div>
                </div>
                <>
                    {currentUtterances && (
                        <DiseaseEditor
                            initialResponse={currentUtterances}
                            onSaveChanges={saveChangesUtterances}
                            setIsModalVisible={setIsModalVisible}
                            isModalVisible={isModalVisible}
                            textAreaContent={textAreaContent}

                        />
                    )}
                </>
            </>
        );

    }
};

export default StructuredReportsReview