import React, {useEffect} from 'react'
import Rrg from "./RRG"

const RRGContainer = () => {

    useEffect(() => {
    }, [])

    return (
        <Rrg/>
    )
}

export default RRGContainer