import React, {useContext, useEffect} from 'react'
import PapersDatasets from "./PapersDatasets"
import {Alert, Table, Tag} from 'antd';
import "./style/style.css"
import SyntaxHighlighter from 'react-syntax-highlighter';
import GlobalContext from "contexts/GlobalContext";
import {ModalPasswordRessourceProvider} from 'components/Utils/ModalPasswordRessource';

const PapersDatasetsContainer = () => {
    const {codeStyle, VilmedicTag} = useContext(GlobalContext);

    useEffect(() => {
    }, [])

    const data = [
        {
            title: 'RaLEs: a Benchmark for Radiology Language Evaluations\n',
            image: '/images/neurips.svg',
            resource_name: 'papers-rales',
            func_name: 'modal_password_ressource',
            resource_source: 'physionet',
            tags: [<Tag color="orange">Physionet Access</Tag>],
            content: (
                <>
                    The 6 datasets used for RaLEs are provided in a tar.gz format. To decompress the downloaded folder,
                    you can use the following command:
                    <SyntaxHighlighter language="bash" style={codeStyle}>
                        tar -xzvf rales_data_0.0.1.tar.gz -C /PATH_TO/rales_data/
                    </SyntaxHighlighter>
                    Current dataset version: 0.0.1
                </>

            ),
            bibtex_entry: '@article{TBD,\n' +
                '  title={RaLEs: a Benchmark for Radiology Language Evaluations\n},\n' +
                '  author={}\n' +
                '}',
        },
    ]


    return (
        <ModalPasswordRessourceProvider>
            <PapersDatasets data={data}/>
        </ModalPasswordRessourceProvider>
    )
}

export default PapersDatasetsContainer