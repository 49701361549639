import React, {useContext} from 'react'
import {Anchor, Button, PageHeader, Popover, Row, Space, Typography} from 'antd';
import "./style/style.css"
import SyntaxHighlighter from 'react-syntax-highlighter';
import GlobalContext from "contexts/GlobalContext";
import {FilePdfOutlined, FileSearchOutlined,} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {ModalPasswordRessourceContext} from 'components/Utils/ModalPasswordRessource';

const {Paragraph} = Typography;
const {Link: LinkAnchor} = Anchor;


const ImageDatasets = ({data}) => {
    const {codeStyle, VilmedicTag} = useContext(GlobalContext);
    const {
        modal_password_ressource,
        directDownloadDataset,
    } = useContext(ModalPasswordRessourceContext);


    const functionMap = {
        directDownloadDataset,
        modal_password_ressource,
    };

    const Content = ({children, extraContent}) => (
        <Row key={"rowContent"}>
            <div key="children" style={{flex: 1,}}>{children}</div>
            <div key="extraContent" className="image">{extraContent}</div>
        </Row>
    );


    if (!(data))
        return "No datasets"

    return (
        <div style={{display: 'flex'}}>
            <div style={{width: 900}} id={"docPageContent"}>
                <Space direction={"vertical"}>
                    {data.map((dataset, index) => {
                        return (
                            <div id={dataset.title}>
                                <PageHeader
                                    style={{maxWidth: 900, minWidth: 900}}
                                    title={dataset.title}
                                    className="site-page-header"
                                    subTitle=""
                                    tags={dataset.tags}
                                    key={index}
                                    extra={[
                                        <Button key="b3" icon={<FilePdfOutlined/>}
                                                href={dataset.website_entry} target="_blank">Website</Button>,
                                        <Button
                                            onClick={() => functionMap[dataset.func_name](dataset.resource_name, dataset.resource_source)}
                                            key="b1"
                                            type="primary">
                                            Download
                                        </Button>
                                    ]}
                                >
                                    <Content
                                        extraContent={""}
                                    >
                                        <>
                                            <Paragraph>
                                                {dataset.content}
                                                <br/>
                                                Processing:
                                                {dataset.processing}
                                            </Paragraph>
                                            <div key={"bottom-links"}>
                                                <Popover content={
                                                    <SyntaxHighlighter language="bash" style={codeStyle}>
                                                        {dataset.bibtex_entry}
                                                    </SyntaxHighlighter>
                                                } title="BibTeX">
                                                    <Link to="#" className="example-link">
                                                        <FileSearchOutlined className="example-link-icon"/>
                                                        {"BibTeX"}
                                                    </Link>
                                                </Popover>

                                            </div>
                                        </>
                                    </Content>
                                </PageHeader>
                            </div>)
                    })
                    }
                </Space>
            </div>
            <div style={{marginLeft: '10px'}}>
                <Anchor>
                    {data.map((dataset, index) => (
                        <LinkAnchor key={index} href={`#${dataset.title}`} title={dataset.title}/>
                    ))}
                </Anchor>
            </div>
        </div>
    )
}

export default ImageDatasets
