import React, {useContext, useEffect, useState} from 'react'
import {UserOutlined} from '@ant-design/icons';

import {
    Affix,
    Space,
    Input,
    Table,
    message,
    Modal,
    Checkbox,
    Spin,
    Alert,
    Button,
    Typography,
    Divider, List, Tag, Slider, Segmented, Rate, Pagination
} from 'antd';

const {TextArea} = Input;
const {Paragraph, Link, Title} = Typography;
const CheckboxGroup = Checkbox.Group;

const ratingA = [
    'Information is likely to very negatively affect the safety or effectiveness of the patient’s future medical care',
    'Information could negatively affect the safety or effectiveness of the patient’s future medical care',
    'Information would negatively affect patient understanding of the patient’s current medical condition, but likely would not influence future medical care',
    'Information provided would not improve patient understanding of the patient’s imaging findings or medical condition, or the information provided would improve the patient’s understanding but is phrased in an unnatural way',
    'Information provided is accurate and would improve patient understanding of the patient’s imaging findings or medical condition, and the information is phrased in a natural way',
]

const ratingB = [
    'Definitely not relevant',
    'Possibly relevant, or definitely relevant but phrased in an unnatural way.',
    'Definitely relevant',
]

const ratingC = [
    'Inaccurate medical information',
    'Extraneous/irrelevant content',
    'Incorrect expression of uncertainty/risk',
    'Incorrect expression of severity',
    'Awkward phrasing/grammar',
    'Other',
]

const AnnotateReport = ({reports, reportNumber, setReportNumber, setRatingValue, annotations, user, resetUser}) => {

    const checkForNull = (obj) => {
        for (const i in obj) {
            if (obj[i] === null || obj[i] === 0) {
                return true;
            }
            if (typeof obj[i] === 'object') {
                if (checkForNull(obj[i])) {
                    return true;
                }
            }
        }
        return false;
    }

    const ratingComponent = (reportNumber,
                             conceptIndex = null,
                             questionIndex = null,
                             isQuestionRating = false,
                             isReportLevel = false) => {
        let value
        if (questionIndex === null && conceptIndex !== null)
            value = annotations[reportNumber]["concepts"][conceptIndex]["explanation"]
        else {
            if (isReportLevel) {
                if (isQuestionRating)
                    value = annotations[reportNumber]["qa"][questionIndex]["question"]
                else
                    value = annotations[reportNumber]["qa"][questionIndex]["answer"]
            } else {
                if (isQuestionRating)
                    value = annotations[reportNumber]["concepts"][conceptIndex]["qa"][questionIndex]["question"]
                else
                    value = annotations[reportNumber]["concepts"][conceptIndex]["qa"][questionIndex]["answer"]
            }
        }


        let rating = (isQuestionRating) ? ratingB : ratingA

        const [valueRating, valueCheckbox] = value;

        return (
            <>
                <Rate
                    tooltips={rating}
                    count={rating.length}
                    onChange={(rating) => setRatingValue(
                        rating,
                        reportNumber,
                        conceptIndex,
                        questionIndex,
                        isQuestionRating,
                        isReportLevel,
                        false)}
                    value={valueRating}
                />
                {valueRating ?
                    <span className="ant-rate-text"><i>{rating[valueRating - 1]}</i></span> : ''
                }
                {/*{(!(isQuestionRating) && valueRating && valueRating < 4) &&*/}
                {/*    <p>*/}
                {/*        <CheckboxGroup options={ratingC}*/}
                {/*                       value={valueCheckbox}*/}
                {/*                       onChange={(rating) => setRatingValue(*/}
                {/*                           rating,*/}
                {/*                           reportNumber,*/}
                {/*                           conceptIndex,*/}
                {/*                           questionIndex,*/}
                {/*                           isQuestionRating,*/}
                {/*                           isReportLevel,*/}
                {/*                           true)}*/}
                {/*        />*/}
                {/*    </p>*/}
                {/*}*/}

            </>
        )
    }


    const report = reports[reportNumber]
    const concepts = report["concepts"].map(e => e["concept"])

    const hasNullArray = annotations.map(ann => checkForNull(ann));
    console.log(annotations[reportNumber])
    let styleTag = document.createElement("style");
    hasNullArray.forEach((hasNull, index) => {
        let color = hasNull ? '#ffbb96' : '#b7eb8f';
        styleTag.innerHTML += `.ant-pagination-item.ant-pagination-item-${index + 1} { background-color: ${color}; } `;
    });
    document.head.appendChild(styleTag);
    return (
        <>
            <Space direction={"vertical"} style={{width: 900}}>
                <Alert message={
                    <>
                        Annotating as {user}. Click <Link onClick={resetUser}>here</Link> to change username.
                    </>
                } type="info" showIcon/>
                <Alert message={"Before starting"}
                       description={
                           <>
                               I recommend taking some time to re-read the material to refresh our memories on the
                               rating system: <Link
                               href={"https://docs.google.com/document/d/1tb69m5aLdHKUnO4bbYb4zEe-PvBXmKsMY1yyK4Hyx3s/edit"}
                               target="_blank" rel="noopener noreferrer"
                           >Rubric rating notes</Link>
                           </>
                       } type="warning" showIcon/>
                <Divider key="instruction" orientation="left">Rating instructions</Divider>
                <b>For questions:</b>
                <Space direction={"horizontal"}>
                    {[1, 2, 3].map((index) => {
                        return (
                            <>
                                <Rate key={"dummyB" + index} count={ratingB.length} disabled
                                      defaultValue={index}/> {ratingB[index - 1]}
                            </>
                        )
                    })}
                </Space>
                <b>For explanation and answers:</b>
                <Space direction={"horizontal"}>
                    {[1, 2, 3, 4, 5].map((index) => {
                        return (
                            <>
                                <Rate key={"dummyA" + index} count={ratingA.length} disabled
                                      defaultValue={index}/> {ratingA[index - 1]}
                            </>
                        )
                    })}
                </Space>
                {/*<i>For explanation and answers, if rating is less than 4 stars, you can tick any checkbox from:*/}
                {/*    from:</i>*/}
                {/*<CheckboxGroup options={ratingC}*/}
                {/*/>*/}
                <center>
                    <Pagination onChange={(page, pageSize) => setReportNumber(page - 1)}
                                defaultCurrent={reportNumber + 1}
                                total={reports.length}
                                defaultPageSize={1}
                                pageSize={1}
                                style={{marginTop: 50}}
                    />
                </center>
                <Divider key="report" orientation="left">Report</Divider>
                <Paragraph>
                    <center>{report["report"]}</center>
                </Paragraph>
                <Divider key="extracted_concepts" orientation="left">Extracted concepts</Divider>
                <List
                    key={"concepts"}
                    size="small"
                    bordered
                    dataSource={concepts}
                    renderItem={(item) =>
                        <List.Item>
                            {item}
                        </List.Item>}
                />

                {/*for each report-level QA*/}
                <Divider key={"reportQA"} orientation="left">Report-level Q&A</Divider>
                {report["qa"].map((q, indexQ) => {
                    return (
                        <>
                            <p>
                                <b>{indexQ + 1}. {q["question"]}</b> Q
                                rating: {ratingComponent(reportNumber, null, indexQ, true, true)}
                            </p>
                            <p>{q["answer"]}</p>
                            <div>Answer rating: {ratingComponent(reportNumber, null, indexQ, false, true)}</div>
                        </>
                    )
                })}
                <Divider key={"conceptQA"} orientation="left">Concept-level Explanation + Q&A</Divider>
                {/*for each concept*/}
                {report["concepts"].map((e, index) => {
                    return (
                        <Space direction={"vertical"}>
                            <Divider key={index} orientation="left">{index + 1}. {e["concept"]}</Divider>
                            <Title level={5}>Explanation</Title>
                            {e["explanation"]}
                            <div>Explanation rating: {ratingComponent(reportNumber, index)}</div>

                            <Title level={5}>Q&A</Title>
                            {/*for each question*/}
                            {e["qa"].map((q, indexQ) => {
                                return (
                                    <>
                                        <p>
                                            <b>{indexQ + 1}. {q["question"]}</b> Q
                                            rating: {ratingComponent(reportNumber, index, indexQ, true)}
                                        </p>
                                        <p>{q["answer"]}</p>
                                        <div>Answer rating: {ratingComponent(reportNumber, index, indexQ)}</div>
                                    </>
                                )
                            })}
                        </Space>
                    )
                })}
            </Space>
        </>
    )
}
export default AnnotateReport
