import React, {useState, useEffect, useRef} from 'react';
import "./style/style.css";
import {useLocation} from 'react-router-dom';

import DicomViewer from './DicomViewerComponent';
import {
    Typography,
    Upload,
    Image,
    Select,
    message,
    Collapse,
    Space, Button,
    Form,
    Input,
    Spin, Alert,
} from 'antd';
import {
    LoadingOutlined,
    PlusOutlined,
    PlaySquareOutlined,
    FilePdfOutlined,
    ArrowUpOutlined,
    ArrowDownOutlined,
    CheckCircleTwoTone,
} from '@ant-design/icons';
import {Modal} from 'antd';
import smmileApi from "services/smmile";
import Cookies from 'js-cookie';

const {Title} = Typography;
const {Panel} = Collapse;
const {TextArea} = Input;
const {Option} = Select;
const {Text, Link} = Typography;

const loadingOverlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    zIndex: 1000, // Ensure it covers other content
};


const StudyNLG = () => {
    const [username, setUsername] = useState('');
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [formSpecialty] = Form.useForm();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const width = queryParams.get('width') || '1024';
    const height = queryParams.get('height') || '1024';
    const fileUrl = queryParams.get('fileUrl') || 'https://cors-anywhere.herokuapp.com/https://storage.googleapis.com/vilmedic_dataset/02aa804e-bde0afdd-112c0b34-7bc16630-4e384014.dcm';

    const handleUsernameFormSubmit = (e) => {
        e.preventDefault(); // Prevent the form from causing a page reload
        if (username.trim()) { // Simple validation to check if the username isn't empty
            Cookies.set('username', username, {expires: 1}); // Set a cookie for 7 days
            setIsFormSubmitted(true);
        } else {
            message.error('Please enter a username.');
        }
    };

    return (
        <div style={{width: width + 'px', height: height + 'px'}}>
            <DicomViewer fileUrl={fileUrl}/>
        </div>
    );
};

export default StudyNLG;
