import React, {useState, useRef} from 'react'
import {
    Affix,
    Space,
    Input,
    message,
    Button,
    Typography,
    Select, Spin,
} from 'antd';
import {marked} from 'marked';
import DOMPurify from 'dompurify';


const {TextArea} = Input;

const {Title, Text} = Typography;

const titleStyle = {
    marginBottom: '20px',
    fontWeight: 900,
    fontSize: '68px',
};

const provinceData = ['Vaillant', 'Atmos'];
const cityData = {
    Vaillant: ['ecoFIT pure', 'ecoFIT pure combi', 'ecoTEC plus', 'TURBOmax'],
    Atmos: ['Atmos Multi', 'Atmos Compact', 'Atmos ATMOS InterOpen', 'Atmos InterSystem'],
};

const REPORT_DEFAULT_PROMPT = "Can you explain the inspection and maintenance work to me?"
const ExplainReport = () => {

    const [reportContent, setReportContent] = useState(REPORT_DEFAULT_PROMPT);
    const [reportSentIsLoading, setReportSentIsLoading] = useState(false);
    const [cities, setCities] = useState(cityData[provinceData[0]]);
    const [primaryCity, setPrimaryCity] = useState(provinceData[0]);
    const [secondCity, setSecondCity] = useState(cityData[provinceData[0]][0]);
    const [plumbGPTresponse, setPlumbGPTresponse] = useState("");
    const [generationIsOn, setGenerationIsOn] = useState(false);

    const readerRef = useRef(null);


    const createMarkup = (markdown) => {
        const rawMarkup = marked(markdown);
        const sanitizedMarkup = DOMPurify.sanitize(rawMarkup);
        return {__html: sanitizedMarkup};
    };

    function resetAll() {
        setReportSentIsLoading(false)
        setGenerationIsOn(false)
        setPlumbGPTresponse("")
    }

    function cancelGeneration() {
        if (readerRef.current) {
            readerRef.current.cancel().then(() => {
                console.log("Stream reading cancelled");
                // Optionally, reset other states or UI elements as needed
            }).catch((error) => {
                console.error("Error cancelling stream reading", error);
            });
            setGenerationIsOn(false)
            setReportSentIsLoading(false); // Stop the loading state

        }
    }

    async function get_plumb_response(request) {
        try {
            const response = await fetch("https://vilmedic.lhr.rocks/misc/plumbGPT/get_response", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(request),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response;
        } catch (error) {
            console.error("Error in get_plumb_response:", error);
            throw new Error('Backend is offline or received critical error');
        }
    }

    function displayError(msg) {
        message.error(msg);
        resetAll()
    }


    async function submitReport() {
        setReportSentIsLoading(true);

        if (!reportContent) {
            displayError('Type a question');
            return;
        }

        try {

            const response = await get_plumb_response({
                "brand": secondCity,
                "model": primaryCity,
                "question": reportContent,
            });
            setGenerationIsOn(true)


            if (!response.body || !response.body.getReader) {
                displayError("Response does not contain a readable stream");
                return;
            }

            const reader = response.body.getReader();
            readerRef.current = reader; // Store the reader in the ref

            let accumulatedResponse = '';

            // This is a recursive function to process each chunk of the stream
            async function processChunk() {
                const {done, value} = await reader.read();
                if (done) {
                    console.log("Stream complete");
                    setPlumbGPTresponse(accumulatedResponse); // Update the state after the stream is complete
                    setGenerationIsOn(false)
                    return;
                }

                // Decode the chunk using UTF-8
                const chunk = new TextDecoder("utf-8").decode(value);
                // Split the chunk by newlines to handle multiple JSON objects
                const jsonStrings = chunk.split('\n').filter(Boolean); // Filter out empty strings

                for (const jsonString of jsonStrings) {

                    try {
                        const data = JSON.parse(jsonString); // Parse each JSON string
                        console.log(data);
                        if ("error" in data) {
                            displayError(data["error"]);
                            return;
                        }

                        if (data.response) {
                            accumulatedResponse += data.response; // Append the response to the accumulated result
                            setPlumbGPTresponse(accumulatedResponse); // Update the state after the stream is complete
                        }
                    } catch (error) {
                        console.error("Error parsing JSON from response chunk", error);
                    }
                }

                // Recursively read the next chunk
                await processChunk();
            }

            // Start processing the stream
            console.log("ok")
            await processChunk();
        } catch (error) {
            displayError("Error reading response stream");
        } finally {
            setReportSentIsLoading(false);
        }
    }

    const reportOnChange = (e) => {
        setReportContent(e.target.value);
    };


    const handleProvinceChange = (value) => {
        setCities(cityData[value]);
        setPrimaryCity(value);
        setSecondCity(cityData[value][0]);
    };
    const onSecondCityChange = (value) => {
        setSecondCity(value);
    };


    return (
        <>
            <Space direction={"vertical"}>
                <div style={{width: 900}}>
                    <Typography>
                        <Title style={titleStyle}>StudyNLG</Title>
                    </Typography>

                    <Space direction={"horizontal"}>

                        <Select
                            defaultValue={provinceData[0]}
                            style={{
                                width: 120,
                            }}
                            onChange={handleProvinceChange}
                            options={provinceData.map((province) => ({
                                label: province,
                                value: province,
                            }))}
                        />
                        <Select
                            style={{
                                width: 200,
                            }}
                            value={secondCity}
                            onChange={onSecondCityChange}
                            options={cities.map((city) => ({
                                label: city,
                                value: city,
                            }))}
                        />
                    </Space>
                    <br/>

                    <TextArea
                        style={{width: 900, marginTop: 10}}
                        autoSize={{minRows: 4}}
                        onChange={reportOnChange}
                        defaultValue={REPORT_DEFAULT_PROMPT}
                    />
                    <Affix offsetTop={50} style={{float: "right", marginTop: 10}}>
                        <>
                            <Button type="primary" onClick={() => submitReport()}>
                                Ask StudyNLG
                            </Button>

                        </>
                    </Affix>
                    {(plumbGPTresponse && generationIsOn) &&
                        <Affix offsetTop={50} style={{float: "right", marginTop: 10, marginRight: 10}}>
                            <>
                                <Button type="danger" onClick={cancelGeneration}>
                                    Cancel Generation
                                </Button>

                            </>
                        </Affix>
                    }
                    {(plumbGPTresponse && !generationIsOn) &&
                        <Affix offsetTop={50} style={{float: "right", marginTop: 10, marginRight: 10}}>
                            <>
                                <Button type="danger" onClick={resetAll}>
                                    Restart
                                </Button>

                            </>
                        </Affix>
                    }

                    {plumbGPTresponse &&
                        <Text>
                            <div>
                                <div
                                    style={{marginTop: 50}}
                                    dangerouslySetInnerHTML={createMarkup(plumbGPTresponse)}
                                />
                            </div>
                        </Text>

                    }
                    {(reportSentIsLoading && !generationIsOn) &&
                        <div style={{display: 'flex', justifyContent: 'center', marginTop: 50}}>
                            <Spin tip="Contacting StudyNLG..."/>
                        </div>
                    }

                </div>
            </Space>
        </>
    );
};

export default ExplainReport