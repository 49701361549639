import React, {useEffect} from 'react'
import Solution from "./Solution"

const SolutionContainer = () => {

    useEffect(() => {
    }, [])

    return (
        <Solution/>
    )
}

export default SolutionContainer