import React, {useEffect, useState} from 'react'
import Rrg from "./RRG"
import softwareApi from "services/software";
import {message} from "antd";

const RRGContainer = () => {

    const [fileList, setFileList] = useState([]);
    const [report, setReport] = useState([]);
    const [imageSent, setImageSent] = useState(false);

    function reset() {
        console.log("here")
        setFileList([])
        setReport([])
        setImageSent(false)
    }

    function displayError(msg) {
        message.error(msg);
        reset()
    }


    const imageOnChange = async ({fileList: newFileList}) => {
        // Lets use actual image as thumbnail instead of antd low quality thumbnail
        if (newFileList.length === 0)
            reset()

        if (newFileList.length > 0) {
            let file = newFileList[0]
            newFileList[0].thumbUrl = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            })
            setFileList(newFileList);
        }

    };

    const imageOnPreview = async (file) => {
        let src = file.url;

        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const sendImage = async () => {
        setImageSent(true)
        setReport([])

        let ret = await softwareApi.callRRG(fileList[0].thumbUrl)
        if (typeof ret !== 'object') {
            displayError("Backend is offline");
            return
        }

        if ("error" in ret) {
            displayError(ret["error"]);
            return
        }
        if (!("report" in ret)) {
            displayError('Backend-Frontend mismatch');
            return
        }

        setReport(ret["report"])
        setImageSent(false)

    }


    useEffect(() => {
    }, [])

    return (
        <Rrg
            fileList={fileList}
            imageOnChange={imageOnChange}
            imageOnPreview={imageOnPreview}
            sendImage={sendImage}
            report={report}
            imageSent={imageSent}
        />
    )
}

export default RRGContainer