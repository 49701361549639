import React, {useEffect, useState} from 'react'
import {Button, Dropdown, Input, message, Select, Space, Spin, Table, Tooltip, Typography, Upload} from 'antd';
import {InboxOutlined, UserOutlined, UnlockOutlined, FileAddOutlined, DownOutlined} from '@ant-design/icons';

const {Dragger} = Upload;


const LeaderboardDragger = ({
                                handleFileUpload,
                                loading,
                                hyps,
                                setTeam,
                                setPin,
                                pin,
                                filename,
                                submitResults,
                                resetResults,
                                datasets,
                                selectedDataset,
                                setSelectedDataset,
                            }) => {


    function handleKeyDown(event) {
        // Allow only digits
        if (/\d/.test(event.key)) {
            setPin(pin + event.key);
        } else {
            event.preventDefault();
        }
    }


    const onSelectDataset = (value) => {
        setSelectedDataset(value)
    };

    return (
        <>
            <Space direction={"vertical"}>
                <> To begin, select the dataset you want to submit for:</>
                <Select
                    placeholder="Select dataset"
                    style={{width: 200}}
                    onChange={onSelectDataset}
                    options={datasets.map((d) => ({
                        label: d,
                        value: d,
                    }))}
                />

                {selectedDataset &&
                    <>
                        <Dragger name='file'
                                 accept='.txt,.csv'
                                 style={{width: 600}}
                                 fileList={[]}
                                 disabled={loading}
                                 multiple={false}
                                 onChange={(f) => handleFileUpload(f, selectedDataset)}
                                 beforeUpload={(file) => {
                                     const isOK = file.type === 'text/plain' || file.type === 'text/csv';
                                     if (!isOK) {
                                         message.error(`${file.name} is not a txt file`);
                                     }
                                     return isOK || Upload.LIST_IGNORE;
                                 }}
                                 customRequest={() => {
                                     return true
                                 }}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined/>
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">
                                Supports only single file with *.txt or *.csv format.
                            </p>
                        </Dragger>
                        {hyps &&
                            <>
                                <Input placeholder="" onChange={(e) => setTeam(e.target.value)}
                                       prefix={<FileAddOutlined/>}
                                       value={filename}
                                       disabled={true}
                                       style={{width: 200}}/>
                                <Input placeholder="Team name" onChange={(e) => setTeam(e.target.value)}
                                       prefix={<UserOutlined/>}
                                       style={{width: 200}}/>
                                <Space direction={"horizontal"}>
                                    <Tooltip trigger={['focus']} title={"Digits only"} placement="bottomLeft"
                                             overlayClassName="numeric-input">
                                        <Input placeholder="Pin code"
                                               prefix={<UnlockOutlined/>}
                                               value={pin}
                                               onKeyDown={handleKeyDown}
                                               style={{width: 200}}/>
                                    </Tooltip>
                                    <Button type="primary" onClick={() => submitResults(selectedDataset)}>Submit</Button>
                                    <Button type="primary" onClick={() => resetResults()}>Reset</Button>
                                </Space>
                            </>

                        }
                    </>
                }
            </Space>
        </>

    )
}

export default LeaderboardDragger