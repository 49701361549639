import React from 'react';
import {ProviderWrapper as GlobalProvider} from "contexts/GlobalContext.jsx"
import App from "./App";
import "./style/style.css"

const AppContainer = () => {

    return (
        <GlobalProvider>
            <App/>
        </GlobalProvider>
    );
}

export default AppContainer;