import React, {useContext, useState} from 'react'
import "./style/style.css"
import GlobalContext from "contexts/GlobalContext";
import {Alert, Button, message, Space, Steps, Table, Tooltip, Typography} from 'antd';
import DraggableTable from "../DraggableTable";
import Modal from "antd/es/modal/Modal";
import {QuestionCircleOutlined, SettingOutlined} from "@ant-design/icons";
import SyntaxHighlighter from "react-syntax-highlighter";

const {Step} = Steps;


const {Title, Paragraph, Text, Link} = Typography;


const RRS = () => {
    const {codeStyle} = useContext(GlobalContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [configSteps, setConfigSteps] = useState([]);
    const [inConfigCurrent, setInConfigCurrent] = useState(0);

    const next = () => {
        setInConfigCurrent(inConfigCurrent + 1);
    };

    const prev = () => {
        setInConfigCurrent(inConfigCurrent - 1);
    };

    const showConfig = (record) => {
        console.log(record)
        let key = record.key
        let train = ""
        let test = ""
        if (key === "mimic-iii--bert-base") {
            train = 'python bin/train.py config/RRS/baseline-transformer-mimic.yml \\\n' +
                '    trainor.batch_size=16 \\\n' +
                '    trainor.grad_accu=8 \\\n' +
                '    trainor.eval_start=2 \\\n' +
                '    trainor.lr_decay_params.patience=0 \\\n' +
                '    trainor.lr_decay_params.factor=0.8 \\\n' +
                '    trainor.optim_params.lr=0.0001 \\\n' +
                '    trainor.early_stop_metric=ROUGEL \\\n' +
                '    dataset.src.root=rrs-mimiciii/all/ \\\n' +
                '    dataset.src.file=findings.tok \\\n' +
                '    dataset.tgt.root=rrs-mimiciii/all/ \\\n' +
                '    dataset.tgt.file=impression.tok \\\n' +
                '    model.encoder.num_hidden_layers=8 \\\n' +
                '    model.decoder.num_hidden_layers=8 \\\n' +
                '    validator.batch_size=4 \\\n' +
                '    validator.metrics=[ROUGEL,ROUGE1,ROUGE2,radgraph,bertscore] \\\n' +
                '    validator.beam_width=4 \\\n' +
                '    name=rrs_mimiciii_bionlp_8 \\\n' +
                '    ckpt_dir=ckpt'

            test = 'python bin/ensemble.py config/RRS/baseline-transformer-mimic.yml \\\n' +
                '    dataset.src.root=rrs-mimiciii/all/ \\\n' +
                '    dataset.src.file=findings.tok \\\n' +
                '    dataset.tgt.root=rrs-mimiciii/all/ \\\n' +
                '    dataset.tgt.file=impression.tok \\\n' +
                '    model.encoder.num_hidden_layers=8 \\\n' +
                '    model.decoder.num_hidden_layers=8 \\\n' +
                '    ensemblor.batch_size=4 \\\n' +
                '    ensemblor.metrics=[ROUGEL,ROUGE1,ROUGE2,radgraph,bertscore] \\\n' +
                '    ensemblor.beam_width=4 \\\n' +
                '    name=rrs_mimiciii_bionlp_8 \\\n' +
                '    ckpt_dir=ckpt'
        }
        if (key === "mimic-iii-allenai/biomed_roberta_base") {
            train = 'python bin/train.py config/RRS/biomed-roberta-baseline-mimic.yml \\\n' +
                '    trainor.batch_size=16 \\\n' +
                '    trainor.grad_accu=8 \\\n' +
                '    trainor.early_stop_metric=ROUGEL \\\n' +
                '    dataset.src.root=rrs-mimiciii/all/ \\\n' +
                '    dataset.src.file=findings.tok \\\n' +
                '    dataset.tgt.root=rrs-mimiciii/all/ \\\n' +
                '    dataset.tgt.file=impression.tok \\\n' +
                '    validator.batch_size=4 \\\n' +
                '    validator.metrics=[ROUGEL,ROUGE1,ROUGE2,radgraph,bertscore] \\\n' +
                '    validator.beam_width=4 \\\n' +
                '    name=rrs_mimiciii_bionlp_roberta \\\n' +
                '    ckpt_dir=ckpt'

            test = 'python bin/ensemble.py config/RRS/biomed-roberta-baseline-mimic.yml \\\n' +
                '    dataset.src.root=rrs-mimiciii/all/ \\\n' +
                '    dataset.src.file=findings.tok \\\n' +
                '    dataset.tgt.root=rrs-mimiciii/all/ \\\n' +
                '    dataset.tgt.file=impression.tok \\\n' +
                '    ensemblor.batch_size=4 \\\n' +
                '    ensemblor.metrics=[ROUGEL,ROUGE1,ROUGE2,radgraph,bertscore] \\\n' +
                '    ensemblor.beam_width=4 \\\n' +
                '    name=rrs_mimiciii_bionlp_roberta \\\n' +
                '    ckpt_dir=ckpt'
        }
        if (key === "mimic-cxr-bert-base") {
            train = "python bin/train.py config/RRS/baseline-transformer-mimic.yml \\\n" +
                "    trainor.batch_size=16 \\\n" +
                "    trainor.grad_accu=8 \\\n" +
                "    trainor.eval_start=2 \\\n" +
                "    trainor.lr_decay_params.patience=1 \\\n" +
                "    trainor.lr_decay_params.factor=0.8 \\\n" +
                "    trainor.optim_params.lr=0.0001 \\\n" +
                "    trainor.early_stop_metric='chexbert-5_micro avg_f1-score' \\\n" +
                "    dataset.src.root=mimic-cxr/ \\\n" +
                "    dataset.src.file=findings.tok \\\n" +
                "    dataset.tgt.root=mimic-cxr/ \\\n" +
                "    dataset.tgt.file=impression.tok \\\n" +
                "    model.encoder.num_hidden_layers=4 \\\n" +
                "    model.decoder.num_hidden_layers=4 \\\n" +
                "    validator.batch_size=4 \\\n" +
                "    validator.metrics=[ROUGEL,ROUGE1,ROUGE2,chexbert,radgraph,bertscore] \\\n" +
                "    validator.beam_width=4 \\\n" +
                "    name=rrs_mimicxr_bionlp_4 \\\n" +
                "    ckpt_dir=ckpt"

            test = "python bin/ensemble.py config/RRS/baseline-transformer-mimic.yml \\\n" +
                "    dataset.src.root=mimic-cxr/ \\\n" +
                "    dataset.src.file=findings.tok \\\n" +
                "    dataset.tgt.root=mimic-cxr/ \\\n" +
                "    dataset.tgt.file=impression.tok \\\n" +
                "    model.encoder.num_hidden_layers=4 \\\n" +
                "    model.decoder.num_hidden_layers=4 \\\n" +
                "    ensemblor.batch_size=4 \\\n" +
                "    ensemblor.metrics=[ROUGEL,ROUGE1,ROUGE2,chexbert,radgraph,bertscore] \\\n" +
                "    ensemblor.beam_width=4 \\\n" +
                "    name=rrs_mimicxr_bionlp_4 \\\n" +
                "    ckpt_dir=ckpt"
        }
        if (key === "mimic-cxr-allenai/biomed_roberta_base") {
            train = "python bin/train.py config/RRS/biomed-roberta-baseline-mimic.yml \\\n" +
                "    trainor.batch_size=16 \\\n" +
                "    trainor.grad_accu=8 \\\n" +
                "    trainor.early_stop_metric='chexbert-5_micro avg_f1-score' \\\n" +
                "    dataset.src.root=mimic-cxr/ \\\n" +
                "    dataset.src.file=findings.tok \\\n" +
                "    dataset.tgt.root=mimic-cxr/ \\\n" +
                "    dataset.tgt.file=impression.tok \\\n" +
                "    validator.batch_size=4 \\\n" +
                "    validator.metrics=[ROUGEL,ROUGE1,ROUGE2,chexbert,radgraph,bertscore] \\\n" +
                "    validator.beam_width=4 \\\n" +
                "    name=rrs_mimicxr_bionlp_biomed \\\n" +
                "    ckpt_dir=ckpt"

            test = "python bin/ensemble.py config/RRS/biomed-roberta-baseline-mimic.yml \\\n" +
                "    dataset.src.root=mimic-cxr/ \\\n" +
                "    dataset.src.file=findings.tok \\\n" +
                "    dataset.tgt.root=mimic-cxr/ \\\n" +
                "    dataset.tgt.file=impression.tok \\\n" +
                "    ensemblor.batch_size=4 \\\n" +
                "    ensemblor.metrics=[ROUGEL,ROUGE1,ROUGE2,chexbert,radgraph,bertscore] \\\n" +
                "    ensemblor.beam_width=4 \\\n" +
                "    name=rrs_mimicxr_bionlp_biomed \\\n" +
                "    ckpt_dir=ckpt\n"
        }

        setConfigSteps(
            [<Paragraph>
                <pre>{train}</pre>
            </Paragraph>, <Paragraph>
                <pre>{test}</pre>
            </Paragraph>],
        )


        setIsModalOpen(true);
    };

    const handleOk = () => {
        setConfigSteps([])
        setInConfigCurrent(0)
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setConfigSteps([])
        setInConfigCurrent(0)
        setIsModalOpen(false);
    };


    const expandedRowRender = (record, index, indent, expanded) => {
        let data = undefined
        if (record.key === "mimic-cxr") {
            data = [
                {
                    key: 'mimic-cxr-bert-base',
                    name: 'bert-base',
                    split: 'validate',
                    rougel: 55.09,
                    chexbert: 71.94,
                    bertscore: 68.66,
                    radgraph: 50.24,
                    index: 0,
                },
                {
                    key: 'mimic-cxr-allenai/biomed_roberta_base',
                    name: 'allenai/biomed_roberta_base',
                    split: 'validate',
                    rougel: 56.14,
                    chexbert: 74.73,
                    bertscore: 69.63,
                    radgraph: 51.08,
                    index: 1,
                },
            ]

        } else if (record.key === "mimic-iii") {
            data = [
                {
                    key: 'mimic-iii-bert-base',
                    name: 'bert-base',
                    split: 'validate',
                    rougel: '30.27',
                    chexbert: "N/A",
                    bertscore: '51.80',
                    radgraph: '26.70',
                    index: 0,
                },
                {
                    key: 'mimic-iii-allenai/biomed_roberta_base',
                    name: 'allenai/biomed_roberta_base',
                    split: 'validate',
                    rougel: '31.90',
                    chexbert: "N/A",
                    bertscore: '53.67',
                    radgraph: '28.49',
                    index: 1,
                },
            ]
        }

        const columns = [

            {
                title: 'Split',
                dataIndex: 'split',
                key: 'split',
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'ROUGEL',
                dataIndex: 'rougel',
                key: 'rougel',
            },
            {
                title: 'F1CheXbert',
                dataIndex: 'chexbert',
                key: 'chexbert',
            },
            {
                title: 'bertscore',
                dataIndex: 'bertscore',
                key: 'bertscore',
            },
            {
                title: 'F1RadGraph',
                dataIndex: 'radgraph',
                key: 'address',
            },
            {
                title: '',
                key: 'config',
                render: (text, record, index) => <a onClick={() => showConfig(record)}>Config</a>,
            }

        ];
        return <DraggableTable
            data={data}
            columns={columns}
        />;
    };


    const columns = [

        {
            title: 'Dataset',
            dataIndex: 'dataset',
            key: 'dataset',
        },
        {
            title: 'Size',
            dataIndex: 'size',
            key: 'size',
        },
        {
            title: 'Publisher',
            dataIndex: 'publisher',
            key: 'publisher',
        },
        {
            title: 'Link',
            key: 'operation',
            render: (text, record, index) => <a>Link</a>,
        },
    ];
    const data = [
        {
            "key": "mimic-cxr",
            "dataset": "mimic-cxr",
            "size": "128,032 samples",
            "publisher": "MIT LCP",
        },
        {
            "key": "mimic-iii",
            "dataset": "mimic-iii",
            "size": " 79,779 samples",
            "publisher": "MIT LCP",
        },
    ]

    return (
        <>
            <div id="rrs_solution" style={{width: 900}}>
                <Space direction={"vertical"}>
                    <Title level={2}>Radiology Report Summarization</Title>
                    <Paragraph>
                        Radiology report summarization is a growing area of research. Given the Findings and/or
                        Background sections of a radiology report, the goal is to generate a summary (called an
                        Impression section) that highlights the key observations and conclusions of the radiology study.
                    </Paragraph>
                    <Alert
                        message="Informational Notes"
                        description="The datasets used in this section are MIMIC-CXR and MIMIC-III."
                        type="info"
                        showIcon
                    />

                    <Table
                        columns={columns}
                        expandable={{
                            expandedRowRender,
                            defaultExpandedRowKeys: ['mimic-cxr'],
                        }}
                        dataSource={data}
                    />

                </Space>
            </div>
            <Modal title="Config" width={700} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>

                <Steps current={inConfigCurrent}>
                    <Step key={"1"} title={"Train & Validation"}/>
                    <Step key={"2"} title={"Test"}/>
                </Steps>
                <div className="steps-content">{configSteps[inConfigCurrent]}</div>
                <div className="steps-action">
                    {inConfigCurrent < configSteps.length - 1 && (
                        <Button type="primary" onClick={() => next()}>
                            Next
                        </Button>
                    )}
                    {inConfigCurrent > 0 && (
                        <Button style={{margin: '0 8px'}} onClick={() => prev()}>
                            Previous
                        </Button>
                    )}
                </div>
            </Modal>
        </>
    );
};

export default RRS
