import React, {useEffect, useState} from 'react'
import StructuredReportsReview from "./StructuredReportsReview";

const StructuredReportsReviewContainer = () => {

    return (
        <StructuredReportsReview/>
    )
}

export default StructuredReportsReviewContainer